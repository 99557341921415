import React from 'react'
import { ThemeProvider, Theme } from '@emotion/react'

export const BaseTheme: React.FC<{ theme: Theme }> = ({ theme, children }) => {
  return (
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      {theme?.links?.map((l) => (
        <link {...l} />
      ))}
      {children}
    </ThemeProvider>
  )
}

BaseTheme.displayName = 'BaseTheme'
