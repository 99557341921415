import { useCallback, useEffect, useMemo, useRef } from 'react'
import pino from 'pino'
import _ from 'lodash'

const logger = pino({ browser: { asObject: true } })
const scanLogger = logger.child({ ns: 'barcodeScan' })
const keypressLogger = logger.child({ ns: 'keypress' })

/**
 * simulate external input scan
 Array.from('%MGRGRL20E31H163QMAGRO  GABRIELE_ò80380001900312326894_').forEach((char) =>
 document.dispatchEvent(new KeyboardEvent('keypress', { keyCode: char.charCodeAt(0) })),
 )
 */

export const useDocumentInput = (enabled: boolean, onChange: Function) => {
  const scanRef = useRef('')
  // @ts-ignore
  const debouncedOnChange = useMemo(() => _.debounce(onChange, 500), [])

  const handleKeyPress = useCallback(
    (e) => {
      if (e?.target?.tagName?.toLowerCase() === 'input') {
        scanRef.current = ''
        return
      }

      const keyCodes = [13, 9, 91, 18, 17, 16]
      const keyCode = e.which
      if (!keyCodes.includes(keyCode)) {
        const char = String.fromCharCode(keyCode)
        scanRef.current += char ?? ''
        keypressLogger.info({ current: scanRef.current, char })
        debouncedOnChange(scanRef.current)
      }
    },
    [debouncedOnChange],
  )

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keypress', handleKeyPress)
      return () => document.removeEventListener('keypress', handleKeyPress)
    }

    return () => document.removeEventListener('keypress', handleKeyPress)
  }, [handleKeyPress, enabled])

  const clear = useCallback(() => {
    scanRef.current = ''
  }, [])

  return { clear }
}
