import { useState } from 'react'
import { useInterval } from 'react-use'

interface UseNowProps {
  interval?: number
}

/**
 * Provides you an updated current date instance
 */
export function useNow(props?: UseNowProps) {
  const [now, setNow] = useState(() => new Date())

  useInterval(
    () => {
      setNow(new Date())
    },
    props?.interval ?? 1000,
  )

  return now
}
