import { CheckinKiosk, Step, Scan, initLogger } from '@gaudia/ui-common'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Bluebird from 'bluebird'
import { subscribe, useSnapshot } from 'valtio'
import { useMedia } from 'react-use'
import { useStateController, centralState } from './useStateController'
import { useStepsController } from './useStepsController'
import { useRequest, useMutation } from '../../components/queryHooks'
import { jsonLogic } from '@gaudia/shared'
import { useAblyEvent } from '../../lib/ably'
import { useDocumentInput } from '../../lib/jsonLayout/useDocumentInput'
import { useTimeoutCounter } from '../../lib/jsonLayout/useTimeoutCounter'
import checkinKioskDataDefault from './CheckIn/data/checkinKioskDataDefault'
import checkinKioskDataWithLocationSelection from './CheckIn/data/checkinKioskDataWithLocationSelection'
import checkinKioskDataTablet from './CheckIn/data/checkinKioskDataTablet'
import checkinKioskDataHub from './CheckIn/data/checkinKioskDataHub'
import { TextToSpeech } from '../../lib/TextToSpeech'

// const debugKiosk = checkinKioskDataHub
const debugKiosk = false
if (debugKiosk) {
  // @ts-ignore
  console.log(JSON.stringify(debugKiosk.interface))
}

export const useCheckin = ({ checkinKioskId }: { checkinKioskId?: string | null } = {}) => {
  /******************************
   * Initialization
   ******************************/

  const checkinKioskQuery = useRequest(
    {
      checkinKioskById: [
        { id: checkinKioskId },
        {
          __typename: true,
          id: true,
          name: true,
          addUnknownVisitorsToLocationId: true,
          locations: {
            id: true,
            name: true,
            queue: { hasQueue: true },
          },
          interface: {
            steps: { name: true, content: true, onMount: true },
            macros: true,
            theme: true,
            debugMode: true,
          },
        },
      ],
    },
    { enabled: checkinKioskId, refetchOnWindowFocus: false },
  )

  const logger = useMemo(() => initLogger('checkinKiosk', { checkinKioskId }), [checkinKioskId])

  const checkinKiosk = useMemo<CheckinKiosk | null | undefined>(() => {
    if (debugKiosk) return debugKiosk

    return checkinKioskQuery?.data?.checkinKioskById
  }, [checkinKioskQuery?.data?.checkinKioskById])

  const handleAblyOnRefresh = useCallback(() => {
    logger.info(`Received checkinKiosk:${checkinKioskId}:refresh ably message. Refreshing`)
    window.location.reload()
  }, [logger, checkinKioskId])

  useAblyEvent(
    checkinKiosk?.id
      ? [
          {
            channelName: `checkinKiosk:${checkinKiosk?.id}:refresh`,
          },
        ]
      : [],
    handleAblyOnRefresh,
  )

  /******************************
   * Steps
   ******************************/
  const steps = useStepsController({ checkinKiosk })

  // select first step
  useEffect(() => {
    if (checkinKiosk?.interface?.steps?.[0]) {
      steps.setCurrentStep(checkinKiosk?.interface?.steps?.[0])
    }
  }, [checkinKiosk?.interface?.steps?.[0]])

  /******************************
   * State
   ******************************/
  const { state, updateState } = useStateController()

  useEffect(() => {
    if (steps?.currentStep?.state) {
      updateState(steps?.currentStep?.state)
    }
  }, [steps?.currentStep?.state])

  const enqueueMutation = useMutation()

  useEffect(() => {
    updateState({
      enqueueOperation: {
        data: enqueueMutation?.result?.data?.enqueue,
        loading: enqueueMutation?.result?.loading,
        errors: enqueueMutation?.result?.errors,
      },
    })
  }, [
    enqueueMutation?.result?.data?.enqueue,
    enqueueMutation?.result?.loading,
    enqueueMutation?.result?.errors,
  ])

  /******************************
   * Temperature scan
   ******************************/

  const [isScanSubscriberEnabled, setScanSubscriberEnabled] = useState(false)

  const [scanData, setScanData] = useState<Scan | null>()

  useAblyEvent(
    checkinKioskId && isScanSubscriberEnabled
      ? [
          {
            channelName: `checkinKiosk:${checkinKioskId}:scan`,
          },
        ]
      : [],
    (event: any) => setScanData(event?.data?.scan),
  )

  // update state
  useEffect(() => {
    updateState({
      faceScanOperation: {
        data: scanData,
        loading: false,
        errors: null,
      },
    })
  }, [scanData])

  // react to face scans
  const [faceScanListeners, faceScanListenersDispatch] = useReducer(
    (state: Function[], { action, callback }: { action: 'add' | 'remove'; callback: Function }) => {
      if (action === 'add') {
        return [...state, callback]
      }

      if (action === 'remove') {
        return state.filter((f) => f !== callback)
      }

      return state
    },
    [],
  )

  useEffect(() => {
    if (state?.faceScanOperation?.data && faceScanListeners.length) {
      faceScanListeners.forEach((fn) => fn())
    }
  }, [faceScanListeners, state?.faceScanOperation?.data])

  const saveScan = useMutation()

  /******************************
   * Document input
   ******************************/
  const [isDocumentInputSubscriberEnabled, setDocumentInputSubscriberEnabled] = useState(false)
  const [externalInputData, setExternalInputData] = useState<string | null>()

  // react to face scans
  const [documentInputListeners, documentInputListenersDispatch] = useReducer(
    (state: Function[], { action, callback }: { action: 'add' | 'remove'; callback: Function }) => {
      if (action === 'add') {
        return [...state, callback]
      }

      if (action === 'remove') {
        return state.filter((f) => f !== callback)
      }

      return state
    },
    [],
  )

  const documentInputController = useDocumentInput(
    isDocumentInputSubscriberEnabled,
    (input: string) => setExternalInputData(input),
  )

  // update state
  useEffect(() => {
    updateState({
      externalInputData,
    })
  }, [externalInputData])

  useEffect(() => {
    if (state?.externalInputData && documentInputListeners.length) {
      documentInputListeners.forEach((fn) => fn())
    }
  }, [documentInputListeners, state?.externalInputData])

  /******************************
   * Printing
   ******************************/

  const sendToPrintMutation = useMutation()

  useEffect(() => {
    updateState({
      sendToPrintOperation: {
        data: sendToPrintMutation?.result?.data?.sendToPrint,
        loading: sendToPrintMutation?.result?.loading,
        errors: sendToPrintMutation?.result?.errors,
      },
    })
  }, [
    sendToPrintMutation?.result?.data?.sendToPrint,
    sendToPrintMutation?.result?.loading,
    sendToPrintMutation?.result?.errors,
  ])

  /******************************
   * Attachments Printing
   ******************************/

  const printAllVisitAttachmentsMutation = useMutation()

  useEffect(() => {
    updateState({
      printAllVisitAttachmentsMutationOperation: {
        data: printAllVisitAttachmentsMutation?.result?.data?.printAllVisitAttachments,
        loading: printAllVisitAttachmentsMutation?.result?.loading,
        errors: printAllVisitAttachmentsMutation?.result?.errors,
      },
    })
  }, [
    printAllVisitAttachmentsMutation?.result?.data?.printAllVisitAttachments,
    printAllVisitAttachmentsMutation?.result?.loading,
    printAllVisitAttachmentsMutation?.result?.errors,
  ])

  const printAttachmentsMutation = useMutation()

  useEffect(() => {
    updateState({
      printAttachmentsMutationOperation: {
        data: printAttachmentsMutation?.result?.data?.printAttachments,
        loading: printAttachmentsMutation?.result?.loading,
        errors: printAttachmentsMutation?.result?.errors,
      },
    })
  }, [
    printAttachmentsMutation?.result?.data?.printAttachments,
    printAttachmentsMutation?.result?.loading,
    printAttachmentsMutation?.result?.errors,
  ])

  /******************************
   * Notifications
   ******************************/

  const sendNotificationMutation = useMutation()

  /******************************
   * Timeout and idle handling
   ******************************/

  const timeoutController = useTimeoutCounter()

  useEffect(() => {
    updateState({
      timeout: {
        secondsLeft: timeoutController.secondsLeft,
        options: timeoutController.options,
      },
    })
  }, [timeoutController.secondsLeft, timeoutController.options, updateState])

  const isLandscape = useMedia('(orientation: landscape)')
  const orientation = isLandscape ? 'landscape' : 'portrait'

  /******************************
   * Enhance jsonLogic
   ******************************/
  useEffect(() => {
    if (checkinKiosk?.interface?.macros) {
      // @ts-ignore
      jsonLogic.add_operation('macro', function ({ name, args } = {}) {
        const macro = checkinKiosk?.interface?.macros?.[name]

        const a = macro
          ? jsonLogic.mapper(
              {
                $checkinKiosk: checkinKiosk,
                $state: state,
                $device: { orientation },
                $args: args,
              },
              macro,
              {
                mustEvaluate: (key) =>
                  !['onSuccess', 'onReceive', 'onClick', 'itemMapping'].includes(key),
              },
            )
          : null

        // console.log('state', state?.timeout?.secondsLeft)
        return a
      })
    }
  }, [
    state,
    checkinKiosk,
    checkinKiosk?.interface?.macros,
    timeoutController.secondsLeft,
    orientation,
  ])

  /******************************
   * Actions
   ******************************/

  const [actionsQueue, actionsQueueDispatch] = useReducer(
    (state: object[], { type, action }: { type: 'add' | 'remove'; action: object }) => {
      if (type === 'add') {
        return [...state, action]
      }

      if (type === 'remove') {
        return state.filter((a) => a !== action)
      }

      return state
    },
    [],
  )

  useEffect(() => {
    if (actionsQueue?.[0]) {
      Bluebird.try(() => performActions(actionsQueue[0])).finally(() =>
        actionsQueueDispatch({ type: 'remove', action: actionsQueue[0] }),
      )
    }
  }, [actionsQueue?.[0]])

  const performActions = useCallback(
    (actionOrActions, context?: any) => {
      const cleanEffects: Function[] = []

      const parsedActionOrActions = jsonLogic.mapper(
        {
          $state: context?.$state ?? state,
          $checkinKiosk: checkinKiosk,
          $args: context?.$args,
          $result: context?.$result,
        },
        actionOrActions,
        {
          mustEvaluate: (key) =>
            !['onSuccess', 'onReceive', 'onClick', 'itemMapping'].includes(key),
        },
      )
      const actions = Array.isArray(parsedActionOrActions)
        ? parsedActionOrActions
        : [parsedActionOrActions]

      // @ts-ignore
      actions
        ?.filter((a) => a)
        ?.forEach?.(({ action }) => {
          if (!action) return
          const actionName = action.name
          const payload = action.payload

          const onSuccessAction = action?.onSuccess
          const onErrorAction = action?.onError

          if (actionName === 'updateState') {
            updateState(payload)
            // actionsQueueDispatch({ type: 'add', action: action })
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
          }

          if (actionName === 'enqueue') {
            Bluebird.try(() =>
              enqueueMutation.mutate({
                enqueue: [
                  { data: { ...payload, checkinKioskId } },
                  {
                    id: true,
                    ticketCode: true,
                    locationId: true,
                    visitorId: true,
                    bookingId: true,
                    attachmentIds: true,
                    visitor: {
                      attachmentIds: true,
                    },
                    location: {
                      shortName: true,
                    },
                  },
                ],
              }),
            )
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: result?.data?.enqueue }),
              )
              .catch(
                (err) =>
                  onErrorAction && performActions(onErrorAction, { $error: err.errors?.[0] }),
              )
          }

          /*
           TODO
          if (actionName === 'visitsForVisitor') {
            Bluebird.try(() =>
              enqueueMutation.mutate({
                enqueue: [
                  { data: { ...payload, checkinKioskId } },
                  {
                    id: true,
                    ticketCode: true,
                    location: {
                      shortName: true,
                    },
                  },
                ],
              }),
            )
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: result?.data?.enqueue }),
              )
              .catch(
                (err) =>
                  onErrorAction && performActions(onErrorAction, { $error: err.errors?.[0] }),
              )
          }*/

          if (actionName === 'subscribeToFaceScan') {
            setScanSubscriberEnabled(true)
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
            const callback = () => {
              if (action?.onReceive) {
                //performActions(action?.onReceive, state)
                actionsQueueDispatch({ type: 'add', action: action?.onReceive })
              }
            }

            faceScanListenersDispatch({ action: 'add', callback })
            cleanEffects.push(() => {
              setScanSubscriberEnabled(false)
              faceScanListenersDispatch({ action: 'remove', callback })
            })
          }

          if (actionName === 'unsubscribeToFaceScan') {
            setScanSubscriberEnabled(false)
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
          }

          if (actionName === 'subscribeToExternalInput') {
            setDocumentInputSubscriberEnabled(true)
            setExternalInputData('')
            documentInputController.clear()
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
            const callback = () => {
              if (action?.onReceive) {
                // performActions(action?.onReceive, state)
                actionsQueueDispatch({ type: 'add', action: action?.onReceive })
              }
            }
            documentInputListenersDispatch({ action: 'add', callback })
            cleanEffects.push(() => {
              setDocumentInputSubscriberEnabled(false)
              setExternalInputData('')
              documentInputListenersDispatch({ action: 'remove', callback })
            })
          }

          if (actionName === 'unsubscribeToExternalInput') {
            setDocumentInputSubscriberEnabled(false)
            setExternalInputData('')
            documentInputController.clear()
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
          }

          if (actionName === 'addScanToVisit') {
            Bluebird.try(() =>
              saveScan.mutate({
                addScanToVisit: [{ ...payload }, { id: true }],
              }),
            ).then(() => onSuccessAction && performActions(onSuccessAction))
          }

          if (actionName === 'sendToPrint') {
            Bluebird.try(() =>
              sendToPrintMutation.mutate({
                sendToPrint: [
                  { ...payload },
                  { success: true, visit: { id: true, ticketCode: true } },
                ],
              }),
            )
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: result?.data?.sendToPrint }),
              )
              .catch(
                (err) =>
                  onErrorAction && performActions(onErrorAction, { $error: err.errors?.[0] }),
              )
          }

          if (actionName === 'goToPreviousStep') {
            steps.goToPreviousStep(payload?.goToLogic)
          }

          if (actionName === 'goToNextStep') {
            steps.goToNextStep()
          }

          if (actionName === 'goToStep' && payload.stepName) {
            steps.goToStep(payload.stepName)
          }

          if (actionName === 'goToFirstStep') {
            steps.goToFirstStep()
          }

          if (actionName === 'resetState') {
            updateState(null)
          }

          if (actionName === 'startCountdown') {
            timeoutController.enable(
              {
                seconds: payload?.durationInSeconds,
                postponeOnActivity: payload?.postponeOnActivity,
              },
              () => onSuccessAction && performActions(onSuccessAction),
            )

            cleanEffects.push(() => {
              timeoutController.clear()
            })
          }

          if (actionName === 'speak') {
            const textToSpeech = new TextToSpeech()
            textToSpeech
              .play(payload?.ssml)
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: { success: true } }),
              )
              .catch((err) => onErrorAction && performActions(onErrorAction, { $err: err }))

            cleanEffects.push(() => textToSpeech.stop())
          }

          if (actionName === 'sendNotification') {
            sendNotificationMutation
              .mutate({
                sendCheckinKioskNotification: [
                  {
                    checkinKioskId,
                    notificationData: {
                      severity: payload?.severity,
                      message: payload?.message,
                      extra: payload?.extra,
                    },
                  },
                  { success: true },
                ],
              })
              .then((data) => onSuccessAction && performActions(onSuccessAction))
          }

          if (actionName === 'printAllVisitAttachments') {
            Bluebird.try(() =>
              printAllVisitAttachmentsMutation.mutate({
                printAllVisitAttachments: [{ ...payload }, { success: true }],
              }),
            )
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, {
                    $result: result?.data?.printAllVisitAttachments,
                  }),
              )
              .catch(
                (err) =>
                  onErrorAction && performActions(onErrorAction, { $error: err.errors?.[0] }),
              )
          }

          if (actionName === 'printAttachments') {
            Bluebird.try(() =>
              printAttachmentsMutation.mutate({
                printAttachments: [{ ...payload, checkinKioskId }, { success: true }],
              }),
            )
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: result?.data?.printAttachments }),
              )
              .catch(
                (err) =>
                  onErrorAction && performActions(onErrorAction, { $error: err.errors?.[0] }),
              )
          }
        })

      return cleanEffects
    },
    [checkinKioskId, checkinKiosk, state, steps],
  )

  /******************************
   * Steps Lifecycle hooks
   ******************************/

  // onMount effects
  useEffect(() => {
    if (steps.currentStep?.onMount) {
      const cleanEffects = performActions(steps.currentStep?.onMount)

      return () => {
        cleanEffects.forEach((fn) => fn())
      }
    }
  }, [steps.currentStep?.onMount])

  const [content, setContent] = useState()

  useEffect(() => {
    const mapContent = () => {
      const mapped = jsonLogic.mapper(
        {
          $checkinKiosk: checkinKiosk,
          $state: state,
          $device: { orientation },
        },
        steps.currentStep?.content,
        {
          mustEvaluate: (key) =>
            !['onSuccess', 'onReceive', 'onClick', 'itemMapping'].includes(key),
        },
      )

      setContent(mapped)
    }

    const unsubscribe = subscribe(centralState, () => mapContent())

    mapContent()

    return () => {
      unsubscribe()
    }
  }, [checkinKiosk, steps.currentStep?.content, orientation])

  return { checkinKiosk, content, steps, state, updateState, performActions }
}
