import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as Ably from 'ably'
import { initLogger } from './initLogger'
import { Types } from 'ably/promises'
import take from 'lodash-es/take'
import { UAParser } from 'ua-parser-js'
import { UseQueryResult } from './graphql'
import ConnectionStateChange = Types.ConnectionStateChange
import ChannelStateChange = Types.ChannelStateChange

const logger = initLogger('ably')

export interface Message<MessageData> extends Omit<Ably.Types.Message, 'data'> {
  data: MessageData
}

export type MessageReceivedCallback<MessageData> = (message: Message<MessageData>) => any

export interface UseChannelArgs<MessageData> {
  channels: { channelName: string; eventName?: string }[]
  cb: MessageReceivedCallback<MessageData>
  options?: {
    onRecover?: () => void
    onConnected?: () => void
  }
}

export interface UseAblyResult {
  connectionState: Ably.Types.ConnectionState
  stateChangeHistory: Ably.Types.ConnectionState[]
  isConnected: boolean
  ensureRefetch: typeof ensureRefetch
  disconnect: Ably.Types.RealtimePromise['close']
}

export const ensureRefetch = (queryResult: UseQueryResult & any) => {
  if (!queryResult.isLoading) {
    const interval = setInterval(() => {
      if (!queryResult.isLoading) {
        queryResult.refetch()
        clearInterval(interval)
      }
    }, 200)
  }

  return queryResult.refetch()
}

interface AblyContext {
  ably?: Ably.Types.RealtimePromise | null
  isConnected?: boolean | null
  connectionState?: Ably.Types.ConnectionState | null
  stateChangeHistory?: Ably.Types.ConnectionState[] | null
}

export const initializeAbly = (customClient) => {
  const createAblyInstance = () => {
    return new Ably.Realtime.Promise({
      authCallback: async (_tokenParams, callback) => {
        try {
          const { data } = await customClient.query({
            ablyAuthenticate: {
              token: true,
              capability: true,
              clientId: true,
              expires: true,
              issued: true,
            },
          })

          // @ts-ignore
          callback(null, data?.ablyAuthenticate)
        } catch (err) {
          // @ts-ignore
          callback(err, null)
        }
      },
    })
  }

  const AblyContext = React.createContext<AblyContext>({
    ably: null,
  })

  const AblyProvider: React.FC<{ isAuthenticated: boolean }> = (props) => {
    const { isAuthenticated } = props
    const [ably, setAbly] = useState<Ably.Types.RealtimePromise | null>()
    const [connectionState, setConnectionState] = useState<Ably.Types.ConnectionState | undefined>(
      ably?.connection?.state,
    )
    const isConnected = useMemo(() => connectionState === 'connected', [connectionState])
    const [stateChangeHistory, setStateChangeHistory] = useState<Ably.Types.ConnectionState[]>([])

    const handleConnectionStateChange = useCallback(
      (stateChange: ConnectionStateChange) => {
        if (stateChange.current === stateChange.previous) {
          return null
        }
        logger.info({ stateChange }, 'Ably connection state change')
        setConnectionState(stateChange.current)
        setStateChangeHistory((history) => take([stateChange.current, ...history], 10))
      },
      [setStateChangeHistory],
    )

    useEffect(() => {
      ably?.connection?.on(handleConnectionStateChange)

      return () => ably?.connection?.off(handleConnectionStateChange)
    }, [ably, handleConnectionStateChange])

    useEffect(() => {
      if (isAuthenticated) {
        setAbly(createAblyInstance())
      }

      if (!isAuthenticated) {
        ably?.close()
        setAbly(null)
      }
      // eslint-disable-next-line
    }, [isAuthenticated])

    const disconnect = useCallback(() => {
      ably?.close()
    }, [ably?.close])

    // register to global presence channel
    useEffect(() => {
      const globalPresenceChannel = ably?.channels?.get('global-presence')
      if (isConnected && ably) {
        globalPresenceChannel.presence.enter(new UAParser().getResult())
      }

      return () => {
        globalPresenceChannel?.presence.leave()
      }
    }, [isConnected, ably])

    const value: AblyContext = useMemo(
      () => ({ ably, isConnected, connectionState, stateChangeHistory, disconnect }),
      [ably, isConnected, connectionState, stateChangeHistory, disconnect],
    )

    return <AblyContext.Provider value={value}>{props.children}</AblyContext.Provider>
  }

  function useAblyEvent<MessageType>(
    channels: UseChannelArgs<MessageType>['channels'] | undefined,
    cb: UseChannelArgs<MessageType>['cb'],
    options?: UseChannelArgs<MessageType>['options'],
  ): UseAblyResult {
    const serializedChannels = useMemo(() => JSON.stringify(channels), [channels])

    const { ably, isConnected, connectionState, stateChangeHistory }: AblyContext = useContext(AblyContext)

    /*// eslint-disable-next-line
    const memoizedCb = useCallback<MessageReceivedCallback<MessageType>>(
      (message) => {
        cb(message)
      },
      // eslint-disable-next-line
      []
    )*/

    const memoizedCb = cb

    useEffect(() => {
      if (!ably) return null

      const cleanupFunctions: Function[] = channels?.reduce((acc, { channelName, eventName }) => {
        const channel = ably?.channels?.get(channelName)
        // channel.setOptions({ params: { rewind: '1' } })

        if (eventName) {
          channel?.subscribe(eventName, memoizedCb) // .then(presenceEnter)
          acc.push(() => channel?.unsubscribe(eventName, memoizedCb))
        } else {
          channel?.subscribe(memoizedCb) // .then(presenceEnter)
          acc.push(() => channel?.unsubscribe(memoizedCb))
        }

        const handler = (stateChange: ChannelStateChange) => {
          logger.info({ channelName, stateChange }, 'Channel state change')
        }

        channel?.on(handler)
        acc.push(() => channel?.off(handler))

        return acc
      }, [])

      return () => cleanupFunctions?.forEach((fn) => fn())
    }, [serializedChannels, memoizedCb, ably])

    useEffect(() => {
      if (stateChangeHistory?.[0] === 'connected' && stateChangeHistory?.[2] && options?.onRecover) {
        logger.info({ stateChangeHistory }, 'Calling onRecover')
        options?.onRecover?.()
      }
    }, [options?.onRecover, ably, stateChangeHistory])

    return { connectionState, stateChangeHistory, isConnected, ensureRefetch, disconnect: ably?.close }
  }

  function useAbly(): UseAblyResult {
    const { isConnected, connectionState, stateChangeHistory, ably }: AblyContext = useContext(AblyContext)

    return { connectionState, stateChangeHistory, isConnected, ensureRefetch, disconnect: ably?.close }
  }

  return { useAblyEvent, useAbly, AblyContext, AblyProvider }
}
