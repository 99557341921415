import { useCallback, useMemo, useRef, useState } from 'react'
import {
  QueryFunction,
  QueryConfig,
  QueryResult,
  useQuery as useReactQuery,
  InfiniteQueryConfig,
  useQueryCache,
  QueryCache,
  ReactQueryCacheProvider,
} from 'react-query'
import { ExecutionResult, GraphQLError } from 'graphql'
import { createClient } from '../graphql'
// import customClient from '../../customClient'
import { Query, QueryRequest, Mutation, MutationRequest } from './index'

export type UseQueryResult = Omit<QueryResult<ExecutionResult<Query>>, 'data'> & {
  loading?: boolean
  isFetchingInitial?: boolean
  data?: ExecutionResult<Query>['data'] | null | undefined
  errors?: ReadonlyArray<GraphQLError>
}

export const createQueryHooks = (customClient: ReturnType<typeof createClient>) => {
  const useRequest = (
    queryRequest: QueryRequest | null | undefined | false | [string | Array<string>, QueryRequest],
    config: QueryConfig<ExecutionResult<Query>> = {},
  ): UseQueryResult => {
    const query = useMemo(() => (Array.isArray(queryRequest) ? queryRequest[1] : queryRequest), [queryRequest])
    const [queryName, ...vars] = useMemo(() => (Array.isArray(queryRequest) ? queryRequest : []), [queryRequest])

    const queryRequestString = useMemo(() => queryName ?? (query && JSON.stringify(query)), [query, queryName])

    // @ts-ignore
    const requestFn: QueryFunction<ExecutionResult<Query>> = (qn: string, q: any) => {
      return customClient.query(q ?? {})
    }

    const useReactQueryArgs = useMemo(() => {
      if (!queryRequest) return false

      if (Array.isArray(queryRequest)) return queryRequest

      return [queryRequestString, queryRequest, ...vars]
    }, [queryRequest, queryRequestString, vars])

    const {
      data: response,
      error,
      isFetching: isLoading,
      refetch,
      status,
      failureCount,
      isStale,
      ...rest
    } = useReactQuery<ExecutionResult<Query>, any>(useReactQueryArgs, requestFn, {
      refetchOnWindowFocus: true,
      staleTime: 1000,
      cacheTime: 1000,
      ...config,
    })

    return {
      data: response?.data || undefined,
      error: error ?? response?.errors,
      errors: response?.errors,
      loading: isLoading,
      isFetching: isLoading,
      isFetchingInitial: status === 'loading',
      refetch,
      status,
      failureCount,
      isStale,
      ...rest,
    }
  }

  const useLazyRequest = (
    queryRequest: QueryRequest | null | false,
    { initialData, ...config }: QueryConfig<ExecutionResult<Query>> = {},
  ): Partial<UseQueryResult> => {
    const requestFn: QueryFunction<ExecutionResult<Query>> = () => customClient.query(queryRequest || {})
    const queryKey = queryRequest && JSON.stringify(queryRequest)
    const {
      data: response,
      error,
      isFetching: isLoading,
      refetch,
      status,
      failureCount,
      isStale,
    } = useReactQuery<ExecutionResult<Query>, string>(queryKey, requestFn, {
      initialData,
      staleTime: 0,
      cacheTime: 0,
      ...config,
    })

    return {
      data: response?.data || undefined,
      error,
      errors: response?.errors,
      loading: isLoading,
      isFetching: isLoading,
      isFetchingInitial: status === 'loading',
      refetch,
      status,
      failureCount,
      isStale,
    }
  }

  const useMutation = (
    initialMutationRequest?: MutationRequest,
    // @ts-ignore
    deps?: any[],
  ): [
    (mutationRequest: MutationRequest) => Promise<ExecutionResult<Mutation> | undefined>,
    UseLazyQueryResult<Mutation>,
  ] => {
    const resultRef = useRef<UseLazyQueryResult<Mutation>>({})

    const mutate = useCallback(
      async (mutationRequest: MutationRequest) => {
        try {
          const req = mutationRequest ?? initialMutationRequest
          if (!req) {
            throw new Error('You have to specify the mutationRequest')
          }
          resultRef.current = { ...resultRef.current, loading: true }
          const res = await customClient.mutation(mutationRequest)
          if (res.errors) {
            throw new MutationError('Mutation error', { errors: res.errors })
          }
          resultRef.current = { ...res, loading: false }
          return res
        } catch (err) {
          resultRef.current = { errors: err, loading: false }
          throw err
        }
      },
      [initialMutationRequest],
    )

    return [mutate, resultRef.current]
  }

  const useMutation2 = (
    initialMutationRequest?: MutationRequest,
    // @ts-ignore
    deps?: any[],
  ): {
    mutate: (mutationRequest: MutationRequest) => Promise<ExecutionResult<Mutation> | undefined>
    result?: UseLazyQueryResult<Mutation>
  } => {
    const [result, setResult] = useState<UseLazyQueryResult<Mutation>>({})
    // const resultRef = useRef<UseLazyQueryResult<Mutation>>({})

    const mutate = useCallback(
      async (mutationRequest: MutationRequest) => {
        try {
          const req = mutationRequest ?? initialMutationRequest
          if (!req) {
            throw new Error('You have to specify the mutationRequest')
          }

          setResult({ ...result, loading: true })
          // resultRef.current = { ...resultRef.current, loading: true }
          const res = await customClient.mutation(mutationRequest)
          if (res.errors) {
            throw new MutationError('Mutation error', { errors: res.errors })
          }
          setResult({ ...result, ...res, loading: false })
          // resultRef.current = { ...res, loading: false }
          return res
        } catch (err) {
          setResult({ errors: err, loading: false })
          // resultRef.current = { errors: err, loading: false }
          throw err
        }
      },
      [initialMutationRequest],
    )

    return { mutate, result }
  }

  const queryCache = new QueryCache()

  return { useRequest, useLazyRequest, useMutation, useMutation2, useQueryCache, queryCache, ReactQueryCacheProvider }
}

type MergeDataFunction<T = any> = (result: ExecutionResult<Query>[]) => T

export type InfiniteQueryConfigWithMerge = InfiniteQueryConfig<ExecutionResult<Query>, string> & {
  mergeDataFn?: MergeDataFunction
}

export interface UseLazyQueryResult<T> extends ExecutionResult<T> {
  loading?: boolean
}

class MutationError extends Error {
  constructor(message: string, extra?: any) {
    super(message)
    Object.assign(this, extra)
  }
}
