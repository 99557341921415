import { useCallback, useEffect, useRef, useState } from 'react'
import throttle from 'lodash-es/throttle'
import { initLogger } from '../lib/initLogger'

type Options = {
  seconds?: number
  postponeOnActivity?: boolean
}

const DEFAULT_OPTS: Options = {
  seconds: 8,
  postponeOnActivity: false,
}

const activityEvents = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']

const logger = initLogger('useTimeoutCounter')

export const useTimeoutCounter = (defaultCb?: Function) => {
  const intervalRef = useRef<any>()
  const timeLeftIntervalRef = useRef<any>()
  // const enableArgs = useRef<[Options, Function?] | null>()
  const [enableArgs, setEnableArgs] = useState<[Options, Function?] | null>()
  const [isActive, setActive] = useState(false)
  const [secondsLeft, setSecondsLeft] = useState(0)

  const enable = useCallback(
    (opts: Options, cb?: Function) => {
      const options = { ...DEFAULT_OPTS, ...opts } as Required<Options>
      logger.debug(options, 'enabled')
      setEnableArgs([options, cb])
      const ms = options.seconds * 1000
      const endDateTime = new Date(Date.now() + ms).getTime()
      clearTimeout(intervalRef.current)
      clearTimeout(timeLeftIntervalRef.current)

      setActive(() => true)
      intervalRef.current = setInterval(() => {
        if (Date.now() >= endDateTime) {
          logger.info('timeout expired')
          setActive(() => false)
          setSecondsLeft(() => 0)
          clearTimeout(intervalRef.current)
          clearTimeout(timeLeftIntervalRef.current)
          ;(cb ?? defaultCb)?.()
        }
      }, 200)

      logger.debug(Math.round((endDateTime - Date.now()) / 1000) + '')
      setSecondsLeft(() => Math.round((endDateTime - Date.now()) / 1000))

      timeLeftIntervalRef.current = setInterval(() => {
        logger.debug('setting seconds left')
        logger.debug(Math.round((endDateTime - Date.now()) / 1000) + '')
        setSecondsLeft(() => Math.round((endDateTime - Date.now()) / 1000))
      }, 1000)
    },
    [defaultCb],
  )

  const clear = useCallback(() => {
    clearTimeout(intervalRef.current)
    clearTimeout(timeLeftIntervalRef.current)
    setEnableArgs(null)
    setActive(() => false)
  }, [])

  useEffect(() => {
    const listen = throttle(() => {
      if (enableArgs?.[0]?.postponeOnActivity) {
        enable(...enableArgs)
      }
    }, 400)

    activityEvents.forEach((ev) => window.addEventListener(ev, listen))

    return () => activityEvents.forEach((ev) => window.removeEventListener(ev, listen))
  }, [enable, enableArgs?.[0]?.postponeOnActivity])

  return { enable, clear, isActive, secondsLeft, options: enableArgs?.[0] }
}
