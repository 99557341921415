import React from 'react'
import ReactDOM from 'react-dom'
import { registerServiceWorker } from '@gaudia/ui-common'
import 'modern-normalize/modern-normalize.css'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { store } from './store'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_ENV === 'production') {
  LogRocket.init('gaudia/gaudia')
}

Sentry.init({
  dsn: 'https://2eb9506c108c48b69a3984c2485f7a39@o444161.ingest.sentry.io/5702439',
  environment: process.env.REACT_APP_ENV,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

registerServiceWorker({
  baseUrl: process.env.PUBLIC_URL,
  onUpdateFound: () => {
    store.hasUpdate = true
  },
})
