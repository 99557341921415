import React, { useCallback, useMemo, Fragment, useEffect } from 'react'
import { Box, Button, Input } from '../../components/layout'
import { Text } from '../../components/layout/Text'
import { jsonLogic } from '@gaudia/shared'

export interface Content {
  name?: string
  type:
    | 'row'
    | 'col'
    | 'box'
    | 'card'
    | 'text'
    | 'paragraph'
    | 'title'
    | 'button'
    | 'navigation'
    | 'image'
    | 'input'
    | 'list'
    | 'repeater'
  props?: any
  elements?: Content[]
  onMount?: any
}

const WrapperComp: React.FC<{ type?: Content['type'] }> = ({ type, children }) => {
  if (type === 'list') {
    return <Box as="li">{children}</Box>
  }

  return <Fragment>{children}</Fragment>
}

export const LayoutItem: React.FC<{
  content?: Content
  state?: Record<string, any>
  updateState?: Function
  performAction?: Function
}> = React.memo(
  ({ content, state, updateState, performAction }) => {
    const renderedChildren = useMemo(
      () =>
        content?.elements?.map((el, index: number) => {
          return (
            <WrapperComp type={content?.type} key={index}>
              <LayoutItem
                content={el}
                state={state}
                updateState={updateState}
                performAction={performAction}
              />
            </WrapperComp>
          )
        }),
      [content?.elements, state, updateState],
    )

    const handleClick = useCallback(() => {
      performAction?.(content?.props?.onClick)
    }, [content?.props?.onClick, performAction])

    const handleMouseUp = useCallback(() => {
      performAction?.(content?.props?.onMouseUp)
    }, [content?.props?.onClick, performAction])

    const formattedProps = useMemo(() => {
      const props = {
        ...(content?.props ?? {}),
        state,
        updateState,
        onClick: content?.props?.onClick ? handleClick : null,
        onMouseUp: content?.props?.onMouseUp ? handleMouseUp : null,
      }

      return props
    }, [content?.props, handleClick, handleMouseUp, state, updateState])

    const repeatedItems = useMemo(() => {
      if (content?.type !== 'repeater') return null

      return formattedProps?.data?.map((i: any, index: number) => {
        const itemProps = jsonLogic.mapper(
          { $item: i },
          formattedProps.itemMapping,
          // (key) => !['onSuccess', 'onReceive', 'onClick'].includes(key),
        )

        const item = itemProps

        return (
          <LayoutItem
            key={index}
            content={item}
            state={state}
            updateState={updateState}
            performAction={performAction}
          />
        )
      })
    }, [
      content?.type,
      formattedProps.itemProps,
      formattedProps?.items,
      performAction,
      state,
      updateState,
    ])

    /******************************
     * Steps Lifecycle hooks
     ******************************/

    // onMount effects
    useEffect(() => {
      if (content?.onMount) {
        // const cleanEffects = performAction(content?.onMount)
        performAction?.(content?.onMount)

        return () => {
          // cleanEffects.forEach((fn) => fn())
        }
      }
    }, [JSON.stringify(content?.onMount)])

    if (typeof formattedProps?.visible !== 'undefined' && !formattedProps?.visible) {
      return null
    }

    return (
      <>
        {content?.type === 'box' && (
          <Box
            display="flex"
            flex={1}
            alignItems="stretch"
            justifyContent="center"
            variant="box.default"
            {...formattedProps}
          >
            {renderedChildren}
          </Box>
        )}

        {content?.type === 'card' && (
          <Box
            display="flex"
            flex={1}
            alignItems="stretch"
            justifyContent="center"
            variant="card.default"
            {...formattedProps}
          >
            {renderedChildren}
          </Box>
        )}

        {/*{content?.type === 'row' && (
          <Row
            justify="space-between"
            style={{ flex: 1, width: '100%' }}
            gutter={12}
            {...formattedProps}
          >
            {renderedChildren}
          </Row>
        )}

        {content?.type === 'col' && (
          <Col flex={1} style={{ flex: 1 }} {...formattedProps}>
            {renderedChildren}
          </Col>
        )}*/}

        {content?.type === 'text' && (
          <Text variant="default" {...formattedProps}>
            {content.props?.value}
          </Text>
        )}

        {content?.type === 'paragraph' && (
          <Text as="p" display="block" m={0} variant="default" {...formattedProps}>
            {renderedChildren}
          </Text>
        )}

        {content?.type === 'list' && (
          <Box as={content.props?.ordered ? 'ol' : 'ul'} variant="default" {...formattedProps}>
            {renderedChildren}
          </Box>
        )}

        {content?.type === 'button' && (
          <Button variant="default" {...formattedProps}>
            {content.props?.value ?? renderedChildren}
          </Button>
        )}

        {content?.type === 'image' && <Box as="img" variant="image.default" {...formattedProps} />}

        {content?.type === 'input' && <Input variant="default" {...formattedProps} />}

        {content?.type === 'repeater' && <>{repeatedItems}</>}
      </>
    )
  },
  (prevProps, nextProps) => {
    const areEqual = JSON.stringify(prevProps?.content) === JSON.stringify(nextProps?.content)

    return areEqual
    // }
  },
)
