import { proxy, useSnapshot } from 'valtio'
import { useCallback } from 'react'
import forEach from 'lodash/forEach'
import set from 'lodash/set'
import assign from 'lodash/assign'

export const centralState = proxy<Record<string, any>>({})

export const useStateController = () => {
  const snapshot = useSnapshot(centralState)

  const updateState = useCallback((update: object | [string, any] | null) => {
    if (update === null) {
      return forEach(centralState, (val, key) => {
        delete centralState[key]
      })
    }

    if (Array.isArray(update)) {
      return set(centralState, update[0], update[1])
    }

    if (typeof update === 'object') {
      return assign(centralState, update)
    }

    return centralState
  }, [])

  return { state: snapshot, updateState }
}
