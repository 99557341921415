import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Bluebird from 'bluebird'
import _ from 'lodash'
import { useStateController } from './useStateController'
import { jsonLogic } from '@gaudia/shared'
import { useDocumentInput } from './useDocumentInput'
import { useTimeoutCounter } from './useTimeoutCounter'
import { TextToSpeech } from '../TextToSpeech'

const EXCLUDED_MAPPING_PATHS = [
  'onSuccess',
  'onReceive',
  'onChange',
  'onClick',
  'itemMapping',
  'watcherPath',
]

export const useJsonLayout = <Schema>({ content }: { content?: any } = {}) => {
  /******************************
   * State
   ******************************/
  const { state, updateState, context, updateContext } = useStateController()

  /******************************
   * Document input
   ******************************/
  const [isDocumentInputSubscriberEnabled, setDocumentInputSubscriberEnabled] = useState(false)
  const [externalInputData, setExternalInputData] = useState<string | null>()

  // react to document input
  const [documentInputListeners, documentInputListenersDispatch] = useReducer(
    (state: Function[], { action, callback }: { action: 'add' | 'remove'; callback: Function }) => {
      if (action === 'add') {
        return [...state, callback]
      }

      if (action === 'remove') {
        return state.filter((f) => f !== callback)
      }

      return state
    },
    [],
  )

  const documentInputController = useDocumentInput(
    isDocumentInputSubscriberEnabled,
    (input: string) => setExternalInputData(input),
  )

  // update state
  useEffect(() => {
    updateState({
      externalInputData,
    })
  }, [externalInputData])

  useEffect(() => {
    if (state?.externalInputData && documentInputListeners.length) {
      documentInputListeners.forEach((fn) => fn())
    }
  }, [documentInputListeners, state?.externalInputData])

  /******************************
   * Timeout and idle handling
   ******************************/

  const timeoutController = useTimeoutCounter()

  useEffect(() => {
    updateState({
      timeout: {
        secondsLeft: timeoutController.secondsLeft,
        options: timeoutController.options,
      },
    })
  }, [timeoutController.secondsLeft, timeoutController.options, updateState])

  /******************************
   * Actions
   ******************************/

  const [actionsQueue, actionsQueueDispatch] = useReducer(
    (state: object[], { type, action }: { type: 'add' | 'remove'; action: object }) => {
      if (type === 'add') {
        return [...state, action]
      }

      if (type === 'remove') {
        return state.filter((a) => a !== action)
      }

      return state
    },
    [],
  )

  useEffect(() => {
    if (actionsQueue?.[0]) {
      Bluebird.try(() => performActions(actionsQueue[0])).finally(() =>
        actionsQueueDispatch({ type: 'remove', action: actionsQueue[0] }),
      )
    }
  }, [actionsQueue?.[0]])

  const watchersRef = useRef<{ watcherPath: string; action: any }[]>([])
  let prevContext = useRef()

  useEffect(() => {
    watchersRef.current.forEach(({ watcherPath, action }) => {
      const previousVal = _.get(prevContext.current, watcherPath)
      const currentVal = _.get(context, watcherPath)

      if (!_.isEqual(previousVal, currentVal)) {
        prevContext.current = JSON.parse(JSON.stringify(context))
        // performActions(action?.onChange, jsonContext)

        actionsQueueDispatch({ type: 'add', action: action })
      }
    })
  }, [context, state])

  const textToSpeech = useMemo(() => new TextToSpeech(), [])

  const ctx = context
  const performActions = useCallback(
    (actionOrActions, context: any = ctx) => {
      const cleanEffects: Function[] = []

      const parsedActionOrActions = jsonLogic.mapper(
        { ...context, $state: context?.$state ?? state, $args: context?.$args },
        actionOrActions,
        { mustEvaluate: (key) => !EXCLUDED_MAPPING_PATHS.includes(key) },
      )
      const actions = Array.isArray(parsedActionOrActions)
        ? parsedActionOrActions
        : [parsedActionOrActions]

      // @ts-ignore
      actions
        ?.filter((a) => a)
        ?.forEach?.(({ action }) => {
          if (!action) return
          const actionName = action.name
          const payload = action.payload

          const onSuccessAction = action?.onSuccess
          const onErrorAction = action?.onError

          if (actionName === 'updateState') {
            updateState(payload)
            // actionsQueueDispatch({ type: 'add', action: action })
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
          }

          if (actionName === 'subscribeToExternalInput') {
            setDocumentInputSubscriberEnabled(true)
            setExternalInputData('')
            documentInputController.clear()
            if (onSuccessAction) {
              performActions(onSuccessAction)
            }
            const callback = () => {
              if (action?.onReceive) {
                // performActions(action?.onReceive, state)
                actionsQueueDispatch({ type: 'add', action: action?.onReceive })
              }
            }
            documentInputListenersDispatch({ action: 'add', callback })
            cleanEffects.push(() => {
              setDocumentInputSubscriberEnabled(false)
              setExternalInputData('')
              documentInputListenersDispatch({ action: 'remove', callback })
            })
          }

          if (actionName === 'resetState') {
            updateState(null)
          }

          if (actionName === 'startCountdown') {
            timeoutController.enable(
              {
                seconds: payload?.durationInSeconds,
                postponeOnActivity: payload?.postponeOnActivity,
              },
              () => onSuccessAction && performActions(onSuccessAction),
            )

            cleanEffects.push(() => {
              timeoutController.clear()
            })
          }

          if (actionName === 'speak') {
            textToSpeech
              .play(payload?.ssml, payload?.queue)
              .then(
                (result) =>
                  onSuccessAction &&
                  performActions(onSuccessAction, { $result: { success: true } }),
              )
              .catch((err) => onErrorAction && performActions(onErrorAction, { $err: err }))

            cleanEffects.push(() => textToSpeech.stop())
          }

          if (actionName === 'watch') {
            const watchConfig = {
              watcherPath: payload?.watcherPath,
              action: action?.onChange,
            }
            watchersRef.current.push(watchConfig)

            cleanEffects.push(() => {
              const index = (watchersRef.current ?? []).indexOf(watchConfig)
              if (index !== -1) {
                watchersRef.current.splice(index, 1)
              }
            })
          }
        })

      return cleanEffects
    },
    [state, context],
  )

  /******************************
   * Steps Lifecycle hooks
   ******************************/

  /*// onMount effects
  useEffect(() => {
    if (content?.onMount) {
      const cleanEffects = performActions(content?.onMount)

      return () => {
        cleanEffects.forEach((fn) => fn())
      }
    }
  }, [content?.onMount])*/

  const [mappedContent, setMappedContent] = useState()

  useEffect(() => {
    const mapped = jsonLogic.mapper({ ...(context ?? {}), $state: context?.$state }, content, {
      mustEvaluate: (key) => !EXCLUDED_MAPPING_PATHS.includes(key),
    })

    setMappedContent(() => mapped)
  }, [content, context])

  return {
    content: mappedContent,
    state,
    updateState,
    performActions,
    context,
    setContext: updateContext,
  }
}
