import { createClient } from '@gaudia/ui-common'
import { httpClient } from './lib/httpClient'

export default createClient({
  // @ts-ignore
  fetcher: ({ query, variables }, fetch, qs) => {
    return httpClient
      .client({ method: 'POST', data: { query, variables }, withCredentials: true })
      .then(({ data }) => data)
  },
})
