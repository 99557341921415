import React from 'react'
import { Step } from '@gaudia/ui-common'
import { useLocation, useParams } from 'react-router-dom'
import { withIdentityCheck } from '../../../components/withIdentityCheck'
import { Wrapper } from './CheckIn.styled'
import { LayoutItem } from './layoutRenderer/LayoutItem'
import { useCheckin } from '../useCheckin'
import { AblyProvider } from '../../../lib/ably'
import { useToken } from '../../../components/useToken'
import { BaseTheme } from '../../../components/BaseTheme'
import { useSmartUpdate } from '../useSmartUpdate'
import customClient from '../../../customClient'
import { OfflineWarning } from '../../../components/OfflineWarning'

function registerGlobalUpdater(checkinKioskId?: string, UI?: any) {
  // @ts-ignore
  window.__updateCheckInKiosk = () =>
    customClient
      .mutation({
        // @ts-ignore
        updateCheckinKioskById: [{ id: checkinKioskId, data: { interface: UI } }, { id: true }],
      })
      .then(console.log)
      .catch(console.error)
}

const DebugBar = React.memo(({ state }: { state?: Record<string, any> }) => {
  return <>state: {JSON.stringify(state)}</>
})

const CheckInRender: React.FC<{ checkinKioskId?: string }> = React.memo(({ checkinKioskId }) => {
  const checkinController = useCheckin({ checkinKioskId })
  useSmartUpdate(checkinController.checkinKiosk, checkinController?.steps?.currentStepIndex)
  // registerGlobalUpdater(checkinKioskId, checkinController?.checkinKiosk?.interface)

  return (
    <>
      <OfflineWarning />
      <BaseTheme theme={checkinController?.checkinKiosk?.interface?.theme ?? {}}>
        <Wrapper>
          {checkinController?.checkinKiosk?.interface?.debugMode && (
            <DebugBar state={checkinController?.state} />
          )}
          {checkinController.steps.currentStep && (
            <LayoutItem
              state={checkinController.state}
              checkinKiosk={checkinController?.checkinKiosk}
              updateState={checkinController.updateState}
              // @ts-ignore
              content={checkinController.content}
              goToPreviousStep={checkinController.steps.goToPreviousStep}
              goToNextStep={checkinController.steps.goToNextStep}
              goToStep={checkinController.steps.goToStep}
              performAction={checkinController.performActions}
            />
          )}
          {/*</Inner>*/}
        </Wrapper>
      </BaseTheme>
    </>
  )
})

export const CheckIn = React.memo(() => {
  const { id: checkinKioskId, step: stepName } = useParams<{
    id: string
    step: Step['name']
  }>()
  const location = useLocation()
  useToken({ locationSearch: location?.search })

  return (
    <AblyProvider isAuthenticated={true}>
      <CheckInRender checkinKioskId={checkinKioskId} />
    </AblyProvider>
  )
})

CheckIn.displayName = 'CheckIn'

export default withIdentityCheck(CheckIn, true)
