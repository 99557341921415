import Dexie from 'dexie'

interface ITextToSpeech {
  id?: string
  data: ArrayBuffer
  lastUsed: Date
}

class SignageDB extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  textToSpeeches: Dexie.Table<ITextToSpeech, string> // number = type of the primkey
  //...other tables goes here...

  constructor() {
    super('GaudiaSignage')
    this.version(1).stores({
      textToSpeeches: '++id, *data, *lastUsed',
      //...other tables goes here...
    })
    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.textToSpeeches = this.table('textToSpeeches')
  }
}

export const signageDB = new SignageDB()
