import { useRequest } from './queryHooks'

export const useIdentity = () => {
  const identityQuery = useRequest(
    {
      identity: {
        __typename: true,
        on_Monitor: {
          id: true,
          name: true,
        },
        on_CheckinKiosk: {
          id: true,
          name: true,
        },
        on_User: {
          id: true,
          firstname: true,
          lastname: true,
          email: true,
        },
      },
    },
    { refetchOnWindowFocus: false, cacheTime: Infinity, staleTime: Infinity },
  )

  return identityQuery
}
