import { useCallback, useReducer } from 'react'
import set from 'lodash/fp/set'
import assign from 'lodash/assign'

export const useStateController = () => {
  const setFunction = (source: any, update: any) => {
    if (update === null) {
      return {}
    }

    if (Array.isArray(update)) {
      return set(update[0], update[1], source)
    }

    if (typeof update === 'object') {
      return assign({}, source, update)
    }
  }

  // react to document input
  const [context, dispatch] = useReducer(
    (context: any, { action, update }: { action: 'setContext' | 'setState'; update: any }) => {
      if (action === 'setContext') {
        return setFunction(context, update)
      }

      if (action === 'setState') {
        const $state = setFunction(context?.$state, update)

        return { ...context, $state }
      }

      return context
    },
    {},
  )

  const updateState = useCallback((update) => dispatch({ action: 'setState', update }), [dispatch])
  const updateContext = useCallback((update) => dispatch({ action: 'setContext', update }), [
    dispatch,
  ])

  return { state: context?.$state, context, updateState, updateContext }
}
