import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import routes from './features/routes'
import { queryCache, ReactQueryCacheProvider } from './components/queryHooks'
import { withIdentityCheck } from './components/withIdentityCheck'
import { IdentityObserver } from './components/IdentityObserver'
import { AblyProvider } from './lib/ably'

function Main() {
  return (
    <>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AblyProvider isAuthenticated={true}>
          {routes.map(({ path, component: Component, redirect }: any) => (
            <Route path={path} key={path} exact>
              {redirect && <Redirect to={redirect} />}
              {Component && <Component />}
            </Route>
          ))}
        </AblyProvider>

        <IdentityObserver />
      </ReactQueryCacheProvider>
    </>
  )
}

export default withIdentityCheck(Main)
