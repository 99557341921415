(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("bluebird"), require("graphql-typed-client"), require("react"), require("react-query"), require("ably"), require("crypto-js"), require("dexie"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "bluebird", "graphql-typed-client", "react", "react-query", "ably", "crypto-js", "dexie"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("axios"), require("bluebird"), require("graphql-typed-client"), require("react"), require("react-query"), require("ably"), require("crypto-js"), require("dexie")) : factory(root["axios"], root["bluebird"], root["graphql-typed-client"], root["react"], root["react-query"], root["ably"], root["crypto-js"], root["dexie"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__300__, __WEBPACK_EXTERNAL_MODULE__221__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__659__, __WEBPACK_EXTERNAL_MODULE__460__, __WEBPACK_EXTERNAL_MODULE__25__) => {
return 