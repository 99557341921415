import { Observable } from 'graphql-typed-client'

export interface Query {
  locationTypes: LocationTypesConnection | null
  locationType: LocationType | null
  locationTypeById: LocationType | null
  locations: LocationsConnection | null
  location: Location | null
  locationById: Location | null
  locationsForTransfer: LocationsConnection | null
  locationTree: (LocationWithChildren | null)[] | null
  bookingsForVisitor: (Booking | null)[] | null
  bookings: BookingsConnection | null
  booking: Booking | null
  bookingById: Booking | null
  visits: VisitsConnection | null
  visit: Visit | null
  visitById: Visit | null
  startedVisitsByStartedById: (StartedVisitsByStartedByIdResult | null)[] | null
  speak: SpeakResult | null
  visitTags: (Tag | null)[] | null
  queueCounts: (QueueCounts | null)[] | null
  visitors: VisitorsConnection | null
  visitor: Visitor | null
  visitorById: Visitor | null
  journeyForVisitor: JourneyForVisitorResponse | null
  users: UsersConnection | null
  user: User | null
  userById: User | null
  identity: Identity | null
  signageSessionTokens: (Token | null)[] | null
  checkTokenValidity: Token | null
  organizations: OrganizationsConnection | null
  organization: Organization | null
  organizationById: Organization | null
  monitors: MonitorsConnection | null
  monitor: Monitor | null
  monitorById: Monitor | null
  monitorVisitsByLocationId: (MonitorVisitsByLocationIdResult | null)[] | null
  checkinKiosks: CheckinKiosksConnection | null
  checkinKiosk: CheckinKiosk | null
  checkinKioskById: CheckinKiosk | null
  checkinVirtualKiosks: CheckinVirtualKiosksConnection | null
  checkinVirtualKiosk: CheckinVirtualKiosk | null
  checkinVirtualKioskById: CheckinVirtualKiosk | null
  virtualCheckinSessions: VirtualCheckinSessionsConnection | null
  virtualCheckinSession: VirtualCheckinSession | null
  virtualCheckinSessionById: VirtualCheckinSession | null
  memberships: MembershipsConnection | null
  membership: Membership | null
  membershipById: Membership | null
  roles: RolesConnection | null
  role: Role | null
  roleById: Role | null
  userRoles: UserRolesConnection | null
  userRole: UserRole | null
  userRoleById: UserRole | null
  analyticsVisitsCount: AnalyticsVisitsCountResponse | null
  analyticsVisitsDuration: AnalyticsVisitsDurationResponse | null
  analyticsVisitsWaitingTime: AnalyticsVisitsWaitingTimeResponse | null
  agents: AgentsConnection | null
  agent: Agent | null
  agentById: Agent | null
  printers: PrintersConnection | null
  printer: Printer | null
  printerById: Printer | null
  scans: ScansConnection | null
  scan: Scan | null
  scanById: Scan | null
  notifications: NotificationsConnection | null
  ablyAuthenticate: AblyAuthorizeResponse | null
  ablyEnabled: AblyEnabledResponse | null
  triggers: TriggersConnection | null
  trigger: Trigger | null
  triggerById: Trigger | null
  templates: TemplatesConnection | null
  template: Template | null
  templateById: Template | null
  getPreference: Preference | null
  deviceLogs: DeviceLogsConnection | null
  deviceLog: DeviceLog | null
  deviceLogById: DeviceLog | null
  __typename: 'Query'
}

/** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
export type String = string

/** The `Boolean` scalar type represents `true` or `false`. */
export type Boolean = boolean

/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
export type DateTime = any

/** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
export type Float = number

export interface LocationTypesConnection {
  items: (LocationType | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'LocationTypesConnection'
}

export interface LocationType {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String
  appearance: LocationTypeAppearance | null
  __typename: 'LocationType'
}

export interface LocationTypeAppearance {
  location: NameAndPluralName | null
  visitor: NameAndPluralName | null
  __typename: 'LocationTypeAppearance'
}

export interface NameAndPluralName {
  name: String | null
  pluralName: String | null
  __typename: 'NameAndPluralName'
}

export interface LocationsConnection {
  items: (Location | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'LocationsConnection'
}

export interface Location {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  shortName: String | null
  color: String | null
  locationTypeId: String
  parentLocationId: String | null
  queue: QueueSettings | null
  extraData: JSON | null
  parentLocation: Location | null
  locationType: LocationType | null
  __typename: 'Location'
}

export interface QueueSettings {
  hasQueue: Boolean | null
  visiting: VisitSettings | null
  waiting: VisitSettings | null
  onCallMarkPreviousVisitAsCompleted: Boolean | null
  __typename: 'QueueSettings'
}

export interface VisitSettings {
  maxDuration: Float | null
  __typename: 'VisitSettings'
}

/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
export type JSON = any

export interface LocationWithChildren {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  shortName: String | null
  color: String | null
  locationTypeId: String
  parentLocationId: String | null
  queue: QueueSettings | null
  extraData: JSON | null
  children: (LocationWithChildren | null)[] | null
  parentLocation: Location | null
  locationType: LocationType | null
  __typename: 'LocationWithChildren'
}

export interface Booking {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  visitorId: String | null
  date: DateTime | null
  time: String | null
  notes: String | null
  prescriptionCode: String | null
  locationId: String | null
  aspVisitId: String | null
  externalId: String | null
  attachmentIds: (String | null)[] | null
  extraData: JSON | null
  visitor: Visitor | null
  location: Location | null
  visit: Visit | null
  __typename: 'Booking'
}

export interface Visitor {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  firstname: String | null
  lastname: String | null
  fiscalCode: String | null
  sex: String | null
  healthCardNumber: String | null
  birth: BirthType | null
  address: Address | null
  contacts: (VisitorContacts | null)[] | null
  attachmentIds: (String | null)[] | null
  extraData: JSON | null
  fullName: String | null
  __typename: 'Visitor'
}

export interface BirthType {
  city: String | null
  province: String | null
  date: DateTime | null
  __typename: 'BirthType'
}

export interface Address {
  line1: String | null
  streetNumber: String | null
  city: String | null
  province: String | null
  __typename: 'Address'
}

export interface VisitorContacts {
  number: String | null
  __typename: 'VisitorContacts'
}

export interface Visit {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  ticketCode: String | null
  order: Float | null
  visitorId: String | null
  locationId: String | null
  checkinKioskId: String | null
  checkinVirtualKioskId: String | null
  startedAt: DateTime | null
  endedAt: DateTime | null
  endedReason: String | null
  endedNote: String | null
  duration: Float | null
  startedById: String | null
  bookingId: String | null
  endedById: String | null
  ticketPrinted: Boolean | null
  selfCheckin: Boolean | null
  canVisit: Boolean | null
  scanIds: (String | null)[] | null
  attachmentIds: (String | null)[] | null
  status: String | null
  tags: (Tag | null)[] | null
  startedBy: User | null
  visitor: Visitor | null
  location: Location | null
  scan: Scan | null
  scans: (Scan | null)[] | null
  __typename: 'Visit'
}

export interface Tag {
  text: String | null
  color: String | null
  __typename: 'Tag'
}

export interface User {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  email: String | null
  firstname: String | null
  lastname: String | null
  contacts: (UserContacts | null)[] | null
  shortName: String | null
  roles: (Role | null)[] | null
  memberships: (Membership | null)[] | null
  fullName: String | null
  __typename: 'User'
}

export interface UserContacts {
  number: String | null
  __typename: 'UserContacts'
}

export interface Role {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String
  policies: (Policy | null)[] | null
  __typename: 'Role'
}

export interface Policy {
  effect: String
  actions: String[]
  resources: (String | null)[] | null
  __typename: 'Policy'
}

export interface Membership {
  id: String | null
  _id: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  organizationId: String | null
  userId: String | null
  organization: Organization | null
  user: User | null
  __typename: 'Membership'
}

export interface Organization {
  id: String | null
  _id: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  timezone: String | null
  scheduledResetTime: String | null
  __typename: 'Organization'
}

export interface Scan {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  date: DateTime | null
  deviceId: String | null
  temperature: Float | null
  wearMask: Boolean | null
  checkinKioskId: String | null
  __typename: 'Scan'
}

export interface BookingsConnection {
  items: (Booking | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'BookingsConnection'
}

export interface VisitsConnection {
  items: (Visit | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'VisitsConnection'
}

export interface StartedVisitsByStartedByIdResult {
  startedById: String | null
  visitId: String | null
  visit: Visit | null
  startedBy: User | null
  __typename: 'StartedVisitsByStartedByIdResult'
}

export enum engine {
  standard = 'standard',
  neural = 'neural',
}

export interface SpeakResult {
  url: String | null
  __typename: 'SpeakResult'
}

export interface QueueCounts {
  counts: Counts | null
  location: Location | null
  locationId: String | null
  parentLocation: Location | null
  __typename: 'QueueCounts'
}

export interface Counts {
  waiting: Float | null
  started: Float | null
  ended: Float | null
  expected: Float | null
  __typename: 'Counts'
}

export interface VisitorsConnection {
  items: (Visitor | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'VisitorsConnection'
}

export interface JourneyForVisitorResponse {
  id: String | null
  locationShortName: String | null
  ticketCode: String | null
  currentTicketCode: String | null
  __typename: 'JourneyForVisitorResponse'
}

export interface UsersConnection {
  items: (User | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'UsersConnection'
}

export type Identity = User | Monitor | CheckinKiosk | Agent | Visitor

export interface Monitor {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  locationIds: (String | null)[] | null
  notification: MonitorNotification | null
  enableDebugMode: Boolean | null
  fontSizeMultiplier: Float | null
  interface: JSON | null
  locations: (Location | null)[] | null
  __typename: 'Monitor'
}

export interface MonitorNotification {
  type: String | null
  __typename: 'MonitorNotification'
}

export interface CheckinKiosk {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  locationConfigs: (LocationConfig | null)[] | null
  addUnknownVisitorsToLocationId: String | null
  uiTemplateId: String | null
  interface: CheckinKioskUI | null
  keyboard: Keyboard | null
  locations: (Location | null)[] | null
  __typename: 'CheckinKiosk'
}

export interface LocationConfig {
  locationId: String
  __typename: 'LocationConfig'
}

export interface CheckinKioskUI {
  steps: (Step | null)[] | null
  macros: JSON | null
  theme: JSON | null
  debugMode: Boolean | null
  __typename: 'CheckinKioskUI'
}

export interface Step {
  name: String
  onMount: JSON | null
  state: JSON | null
  content: JSON
  __typename: 'Step'
}

export interface Keyboard {
  active: Boolean | null
  __typename: 'Keyboard'
}

export interface Agent {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  queue: AgentQueue | null
  checkinKioskId: String | null
  ticketPrintTemplateId: String | null
  activationToken: AgentToken | null
  status: String | null
  printers: (Printer | null)[] | null
  healthChecks: AgentHealthChecksConnection | null
  __typename: 'Agent'
}

export interface AgentQueue {
  url: String | null
  accessKey: String | null
  secretKey: String | null
  __typename: 'AgentQueue'
}

export interface AgentToken {
  id: String
  consumerId: String
  type: String | null
  extra: Extra | null
  expireAt: DateTime | null
  lastUsed: DateTime | null
  createdAt: DateTime | null
  token: String | null
  consumer: TokenConsumer | null
  __typename: 'AgentToken'
}

export interface Extra {
  visitId: String | null
  __typename: 'Extra'
}

export type TokenConsumer = User | Monitor | CheckinKiosk | CheckinVirtualKiosk | Agent | Visitor

export interface CheckinVirtualKiosk {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  locationConfigs: (LocationConfig | null)[] | null
  locations: (Location | null)[] | null
  checkinURLs: (VirtualCheckinURL | null)[] | null
  __typename: 'CheckinVirtualKiosk'
}

export interface VirtualCheckinURL {
  token: Token | null
  url: String | null
  __typename: 'VirtualCheckinURL'
}

export interface Token {
  id: String
  consumerId: String
  type: String | null
  extra: Extra | null
  expireAt: DateTime | null
  lastUsed: DateTime | null
  createdAt: DateTime | null
  consumer: TokenConsumer | null
  __typename: 'Token'
}

export interface Printer {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  deviceId: String | null
  type: String | null
  enabled: Boolean | null
  agentId: String | null
  options: PrintingOptions | null
  __typename: 'Printer'
}

export interface PrintingOptions {
  media: String | null
  sides: String | null
  fitToPage: Boolean | null
  __typename: 'PrintingOptions'
}

export interface AgentHealthChecksConnection {
  items: (AgentHealthCheck | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'AgentHealthChecksConnection'
}

export interface AgentHealthCheck {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  agentId: String
  date: DateTime | null
  __typename: 'AgentHealthCheck'
}

export interface OrganizationsConnection {
  items: (Organization | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'OrganizationsConnection'
}

export interface MonitorsConnection {
  items: (Monitor | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'MonitorsConnection'
}

export interface MonitorVisitsByLocationIdResult {
  locationId: String | null
  visitId: String | null
  visit: Visit | null
  location: Location | null
  __typename: 'MonitorVisitsByLocationIdResult'
}

export interface CheckinKiosksConnection {
  items: (CheckinKiosk | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'CheckinKiosksConnection'
}

export interface CheckinVirtualKiosksConnection {
  items: (CheckinVirtualKiosk | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'CheckinVirtualKiosksConnection'
}

export interface VirtualCheckinSessionsConnection {
  items: (VirtualCheckinSession | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'VirtualCheckinSessionsConnection'
}

export interface VirtualCheckinSession {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  identifier: String | null
  checkinVirtualKioskId: String | null
  token: String | null
  status: String | null
  privacyAccepted: Boolean | null
  availableLocations: JSON | null
  choosenLocationId: JSON | null
  visitId: String | null
  dataLog: (Log | null)[] | null
  __typename: 'VirtualCheckinSession'
}

export interface Log {
  message: String | null
  date: DateTime | null
  __typename: 'Log'
}

export interface MembershipsConnection {
  items: (Membership | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'MembershipsConnection'
}

export interface RolesConnection {
  items: (Role | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'RolesConnection'
}

export interface UserRolesConnection {
  items: (UserRole | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'UserRolesConnection'
}

export interface UserRole {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  userId: String
  roleId: String
  role: Role | null
  __typename: 'UserRole'
}

export interface AnalyticsVisitsCountResponse {
  data: (AnalyticsVisitsCountResponseData | null)[] | null
  __typename: 'AnalyticsVisitsCountResponse'
}

export interface AnalyticsVisitsCountResponseData {
  count: Float | null
  date: String | null
  locationId: String | null
  location: Location | null
  startedById: String | null
  startedBy: User | null
  __typename: 'AnalyticsVisitsCountResponseData'
}

export interface AnalyticsVisitsDurationResponse {
  data: (AnalyticsVisitsDurationResponseData | null)[] | null
  __typename: 'AnalyticsVisitsDurationResponse'
}

export interface AnalyticsVisitsDurationResponseData {
  durationAvgSecs: Float | null
  durationAvgMins: Float | null
  date: String | null
  locationId: String | null
  location: Location | null
  startedById: String | null
  startedBy: User | null
  __typename: 'AnalyticsVisitsDurationResponseData'
}

export interface AnalyticsVisitsWaitingTimeResponse {
  data: (AnalyticsVisitsWaitingTimeResponseData | null)[] | null
  __typename: 'AnalyticsVisitsWaitingTimeResponse'
}

export interface AnalyticsVisitsWaitingTimeResponseData {
  waitingTimeAvgSecs: Float | null
  waitingTimeAvgMins: Float | null
  date: String | null
  locationId: String | null
  location: Location | null
  startedById: String | null
  startedBy: User | null
  __typename: 'AnalyticsVisitsWaitingTimeResponseData'
}

export interface AgentsConnection {
  items: (Agent | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'AgentsConnection'
}

export interface PrintersConnection {
  items: (Printer | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'PrintersConnection'
}

export interface ScansConnection {
  items: (Scan | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'ScansConnection'
}

export interface NotificationsConnection {
  items: (Notification | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'NotificationsConnection'
}

export interface Notification {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  severity: String
  message: String | null
  senderId: String
  senderType: String
  extra: Extra | null
  receivers: (Receiver | null)[] | null
  checkinKiosk: CheckinKiosk | null
  scan: Scan | null
  visitor: Visitor | null
  sender: Sender | null
  __typename: 'Notification'
}

export interface Receiver {
  receiverType: String
  receiverId: String
  readAt: DateTime | null
  dismissedAt: DateTime | null
  __typename: 'Receiver'
}

export type Sender = CheckinKiosk

export interface AblyAuthorizeResponse {
  capability: String | null
  clientId: String | null
  expires: Float | null
  issued: Float | null
  token: String | null
  __typename: 'AblyAuthorizeResponse'
}

export interface AblyEnabledResponse {
  uiEnabled: Boolean | null
  __typename: 'AblyEnabledResponse'
}

export interface TriggersConnection {
  items: (Trigger | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'TriggersConnection'
}

export interface Trigger {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  event: String | null
  sync: Boolean | null
  continueOnError: Boolean | null
  actions: (JSON | null)[] | null
  __typename: 'Trigger'
}

export interface TemplatesConnection {
  items: (Template | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'TemplatesConnection'
}

export interface Template {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  name: String | null
  content: JSON | null
  __typename: 'Template'
}

export interface Preference {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  userId: String | null
  name: String | null
  entityIds: (String | null)[] | null
  fields: JSON | null
  __typename: 'Preference'
}

export interface DeviceLogsConnection {
  items: (DeviceLog | null)[] | null
  total: Float | null
  skip: Float | null
  limit: Float | null
  __typename: 'DeviceLogsConnection'
}

export interface DeviceLog {
  id: String | null
  _id: String | null
  organizationId: String | null
  createdAt: DateTime | null
  updatedAt: DateTime | null
  consumerId: String
  consumerType: String
  tokenId: String | null
  eventName: String
  message: String | null
  userAgent: UserAgent | null
  timestamp: DateTime | null
  __typename: 'DeviceLog'
}

export interface UserAgent {
  ua: String | null
  browser: Browser | null
  engine: Engine | null
  os: Os | null
  device: Device | null
  cpu: JSON | null
  __typename: 'UserAgent'
}

export interface Browser {
  name: String | null
  version: String | null
  major: String | null
  __typename: 'Browser'
}

export interface Engine {
  name: String | null
  version: String | null
  __typename: 'Engine'
}

export interface Os {
  name: String | null
  version: String | null
  __typename: 'Os'
}

export interface Device {
  vendor: String | null
  model: String | null
  __typename: 'Device'
}

export interface Mutation {
  createLocationType: LocationType | null
  updateLocationTypeById: LocationType | null
  updateLocationType: LocationType | null
  deleteLocationTypeById: LocationType | null
  createLocation: Location | null
  updateLocationById: Location | null
  updateLocation: Location | null
  deleteLocationById: Location | null
  updateHierarchy: UpdateHierarchyResult | null
  addBooking: Booking | null
  createBooking: Booking | null
  updateBookingById: Booking | null
  updateBooking: Booking | null
  deleteBookingById: Booking | null
  createVisit: Visit | null
  updateVisitById: Visit | null
  updateVisit: Visit | null
  deleteVisitById: Visit | null
  enqueue: Visit | null
  addScanToVisit: Visit | null
  printAllVisitAttachments: Success | null
  printAttachments: Success | null
  callVisit: Visit | null
  callNextVisit: Visit | null
  endVisit: Visit | null
  transferVisitor: Visit | null
  amendVisit: Visit | null
  startVisit: Visit | null
  sendTicketToPrint: SendToPrintResult | null
  sendToPrint: SendToPrintResult | null
  createVisitor: Visitor | null
  updateVisitorById: Visitor | null
  updateVisitor: Visitor | null
  deleteVisitorById: Visitor | null
  createUser: User | null
  updateUserById: User | null
  updateUser: User | null
  deleteUserById: User | null
  login: Success | null
  logout: Success | null
  resetPassword: Success | null
  resetPasswordRequest: Success | null
  createSignageSessionToken: CreateSignageSessionTokenResult | null
  createOrganization: Organization | null
  updateOrganizationById: Organization | null
  updateOrganization: Organization | null
  deleteOrganizationById: Organization | null
  createMonitor: Monitor | null
  updateMonitorById: Monitor | null
  updateMonitor: Monitor | null
  deleteMonitorById: Monitor | null
  startMonitorSession: Result | null
  refreshMonitor: Monitor | null
  createCheckinKiosk: CheckinKiosk | null
  updateCheckinKioskById: CheckinKiosk | null
  updateCheckinKiosk: CheckinKiosk | null
  deleteCheckinKioskById: CheckinKiosk | null
  startCheckinKioskSession: Result | null
  generateCheckinSignageUrl: GenerateCheckinSignageUrlResult | null
  refreshCheckinKiosk: CheckinKiosk | null
  sendCheckinKioskNotification: Success | null
  createCheckinVirtualKiosk: CheckinVirtualKiosk | null
  updateCheckinVirtualKioskById: CheckinVirtualKiosk | null
  updateCheckinVirtualKiosk: CheckinVirtualKiosk | null
  deleteCheckinVirtualKioskById: CheckinVirtualKiosk | null
  generateVirtualCheckinURL: VirtualCheckinURL | null
  deleteVirtualCheckinURL: Success | null
  createVirtualCheckinSession: VirtualCheckinSession | null
  updateVirtualCheckinSessionById: VirtualCheckinSession | null
  updateVirtualCheckinSession: VirtualCheckinSession | null
  deleteVirtualCheckinSessionById: VirtualCheckinSession | null
  createMembership: Membership | null
  updateMembershipById: Membership | null
  updateMembership: Membership | null
  deleteMembershipById: Membership | null
  inviteUser: Success | null
  activateUser: Success | null
  createRole: Role | null
  updateRoleById: Role | null
  updateRole: Role | null
  deleteRoleById: Role | null
  createUserRole: UserRole | null
  updateUserRoleById: UserRole | null
  updateUserRole: UserRole | null
  deleteUserRoleById: UserRole | null
  createAgent: AgentToken | null
  updateAgentById: Agent | null
  updateAgent: Agent | null
  deleteAgentById: Agent | null
  createPrinter: Printer | null
  updatePrinterById: Printer | null
  updatePrinter: Printer | null
  deletePrinterById: Printer | null
  generateAgentToken: AgentToken | null
  createAgentHealthCheck: AgentHealthCheck | null
  updateAgentPrinters: UpdateAgentPrintersResult | null
  createScan: Scan | null
  updateScanById: Scan | null
  updateScan: Scan | null
  deleteScanById: Scan | null
  markNotificationAsRead: Notification | null
  markNotificationAsDismissed: Notification | null
  createTrigger: Trigger | null
  updateTriggerById: Trigger | null
  updateTrigger: Trigger | null
  deleteTriggerById: Trigger | null
  createTemplate: Template | null
  updateTemplateById: Template | null
  updateTemplate: Template | null
  deleteTemplateById: Template | null
  updatePreference: Preference | null
  updateDefaultPreference: Preference | null
  createDeviceLog: DeviceLog | null
  updateDeviceLogById: DeviceLog | null
  updateDeviceLog: DeviceLog | null
  deleteDeviceLogById: DeviceLog | null
  __typename: 'Mutation'
}

export interface UpdateHierarchyResult {
  success: Boolean | null
  __typename: 'UpdateHierarchyResult'
}

export interface Success {
  success: Boolean | null
  __typename: 'Success'
}

export interface SendToPrintResult {
  success: Boolean | null
  visitId: String | null
  visit: Visit | null
  __typename: 'SendToPrintResult'
}

export interface CreateSignageSessionTokenResult {
  token: String | null
  url: String | null
  __typename: 'CreateSignageSessionTokenResult'
}

export interface Result {
  success: Boolean | null
  __typename: 'Result'
}

export interface GenerateCheckinSignageUrlResult {
  url: String | null
  __typename: 'GenerateCheckinSignageUrlResult'
}

export interface UpdateAgentPrintersResult {
  printers: (Printer | null)[] | null
  __typename: 'UpdateAgentPrintersResult'
}

export interface QueryRequest {
  locationTypes?:
    | [
        { where?: LocationTypeFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        LocationTypesConnectionRequest,
      ]
    | LocationTypesConnectionRequest
  locationType?:
    | [{ where?: LocationTypeFilterQueryInput | null; sort?: String | null }, LocationTypeRequest]
    | LocationTypeRequest
  locationTypeById?: [{ id?: String | null }, LocationTypeRequest] | LocationTypeRequest
  locations?:
    | [
        { where?: LocationFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        LocationsConnectionRequest,
      ]
    | LocationsConnectionRequest
  location?: [{ where?: LocationFilterQueryInput | null; sort?: String | null }, LocationRequest] | LocationRequest
  locationById?: [{ id?: String | null }, LocationRequest] | LocationRequest
  locationsForTransfer?:
    | [
        { where?: LocationFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        LocationsConnectionRequest,
      ]
    | LocationsConnectionRequest
  locationTree?: [{ id?: String | null }, LocationWithChildrenRequest] | LocationWithChildrenRequest
  bookingsForVisitor?: [{ args: BookingsQueryQueryInput }, BookingRequest]
  bookings?:
    | [
        { where?: BookingFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        BookingsConnectionRequest,
      ]
    | BookingsConnectionRequest
  booking?: [{ where?: BookingFilterQueryInput | null; sort?: String | null }, BookingRequest] | BookingRequest
  bookingById?: [{ id?: String | null }, BookingRequest] | BookingRequest
  visits?:
    | [
        { where?: VisitFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        VisitsConnectionRequest,
      ]
    | VisitsConnectionRequest
  visit?: [{ where?: VisitFilterQueryInput | null; sort?: String | null }, VisitRequest] | VisitRequest
  visitById?: [{ id?: String | null }, VisitRequest] | VisitRequest
  startedVisitsByStartedById?:
    | [{ where?: VisitFilterQueryInput | null }, StartedVisitsByStartedByIdResultRequest]
    | StartedVisitsByStartedByIdResultRequest
  speak?: [{ ssml: String; engine?: engine | null }, SpeakResultRequest]
  visitTags?: TagRequest
  queueCounts?:
    | [
        { where?: FetchQueueCountsCriteriaQueryInput | null; includeChildren?: Boolean | null; sort?: String | null },
        QueueCountsRequest,
      ]
    | QueueCountsRequest
  visitors?:
    | [
        { where?: VisitorFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        VisitorsConnectionRequest,
      ]
    | VisitorsConnectionRequest
  visitor?: [{ where?: VisitorFilterQueryInput | null; sort?: String | null }, VisitorRequest] | VisitorRequest
  visitorById?: [{ id?: String | null }, VisitorRequest] | VisitorRequest
  journeyForVisitor?: JourneyForVisitorResponseRequest
  users?:
    | [
        { where?: UserFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        UsersConnectionRequest,
      ]
    | UsersConnectionRequest
  user?: [{ where?: UserFilterQueryInput | null; sort?: String | null }, UserRequest] | UserRequest
  userById?: [{ id?: String | null }, UserRequest] | UserRequest
  identity?: IdentityRequest
  signageSessionTokens?: [
    { consumerId: String; consumerType: String; limit?: Float | null; skip?: Float | null },
    TokenRequest,
  ]
  checkTokenValidity?: [{ token: String }, TokenRequest]
  organizations?:
    | [
        { where?: OrganizationFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        OrganizationsConnectionRequest,
      ]
    | OrganizationsConnectionRequest
  organization?:
    | [{ where?: OrganizationFilterQueryInput | null; sort?: String | null }, OrganizationRequest]
    | OrganizationRequest
  organizationById?: [{ id?: String | null }, OrganizationRequest] | OrganizationRequest
  monitors?:
    | [
        { where?: MonitorFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        MonitorsConnectionRequest,
      ]
    | MonitorsConnectionRequest
  monitor?: [{ where?: MonitorFilterQueryInput | null; sort?: String | null }, MonitorRequest] | MonitorRequest
  monitorById?: [{ id?: String | null }, MonitorRequest] | MonitorRequest
  monitorVisitsByLocationId?:
    | [{ monitorId?: String | null }, MonitorVisitsByLocationIdResultRequest]
    | MonitorVisitsByLocationIdResultRequest
  checkinKiosks?:
    | [
        { where?: CheckinKioskFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        CheckinKiosksConnectionRequest,
      ]
    | CheckinKiosksConnectionRequest
  checkinKiosk?:
    | [{ where?: CheckinKioskFilterQueryInput | null; sort?: String | null }, CheckinKioskRequest]
    | CheckinKioskRequest
  checkinKioskById?: [{ id?: String | null }, CheckinKioskRequest] | CheckinKioskRequest
  checkinVirtualKiosks?:
    | [
        {
          where?: CheckinVirtualKioskFilterQueryInput | null
          limit?: Float | null
          skip?: Float | null
          sort?: String | null
        },
        CheckinVirtualKiosksConnectionRequest,
      ]
    | CheckinVirtualKiosksConnectionRequest
  checkinVirtualKiosk?:
    | [{ where?: CheckinVirtualKioskFilterQueryInput | null; sort?: String | null }, CheckinVirtualKioskRequest]
    | CheckinVirtualKioskRequest
  checkinVirtualKioskById?: [{ id?: String | null }, CheckinVirtualKioskRequest] | CheckinVirtualKioskRequest
  virtualCheckinSessions?:
    | [
        {
          where?: VirtualCheckinSessionFilterQueryInput | null
          limit?: Float | null
          skip?: Float | null
          sort?: String | null
        },
        VirtualCheckinSessionsConnectionRequest,
      ]
    | VirtualCheckinSessionsConnectionRequest
  virtualCheckinSession?:
    | [{ where?: VirtualCheckinSessionFilterQueryInput | null; sort?: String | null }, VirtualCheckinSessionRequest]
    | VirtualCheckinSessionRequest
  virtualCheckinSessionById?: [{ id?: String | null }, VirtualCheckinSessionRequest] | VirtualCheckinSessionRequest
  memberships?:
    | [
        { where?: MembershipFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        MembershipsConnectionRequest,
      ]
    | MembershipsConnectionRequest
  membership?: [{ where?: MembershipFilterQueryInput | null; sort?: String | null }, MembershipRequest] | MembershipRequest
  membershipById?: [{ id?: String | null }, MembershipRequest] | MembershipRequest
  roles?:
    | [
        { where?: RoleFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        RolesConnectionRequest,
      ]
    | RolesConnectionRequest
  role?: [{ where?: RoleFilterQueryInput | null; sort?: String | null }, RoleRequest] | RoleRequest
  roleById?: [{ id?: String | null }, RoleRequest] | RoleRequest
  userRoles?:
    | [
        { where?: UserRoleFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        UserRolesConnectionRequest,
      ]
    | UserRolesConnectionRequest
  userRole?: [{ where?: UserRoleFilterQueryInput | null; sort?: String | null }, UserRoleRequest] | UserRoleRequest
  userRoleById?: [{ id?: String | null }, UserRoleRequest] | UserRoleRequest
  analyticsVisitsCount?: [{ params: AnalyticsVisitsCountPayloadQueryInput }, AnalyticsVisitsCountResponseRequest]
  analyticsVisitsDuration?: [{ params: AnalyticsVisitsDurationPayloadQueryInput }, AnalyticsVisitsDurationResponseRequest]
  analyticsVisitsWaitingTime?: [
    { params: AnalyticsVisitsWaitingTimePayloadQueryInput },
    AnalyticsVisitsWaitingTimeResponseRequest,
  ]
  agents?:
    | [
        { where?: AgentFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        AgentsConnectionRequest,
      ]
    | AgentsConnectionRequest
  agent?: [{ where?: AgentFilterQueryInput | null; sort?: String | null }, AgentRequest] | AgentRequest
  agentById?: [{ id?: String | null }, AgentRequest] | AgentRequest
  printers?:
    | [
        { where?: PrinterFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        PrintersConnectionRequest,
      ]
    | PrintersConnectionRequest
  printer?: [{ where?: PrinterFilterQueryInput | null; sort?: String | null }, PrinterRequest] | PrinterRequest
  printerById?: [{ id?: String | null }, PrinterRequest] | PrinterRequest
  scans?:
    | [
        { where?: ScanFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        ScansConnectionRequest,
      ]
    | ScansConnectionRequest
  scan?: [{ where?: ScanFilterQueryInput | null; sort?: String | null }, ScanRequest] | ScanRequest
  scanById?: [{ id?: String | null }, ScanRequest] | ScanRequest
  notifications?:
    | [
        { where?: NotificationFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        NotificationsConnectionRequest,
      ]
    | NotificationsConnectionRequest
  ablyAuthenticate?: AblyAuthorizeResponseRequest
  ablyEnabled?: AblyEnabledResponseRequest
  triggers?:
    | [
        { where?: TriggerFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        TriggersConnectionRequest,
      ]
    | TriggersConnectionRequest
  trigger?: [{ where?: TriggerFilterQueryInput | null; sort?: String | null }, TriggerRequest] | TriggerRequest
  triggerById?: [{ id?: String | null }, TriggerRequest] | TriggerRequest
  templates?:
    | [
        { where?: TemplateFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        TemplatesConnectionRequest,
      ]
    | TemplatesConnectionRequest
  template?: [{ where?: TemplateFilterQueryInput | null; sort?: String | null }, TemplateRequest] | TemplateRequest
  templateById?: [{ id?: String | null }, TemplateRequest] | TemplateRequest
  getPreference?: [{ args: GetPreferenceArgsQueryInput }, PreferenceRequest]
  deviceLogs?:
    | [
        { where?: DeviceLogFilterQueryInput | null; limit?: Float | null; skip?: Float | null; sort?: String | null },
        DeviceLogsConnectionRequest,
      ]
    | DeviceLogsConnectionRequest
  deviceLog?: [{ where?: DeviceLogFilterQueryInput | null; sort?: String | null }, DeviceLogRequest] | DeviceLogRequest
  deviceLogById?: [{ id?: String | null }, DeviceLogRequest] | DeviceLogRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationTypeFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  appearance?: AppearanceFilterQueryInput | null
  AND?: (LocationTypeFilterQueryInput | null)[] | null
  OR?: (LocationTypeFilterQueryInput | null)[] | null
  NOR?: (LocationTypeFilterQueryInput | null)[] | null
}

export interface IdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: IdFilterQueryInput | null
}

export interface _idFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: _idFilterQueryInput | null
}

export interface OrganizationIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OrganizationIdFilterQueryInput | null
}

export interface CreatedAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CreatedAtFilterQueryInput | null
}

export interface UpdatedAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UpdatedAtFilterQueryInput | null
}

export interface NameFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NameFilterQueryInput | null
}

export interface AppearanceFilterQueryInput {
  location?: LocationFilterQueryInput | null
  visitor?: VisitorFilterQueryInput | null
  AND?: (AppearanceFilterQueryInput | null)[] | null
  OR?: (AppearanceFilterQueryInput | null)[] | null
  NOR?: (AppearanceFilterQueryInput | null)[] | null
}

export interface LocationFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  shortName?: ShortNameFilterQueryInput | null
  color?: ColorFilterQueryInput | null
  locationTypeId?: LocationTypeIdFilterQueryInput | null
  parentLocationId?: ParentLocationIdFilterQueryInput | null
  queue?: QueueFilterQueryInput | null
  extraData?: ExtraDataFilterQueryInput | null
  AND?: (LocationFilterQueryInput | null)[] | null
  OR?: (LocationFilterQueryInput | null)[] | null
  NOR?: (LocationFilterQueryInput | null)[] | null
}

export interface ShortNameFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ShortNameFilterQueryInput | null
}

export interface ColorFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ColorFilterQueryInput | null
}

export interface LocationTypeIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationTypeIdFilterQueryInput | null
}

export interface ParentLocationIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ParentLocationIdFilterQueryInput | null
}

export interface QueueFilterQueryInput {
  hasQueue?: HasQueueFilterQueryInput | null
  visiting?: VisitingFilterQueryInput | null
  waiting?: WaitingFilterQueryInput | null
  onCallMarkPreviousVisitAsCompleted?: OnCallMarkPreviousVisitAsCompletedFilterQueryInput | null
  AND?: (QueueFilterQueryInput | null)[] | null
  OR?: (QueueFilterQueryInput | null)[] | null
  NOR?: (QueueFilterQueryInput | null)[] | null
}

export interface HasQueueFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: HasQueueFilterQueryInput | null
}

export interface VisitingFilterQueryInput {
  maxDuration?: MaxDurationFilterQueryInput | null
  AND?: (VisitingFilterQueryInput | null)[] | null
  OR?: (VisitingFilterQueryInput | null)[] | null
  NOR?: (VisitingFilterQueryInput | null)[] | null
}

export interface MaxDurationFilterQueryInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MaxDurationFilterQueryInput | null
}

export interface WaitingFilterQueryInput {
  maxDuration?: MaxDurationFilterQueryInput | null
  AND?: (WaitingFilterQueryInput | null)[] | null
  OR?: (WaitingFilterQueryInput | null)[] | null
  NOR?: (WaitingFilterQueryInput | null)[] | null
}

export interface OnCallMarkPreviousVisitAsCompletedFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OnCallMarkPreviousVisitAsCompletedFilterQueryInput | null
}

export interface ExtraDataFilterQueryInput {
  AND?: (ExtraDataFilterQueryInput | null)[] | null
  OR?: (ExtraDataFilterQueryInput | null)[] | null
  NOR?: (ExtraDataFilterQueryInput | null)[] | null
}

export interface VisitorFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  firstname?: FirstnameFilterQueryInput | null
  lastname?: LastnameFilterQueryInput | null
  fiscalCode?: FiscalCodeFilterQueryInput | null
  sex?: SexFilterQueryInput | null
  healthCardNumber?: HealthCardNumberFilterQueryInput | null
  birth?: BirthFilterQueryInput | null
  address?: AddressFilterQueryInput | null
  contacts?: ContactsFilterQueryInput | null
  attachmentIds?: AttachmentIdsFilterQueryInput | null
  extraData?: ExtraDataFilterQueryInput | null
  AND?: (VisitorFilterQueryInput | null)[] | null
  OR?: (VisitorFilterQueryInput | null)[] | null
  NOR?: (VisitorFilterQueryInput | null)[] | null
}

export interface FirstnameFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FirstnameFilterQueryInput | null
}

export interface LastnameFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LastnameFilterQueryInput | null
}

export interface FiscalCodeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FiscalCodeFilterQueryInput | null
}

export interface SexFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SexFilterQueryInput | null
}

export interface HealthCardNumberFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: HealthCardNumberFilterQueryInput | null
}

export interface BirthFilterQueryInput {
  city?: CityFilterQueryInput | null
  province?: ProvinceFilterQueryInput | null
  date?: DateFilterQueryInput | null
  AND?: (BirthFilterQueryInput | null)[] | null
  OR?: (BirthFilterQueryInput | null)[] | null
  NOR?: (BirthFilterQueryInput | null)[] | null
}

export interface CityFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CityFilterQueryInput | null
}

export interface ProvinceFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ProvinceFilterQueryInput | null
}

export interface DateFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DateFilterQueryInput | null
}

export interface AddressFilterQueryInput {
  line1?: Line1FilterQueryInput | null
  streetNumber?: StreetNumberFilterQueryInput | null
  city?: CityFilterQueryInput | null
  province?: ProvinceFilterQueryInput | null
  AND?: (AddressFilterQueryInput | null)[] | null
  OR?: (AddressFilterQueryInput | null)[] | null
  NOR?: (AddressFilterQueryInput | null)[] | null
}

export interface Line1FilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: Line1FilterQueryInput | null
}

export interface StreetNumberFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StreetNumberFilterQueryInput | null
}

export interface ContactsFilterQueryInput {
  number?: NumberFilterQueryInput | null
  AND?: (ContactsFilterQueryInput | null)[] | null
  OR?: (ContactsFilterQueryInput | null)[] | null
  NOR?: (ContactsFilterQueryInput | null)[] | null
}

export interface NumberFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NumberFilterQueryInput | null
}

export interface AttachmentIdsFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AttachmentIdsFilterQueryInput | null
}

export interface LocationTypesConnectionRequest {
  items?: LocationTypeRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationTypeRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  appearance?: LocationTypeAppearanceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationTypeAppearanceRequest {
  location?: NameAndPluralNameRequest
  visitor?: NameAndPluralNameRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NameAndPluralNameRequest {
  name?: boolean | number
  pluralName?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationsConnectionRequest {
  items?: LocationRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  shortName?: boolean | number
  color?: boolean | number
  locationTypeId?: boolean | number
  parentLocationId?: boolean | number
  queue?: QueueSettingsRequest
  extraData?: boolean | number
  parentLocation?: LocationRequest
  locationType?: LocationTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface QueueSettingsRequest {
  hasQueue?: boolean | number
  visiting?: VisitSettingsRequest
  waiting?: VisitSettingsRequest
  onCallMarkPreviousVisitAsCompleted?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitSettingsRequest {
  maxDuration?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationWithChildrenRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  shortName?: boolean | number
  color?: boolean | number
  locationTypeId?: boolean | number
  parentLocationId?: boolean | number
  queue?: QueueSettingsRequest
  extraData?: boolean | number
  children?: LocationWithChildrenRequest
  parentLocation?: LocationRequest
  locationType?: LocationTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BookingsQueryQueryInput {
  visitor?: BookingsQueryVisitorQueryInput | null
  booking?: BookingsQueryVisitQueryInput | null
}

export interface BookingsQueryVisitorQueryInput {
  fiscalCode?: String | null
  healthCardNumber?: String | null
}

export interface BookingsQueryVisitQueryInput {
  prescriptionCode?: String | null
  date?: DateTime | null
}

export interface BookingRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  visitorId?: boolean | number
  date?: boolean | number
  time?: boolean | number
  notes?: boolean | number
  prescriptionCode?: boolean | number
  locationId?: boolean | number
  aspVisitId?: boolean | number
  externalId?: boolean | number
  attachmentIds?: boolean | number
  extraData?: boolean | number
  visitor?: VisitorRequest
  location?: LocationRequest
  visit?: VisitRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitorRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  firstname?: boolean | number
  lastname?: boolean | number
  fiscalCode?: boolean | number
  sex?: boolean | number
  healthCardNumber?: boolean | number
  birth?: BirthTypeRequest
  address?: AddressRequest
  contacts?: VisitorContactsRequest
  attachmentIds?: boolean | number
  extraData?: boolean | number
  fullName?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BirthTypeRequest {
  city?: boolean | number
  province?: boolean | number
  date?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddressRequest {
  line1?: boolean | number
  streetNumber?: boolean | number
  city?: boolean | number
  province?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitorContactsRequest {
  number?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  ticketCode?: boolean | number
  order?: boolean | number
  visitorId?: boolean | number
  locationId?: boolean | number
  checkinKioskId?: boolean | number
  checkinVirtualKioskId?: boolean | number
  startedAt?: boolean | number
  endedAt?: boolean | number
  endedReason?: boolean | number
  endedNote?: boolean | number
  duration?: boolean | number
  startedById?: boolean | number
  bookingId?: boolean | number
  endedById?: boolean | number
  ticketPrinted?: boolean | number
  selfCheckin?: boolean | number
  canVisit?: boolean | number
  scanIds?: boolean | number
  attachmentIds?: boolean | number
  status?: boolean | number
  tags?: TagRequest
  startedBy?: UserRequest
  visitor?: VisitorRequest
  location?: LocationRequest
  scan?: ScanRequest
  scans?: ScanRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TagRequest {
  text?: boolean | number
  color?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  email?: boolean | number
  firstname?: boolean | number
  lastname?: boolean | number
  contacts?: UserContactsRequest
  shortName?: boolean | number
  roles?: RoleRequest
  memberships?: MembershipRequest
  fullName?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserContactsRequest {
  number?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RoleRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  policies?: PolicyRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PolicyRequest {
  effect?: boolean | number
  actions?: boolean | number
  resources?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MembershipRequest {
  id?: boolean | number
  _id?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  organizationId?: boolean | number
  userId?: boolean | number
  organization?: OrganizationRequest
  user?: UserRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface OrganizationRequest {
  id?: boolean | number
  _id?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  timezone?: boolean | number
  scheduledResetTime?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ScanRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  date?: boolean | number
  deviceId?: boolean | number
  temperature?: boolean | number
  wearMask?: boolean | number
  checkinKioskId?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BookingFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  visitorId?: VisitorIdFilterQueryInput | null
  date?: DateFilterQueryInput | null
  time?: TimeFilterQueryInput | null
  notes?: NotesFilterQueryInput | null
  prescriptionCode?: PrescriptionCodeFilterQueryInput | null
  locationId?: LocationIdFilterQueryInput | null
  aspVisitId?: AspVisitIdFilterQueryInput | null
  externalId?: ExternalIdFilterQueryInput | null
  attachmentIds?: AttachmentIdsFilterQueryInput | null
  extraData?: ExtraDataFilterQueryInput | null
  AND?: (BookingFilterQueryInput | null)[] | null
  OR?: (BookingFilterQueryInput | null)[] | null
  NOR?: (BookingFilterQueryInput | null)[] | null
}

export interface VisitorIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VisitorIdFilterQueryInput | null
}

export interface TimeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimeFilterQueryInput | null
}

export interface NotesFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NotesFilterQueryInput | null
}

export interface PrescriptionCodeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: PrescriptionCodeFilterQueryInput | null
}

export interface LocationIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationIdFilterQueryInput | null
}

export interface AspVisitIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AspVisitIdFilterQueryInput | null
}

export interface ExternalIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ExternalIdFilterQueryInput | null
}

export interface BookingsConnectionRequest {
  items?: BookingRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  ticketCode?: TicketCodeFilterQueryInput | null
  order?: OrderFilterQueryInput | null
  visitorId?: VisitorIdFilterQueryInput | null
  locationId?: LocationIdFilterQueryInput | null
  checkinKioskId?: CheckinKioskIdFilterQueryInput | null
  checkinVirtualKioskId?: CheckinVirtualKioskIdFilterQueryInput | null
  startedAt?: StartedAtFilterQueryInput | null
  endedAt?: EndedAtFilterQueryInput | null
  endedReason?: EndedReasonFilterQueryInput | null
  endedNote?: EndedNoteFilterQueryInput | null
  duration?: DurationFilterQueryInput | null
  startedById?: StartedByIdFilterQueryInput | null
  bookingId?: BookingIdFilterQueryInput | null
  endedById?: EndedByIdFilterQueryInput | null
  ticketPrinted?: TicketPrintedFilterQueryInput | null
  selfCheckin?: SelfCheckinFilterQueryInput | null
  canVisit?: CanVisitFilterQueryInput | null
  scanIds?: ScanIdsFilterQueryInput | null
  attachmentIds?: AttachmentIdsFilterQueryInput | null
  status?: StatusFilterQueryInput | null
  tags?: TagsFilterQueryInput | null
  AND?: (VisitFilterQueryInput | null)[] | null
  OR?: (VisitFilterQueryInput | null)[] | null
  NOR?: (VisitFilterQueryInput | null)[] | null
}

export interface TicketCodeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketCodeFilterQueryInput | null
}

export interface OrderFilterQueryInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OrderFilterQueryInput | null
}

export interface CheckinKioskIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CheckinKioskIdFilterQueryInput | null
}

export interface CheckinVirtualKioskIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CheckinVirtualKioskIdFilterQueryInput | null
}

export interface StartedAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StartedAtFilterQueryInput | null
}

export interface EndedAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedAtFilterQueryInput | null
}

export interface EndedReasonFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedReasonFilterQueryInput | null
}

export interface EndedNoteFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedNoteFilterQueryInput | null
}

export interface DurationFilterQueryInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DurationFilterQueryInput | null
}

export interface StartedByIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StartedByIdFilterQueryInput | null
}

export interface BookingIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: BookingIdFilterQueryInput | null
}

export interface EndedByIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedByIdFilterQueryInput | null
}

export interface TicketPrintedFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketPrintedFilterQueryInput | null
}

export interface SelfCheckinFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SelfCheckinFilterQueryInput | null
}

export interface CanVisitFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CanVisitFilterQueryInput | null
}

export interface ScanIdsFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ScanIdsFilterQueryInput | null
}

export interface StatusFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StatusFilterQueryInput | null
}

export interface TagsFilterQueryInput {
  text?: TextFilterQueryInput | null
  color?: ColorFilterQueryInput | null
  AND?: (TagsFilterQueryInput | null)[] | null
  OR?: (TagsFilterQueryInput | null)[] | null
  NOR?: (TagsFilterQueryInput | null)[] | null
}

export interface TextFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TextFilterQueryInput | null
}

export interface VisitsConnectionRequest {
  items?: VisitRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StartedVisitsByStartedByIdResultRequest {
  startedById?: boolean | number
  visitId?: boolean | number
  visit?: VisitRequest
  startedBy?: UserRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SpeakResultRequest {
  url?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FetchQueueCountsCriteriaQueryInput {
  locationId?: String | null
  startDate?: DateTime | null
  endDate?: DateTime | null
}

export interface QueueCountsRequest {
  counts?: CountsRequest
  location?: LocationRequest
  locationId?: boolean | number
  parentLocation?: LocationRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CountsRequest {
  waiting?: boolean | number
  started?: boolean | number
  ended?: boolean | number
  expected?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitorsConnectionRequest {
  items?: VisitorRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface JourneyForVisitorResponseRequest {
  id?: boolean | number
  locationShortName?: boolean | number
  ticketCode?: boolean | number
  currentTicketCode?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  email?: EmailFilterQueryInput | null
  firstname?: FirstnameFilterQueryInput | null
  lastname?: LastnameFilterQueryInput | null
  contacts?: ContactsFilterQueryInput | null
  shortName?: ShortNameFilterQueryInput | null
  AND?: (UserFilterQueryInput | null)[] | null
  OR?: (UserFilterQueryInput | null)[] | null
  NOR?: (UserFilterQueryInput | null)[] | null
}

export interface EmailFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EmailFilterQueryInput | null
}

export interface UsersConnectionRequest {
  items?: UserRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface IdentityRequest {
  on_User?: UserRequest
  on_Monitor?: MonitorRequest
  on_CheckinKiosk?: CheckinKioskRequest
  on_Agent?: AgentRequest
  on_Visitor?: VisitorRequest
  __typename?: boolean | number
}

export interface MonitorRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  locationIds?: boolean | number
  notification?: MonitorNotificationRequest
  enableDebugMode?: boolean | number
  fontSizeMultiplier?: boolean | number
  interface?: boolean | number
  locations?: LocationRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MonitorNotificationRequest {
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CheckinKioskRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  locationConfigs?: LocationConfigRequest
  addUnknownVisitorsToLocationId?: boolean | number
  uiTemplateId?: boolean | number
  interface?: CheckinKioskUIRequest
  keyboard?: KeyboardRequest
  locations?: LocationRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationConfigRequest {
  locationId?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CheckinKioskUIRequest {
  steps?: StepRequest
  macros?: boolean | number
  theme?: boolean | number
  debugMode?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StepRequest {
  name?: boolean | number
  onMount?: boolean | number
  state?: boolean | number
  content?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface KeyboardRequest {
  active?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  queue?: AgentQueueRequest
  checkinKioskId?: boolean | number
  ticketPrintTemplateId?: boolean | number
  activationToken?: AgentTokenRequest
  status?: boolean | number
  printers?: PrinterRequest
  healthChecks?:
    | [{ limit?: Float | null; skip?: Float | null; sort?: String | null }, AgentHealthChecksConnectionRequest]
    | AgentHealthChecksConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentQueueRequest {
  url?: boolean | number
  accessKey?: boolean | number
  secretKey?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentTokenRequest {
  id?: boolean | number
  consumerId?: boolean | number
  type?: boolean | number
  extra?: ExtraRequest
  expireAt?: boolean | number
  lastUsed?: boolean | number
  createdAt?: boolean | number
  token?: boolean | number
  consumer?: TokenConsumerRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ExtraRequest {
  visitId?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TokenConsumerRequest {
  on_User?: UserRequest
  on_Monitor?: MonitorRequest
  on_CheckinKiosk?: CheckinKioskRequest
  on_CheckinVirtualKiosk?: CheckinVirtualKioskRequest
  on_Agent?: AgentRequest
  on_Visitor?: VisitorRequest
  __typename?: boolean | number
}

export interface CheckinVirtualKioskRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  locationConfigs?: LocationConfigRequest
  locations?: LocationRequest
  checkinURLs?: VirtualCheckinURLRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VirtualCheckinURLRequest {
  token?: TokenRequest
  url?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TokenRequest {
  id?: boolean | number
  consumerId?: boolean | number
  type?: boolean | number
  extra?: ExtraRequest
  expireAt?: boolean | number
  lastUsed?: boolean | number
  createdAt?: boolean | number
  consumer?: TokenConsumerRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PrinterRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  deviceId?: boolean | number
  type?: boolean | number
  enabled?: boolean | number
  agentId?: boolean | number
  options?: PrintingOptionsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PrintingOptionsRequest {
  media?: boolean | number
  sides?: boolean | number
  fitToPage?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentHealthChecksConnectionRequest {
  items?: AgentHealthCheckRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentHealthCheckRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  agentId?: boolean | number
  date?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface OrganizationFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  timezone?: TimezoneFilterQueryInput | null
  scheduledResetTime?: ScheduledResetTimeFilterQueryInput | null
  AND?: (OrganizationFilterQueryInput | null)[] | null
  OR?: (OrganizationFilterQueryInput | null)[] | null
  NOR?: (OrganizationFilterQueryInput | null)[] | null
}

export interface TimezoneFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimezoneFilterQueryInput | null
}

export interface ScheduledResetTimeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ScheduledResetTimeFilterQueryInput | null
}

export interface OrganizationsConnectionRequest {
  items?: OrganizationRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MonitorFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  locationIds?: LocationIdsFilterQueryInput | null
  notification?: NotificationFilterQueryInput | null
  enableDebugMode?: EnableDebugModeFilterQueryInput | null
  fontSizeMultiplier?: FontSizeMultiplierFilterQueryInput | null
  interface?: InterfaceFilterQueryInput | null
  AND?: (MonitorFilterQueryInput | null)[] | null
  OR?: (MonitorFilterQueryInput | null)[] | null
  NOR?: (MonitorFilterQueryInput | null)[] | null
}

export interface LocationIdsFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationIdsFilterQueryInput | null
}

export interface NotificationFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  severity?: SeverityFilterQueryInput | null
  message?: MessageFilterQueryInput | null
  senderId?: SenderIdFilterQueryInput | null
  senderType?: SenderTypeFilterQueryInput | null
  extra?: ExtraFilterQueryInput | null
  receivers?: ReceiversFilterQueryInput | null
  AND?: (NotificationFilterQueryInput | null)[] | null
  OR?: (NotificationFilterQueryInput | null)[] | null
  NOR?: (NotificationFilterQueryInput | null)[] | null
}

export interface SeverityFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SeverityFilterQueryInput | null
}

export interface MessageFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MessageFilterQueryInput | null
}

export interface SenderIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SenderIdFilterQueryInput | null
}

export interface SenderTypeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SenderTypeFilterQueryInput | null
}

export interface ExtraFilterQueryInput {
  visitId?: VisitIdFilterQueryInput | null
  visitorId?: VisitorIdFilterQueryInput | null
  scanId?: ScanIdFilterQueryInput | null
  checkinKioskId?: CheckinKioskIdFilterQueryInput | null
  AND?: (ExtraFilterQueryInput | null)[] | null
  OR?: (ExtraFilterQueryInput | null)[] | null
  NOR?: (ExtraFilterQueryInput | null)[] | null
}

export interface VisitIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VisitIdFilterQueryInput | null
}

export interface ScanIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ScanIdFilterQueryInput | null
}

export interface ReceiversFilterQueryInput {
  receiverType?: ReceiverTypeFilterQueryInput | null
  receiverId?: ReceiverIdFilterQueryInput | null
  readAt?: ReadAtFilterQueryInput | null
  dismissedAt?: DismissedAtFilterQueryInput | null
  AND?: (ReceiversFilterQueryInput | null)[] | null
  OR?: (ReceiversFilterQueryInput | null)[] | null
  NOR?: (ReceiversFilterQueryInput | null)[] | null
}

export interface ReceiverTypeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ReceiverTypeFilterQueryInput | null
}

export interface ReceiverIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ReceiverIdFilterQueryInput | null
}

export interface ReadAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ReadAtFilterQueryInput | null
}

export interface DismissedAtFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DismissedAtFilterQueryInput | null
}

export interface EnableDebugModeFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EnableDebugModeFilterQueryInput | null
}

export interface FontSizeMultiplierFilterQueryInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FontSizeMultiplierFilterQueryInput | null
}

export interface InterfaceFilterQueryInput {
  AND?: (InterfaceFilterQueryInput | null)[] | null
  OR?: (InterfaceFilterQueryInput | null)[] | null
  NOR?: (InterfaceFilterQueryInput | null)[] | null
}

export interface MonitorsConnectionRequest {
  items?: MonitorRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MonitorVisitsByLocationIdResultRequest {
  locationId?: boolean | number
  visitId?: boolean | number
  visit?: VisitRequest
  location?: LocationRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CheckinKioskFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  locationConfigs?: LocationConfigsFilterQueryInput | null
  addUnknownVisitorsToLocationId?: AddUnknownVisitorsToLocationIdFilterQueryInput | null
  uiTemplateId?: UiTemplateIdFilterQueryInput | null
  interface?: InterfaceFilterQueryInput | null
  keyboard?: KeyboardFilterQueryInput | null
  AND?: (CheckinKioskFilterQueryInput | null)[] | null
  OR?: (CheckinKioskFilterQueryInput | null)[] | null
  NOR?: (CheckinKioskFilterQueryInput | null)[] | null
}

export interface LocationConfigsFilterQueryInput {
  locationId?: LocationIdFilterQueryInput | null
  AND?: (LocationConfigsFilterQueryInput | null)[] | null
  OR?: (LocationConfigsFilterQueryInput | null)[] | null
  NOR?: (LocationConfigsFilterQueryInput | null)[] | null
}

export interface AddUnknownVisitorsToLocationIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AddUnknownVisitorsToLocationIdFilterQueryInput | null
}

export interface UiTemplateIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UiTemplateIdFilterQueryInput | null
}

export interface KeyboardFilterQueryInput {
  active?: ActiveFilterQueryInput | null
  AND?: (KeyboardFilterQueryInput | null)[] | null
  OR?: (KeyboardFilterQueryInput | null)[] | null
  NOR?: (KeyboardFilterQueryInput | null)[] | null
}

export interface ActiveFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActiveFilterQueryInput | null
}

export interface CheckinKiosksConnectionRequest {
  items?: CheckinKioskRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CheckinVirtualKioskFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  locationConfigs?: LocationConfigsFilterQueryInput | null
  AND?: (CheckinVirtualKioskFilterQueryInput | null)[] | null
  OR?: (CheckinVirtualKioskFilterQueryInput | null)[] | null
  NOR?: (CheckinVirtualKioskFilterQueryInput | null)[] | null
}

export interface CheckinVirtualKiosksConnectionRequest {
  items?: CheckinVirtualKioskRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VirtualCheckinSessionFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  identifier?: IdentifierFilterQueryInput | null
  checkinVirtualKioskId?: CheckinVirtualKioskIdFilterQueryInput | null
  token?: TokenFilterQueryInput | null
  status?: StatusFilterQueryInput | null
  privacyAccepted?: PrivacyAcceptedFilterQueryInput | null
  availableLocations?: AvailableLocationsFilterQueryInput | null
  choosenLocationId?: ChoosenLocationIdFilterQueryInput | null
  visitId?: VisitIdFilterQueryInput | null
  dataLog?: DataLogFilterQueryInput | null
  AND?: (VirtualCheckinSessionFilterQueryInput | null)[] | null
  OR?: (VirtualCheckinSessionFilterQueryInput | null)[] | null
  NOR?: (VirtualCheckinSessionFilterQueryInput | null)[] | null
}

export interface IdentifierFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: IdentifierFilterQueryInput | null
}

export interface TokenFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TokenFilterQueryInput | null
}

export interface PrivacyAcceptedFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: PrivacyAcceptedFilterQueryInput | null
}

export interface AvailableLocationsFilterQueryInput {
  AND?: (AvailableLocationsFilterQueryInput | null)[] | null
  OR?: (AvailableLocationsFilterQueryInput | null)[] | null
  NOR?: (AvailableLocationsFilterQueryInput | null)[] | null
}

export interface ChoosenLocationIdFilterQueryInput {
  AND?: (ChoosenLocationIdFilterQueryInput | null)[] | null
  OR?: (ChoosenLocationIdFilterQueryInput | null)[] | null
  NOR?: (ChoosenLocationIdFilterQueryInput | null)[] | null
}

export interface DataLogFilterQueryInput {
  message?: MessageFilterQueryInput | null
  date?: DateFilterQueryInput | null
  AND?: (DataLogFilterQueryInput | null)[] | null
  OR?: (DataLogFilterQueryInput | null)[] | null
  NOR?: (DataLogFilterQueryInput | null)[] | null
}

export interface VirtualCheckinSessionsConnectionRequest {
  items?: VirtualCheckinSessionRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VirtualCheckinSessionRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  identifier?: boolean | number
  checkinVirtualKioskId?: boolean | number
  token?: boolean | number
  status?: boolean | number
  privacyAccepted?: boolean | number
  availableLocations?: boolean | number
  choosenLocationId?: boolean | number
  visitId?: boolean | number
  dataLog?: LogRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LogRequest {
  message?: boolean | number
  date?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MembershipFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  userId?: UserIdFilterQueryInput | null
  AND?: (MembershipFilterQueryInput | null)[] | null
  OR?: (MembershipFilterQueryInput | null)[] | null
  NOR?: (MembershipFilterQueryInput | null)[] | null
}

export interface UserIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UserIdFilterQueryInput | null
}

export interface MembershipsConnectionRequest {
  items?: MembershipRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RoleFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  policies?: PoliciesFilterQueryInput | null
  AND?: (RoleFilterQueryInput | null)[] | null
  OR?: (RoleFilterQueryInput | null)[] | null
  NOR?: (RoleFilterQueryInput | null)[] | null
}

export interface PoliciesFilterQueryInput {
  effect?: EffectFilterQueryInput | null
  actions?: ActionsFilterQueryInput | null
  resources?: ResourcesFilterQueryInput | null
  AND?: (PoliciesFilterQueryInput | null)[] | null
  OR?: (PoliciesFilterQueryInput | null)[] | null
  NOR?: (PoliciesFilterQueryInput | null)[] | null
}

export interface EffectFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EffectFilterQueryInput | null
}

export interface ActionsFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActionsFilterQueryInput | null
}

export interface ResourcesFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ResourcesFilterQueryInput | null
}

export interface RolesConnectionRequest {
  items?: RoleRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserRoleFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  userId?: UserIdFilterQueryInput | null
  roleId?: RoleIdFilterQueryInput | null
  AND?: (UserRoleFilterQueryInput | null)[] | null
  OR?: (UserRoleFilterQueryInput | null)[] | null
  NOR?: (UserRoleFilterQueryInput | null)[] | null
}

export interface RoleIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: RoleIdFilterQueryInput | null
}

export interface UserRolesConnectionRequest {
  items?: UserRoleRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserRoleRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  userId?: boolean | number
  roleId?: boolean | number
  role?: RoleRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsCountPayloadQueryInput {
  timePeriod: String
  groupBy?: (String | null)[] | null
}

export interface AnalyticsVisitsCountResponseRequest {
  data?: AnalyticsVisitsCountResponseDataRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsCountResponseDataRequest {
  count?: boolean | number
  date?: boolean | number
  locationId?: boolean | number
  location?: LocationRequest
  startedById?: boolean | number
  startedBy?: UserRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsDurationPayloadQueryInput {
  timePeriod: String
  groupBy?: (String | null)[] | null
}

export interface AnalyticsVisitsDurationResponseRequest {
  data?: AnalyticsVisitsDurationResponseDataRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsDurationResponseDataRequest {
  durationAvgSecs?: boolean | number
  durationAvgMins?: boolean | number
  date?: boolean | number
  locationId?: boolean | number
  location?: LocationRequest
  startedById?: boolean | number
  startedBy?: UserRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsWaitingTimePayloadQueryInput {
  timePeriod: String
  groupBy?: (String | null)[] | null
}

export interface AnalyticsVisitsWaitingTimeResponseRequest {
  data?: AnalyticsVisitsWaitingTimeResponseDataRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnalyticsVisitsWaitingTimeResponseDataRequest {
  waitingTimeAvgSecs?: boolean | number
  waitingTimeAvgMins?: boolean | number
  date?: boolean | number
  locationId?: boolean | number
  location?: LocationRequest
  startedById?: boolean | number
  startedBy?: UserRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AgentFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  queue?: QueueFilterQueryInput | null
  checkinKioskId?: CheckinKioskIdFilterQueryInput | null
  ticketPrintTemplateId?: TicketPrintTemplateIdFilterQueryInput | null
  activationToken?: ActivationTokenFilterQueryInput | null
  status?: StatusFilterQueryInput | null
  AND?: (AgentFilterQueryInput | null)[] | null
  OR?: (AgentFilterQueryInput | null)[] | null
  NOR?: (AgentFilterQueryInput | null)[] | null
}

export interface TicketPrintTemplateIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketPrintTemplateIdFilterQueryInput | null
}

export interface ActivationTokenFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActivationTokenFilterQueryInput | null
}

export interface AgentsConnectionRequest {
  items?: AgentRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PrinterFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  deviceId?: DeviceIdFilterQueryInput | null
  type?: TypeFilterQueryInput | null
  enabled?: EnabledFilterQueryInput | null
  agentId?: AgentIdFilterQueryInput | null
  options?: OptionsFilterQueryInput | null
  AND?: (PrinterFilterQueryInput | null)[] | null
  OR?: (PrinterFilterQueryInput | null)[] | null
  NOR?: (PrinterFilterQueryInput | null)[] | null
}

export interface DeviceIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DeviceIdFilterQueryInput | null
}

export interface TypeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TypeFilterQueryInput | null
}

export interface EnabledFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EnabledFilterQueryInput | null
}

export interface AgentIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AgentIdFilterQueryInput | null
}

export interface OptionsFilterQueryInput {
  media?: MediaFilterQueryInput | null
  sides?: SidesFilterQueryInput | null
  fitToPage?: FitToPageFilterQueryInput | null
  AND?: (OptionsFilterQueryInput | null)[] | null
  OR?: (OptionsFilterQueryInput | null)[] | null
  NOR?: (OptionsFilterQueryInput | null)[] | null
}

export interface MediaFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MediaFilterQueryInput | null
}

export interface SidesFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SidesFilterQueryInput | null
}

export interface FitToPageFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FitToPageFilterQueryInput | null
}

export interface PrintersConnectionRequest {
  items?: PrinterRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ScanFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  date?: DateFilterQueryInput | null
  deviceId?: DeviceIdFilterQueryInput | null
  temperature?: TemperatureFilterQueryInput | null
  wearMask?: WearMaskFilterQueryInput | null
  checkinKioskId?: CheckinKioskIdFilterQueryInput | null
  AND?: (ScanFilterQueryInput | null)[] | null
  OR?: (ScanFilterQueryInput | null)[] | null
  NOR?: (ScanFilterQueryInput | null)[] | null
}

export interface TemperatureFilterQueryInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TemperatureFilterQueryInput | null
}

export interface WearMaskFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: WearMaskFilterQueryInput | null
}

export interface ScansConnectionRequest {
  items?: ScanRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NotificationsConnectionRequest {
  items?: NotificationRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NotificationRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  severity?: boolean | number
  message?: boolean | number
  senderId?: boolean | number
  senderType?: boolean | number
  extra?: ExtraRequest
  receivers?: ReceiverRequest
  checkinKiosk?: CheckinKioskRequest
  scan?: ScanRequest
  visitor?: VisitorRequest
  sender?: SenderRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ReceiverRequest {
  receiverType?: boolean | number
  receiverId?: boolean | number
  readAt?: boolean | number
  dismissedAt?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SenderRequest {
  on_CheckinKiosk?: CheckinKioskRequest
  __typename?: boolean | number
}

export interface AblyAuthorizeResponseRequest {
  capability?: boolean | number
  clientId?: boolean | number
  expires?: boolean | number
  issued?: boolean | number
  token?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AblyEnabledResponseRequest {
  uiEnabled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TriggerFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  event?: EventFilterQueryInput | null
  sync?: SyncFilterQueryInput | null
  continueOnError?: ContinueOnErrorFilterQueryInput | null
  actions?: ActionsFilterQueryInput | null
  AND?: (TriggerFilterQueryInput | null)[] | null
  OR?: (TriggerFilterQueryInput | null)[] | null
  NOR?: (TriggerFilterQueryInput | null)[] | null
}

export interface EventFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EventFilterQueryInput | null
}

export interface SyncFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SyncFilterQueryInput | null
}

export interface ContinueOnErrorFilterQueryInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ContinueOnErrorFilterQueryInput | null
}

export interface TriggersConnectionRequest {
  items?: TriggerRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TriggerRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  event?: boolean | number
  sync?: boolean | number
  continueOnError?: boolean | number
  actions?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TemplateFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  name?: NameFilterQueryInput | null
  content?: ContentFilterQueryInput | null
  AND?: (TemplateFilterQueryInput | null)[] | null
  OR?: (TemplateFilterQueryInput | null)[] | null
  NOR?: (TemplateFilterQueryInput | null)[] | null
}

export interface ContentFilterQueryInput {
  AND?: (ContentFilterQueryInput | null)[] | null
  OR?: (ContentFilterQueryInput | null)[] | null
  NOR?: (ContentFilterQueryInput | null)[] | null
}

export interface TemplatesConnectionRequest {
  items?: TemplateRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TemplateRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  name?: boolean | number
  content?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GetPreferenceArgsQueryInput {
  name: String
  entityIds?: (String | null)[] | null
}

export interface PreferenceRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  userId?: boolean | number
  name?: boolean | number
  entityIds?: boolean | number
  fields?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeviceLogFilterQueryInput {
  id?: IdFilterQueryInput | null
  _id?: _idFilterQueryInput | null
  organizationId?: OrganizationIdFilterQueryInput | null
  createdAt?: CreatedAtFilterQueryInput | null
  updatedAt?: UpdatedAtFilterQueryInput | null
  consumerId?: ConsumerIdFilterQueryInput | null
  consumerType?: ConsumerTypeFilterQueryInput | null
  tokenId?: TokenIdFilterQueryInput | null
  eventName?: EventNameFilterQueryInput | null
  message?: MessageFilterQueryInput | null
  userAgent?: UserAgentFilterQueryInput | null
  timestamp?: TimestampFilterQueryInput | null
  AND?: (DeviceLogFilterQueryInput | null)[] | null
  OR?: (DeviceLogFilterQueryInput | null)[] | null
  NOR?: (DeviceLogFilterQueryInput | null)[] | null
}

export interface ConsumerIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ConsumerIdFilterQueryInput | null
}

export interface ConsumerTypeFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ConsumerTypeFilterQueryInput | null
}

export interface TokenIdFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TokenIdFilterQueryInput | null
}

export interface EventNameFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EventNameFilterQueryInput | null
}

export interface UserAgentFilterQueryInput {
  ua?: UaFilterQueryInput | null
  browser?: BrowserFilterQueryInput | null
  engine?: EngineFilterQueryInput | null
  os?: OsFilterQueryInput | null
  device?: DeviceFilterQueryInput | null
  cpu?: CpuFilterQueryInput | null
  AND?: (UserAgentFilterQueryInput | null)[] | null
  OR?: (UserAgentFilterQueryInput | null)[] | null
  NOR?: (UserAgentFilterQueryInput | null)[] | null
}

export interface UaFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UaFilterQueryInput | null
}

export interface BrowserFilterQueryInput {
  name?: NameFilterQueryInput | null
  version?: VersionFilterQueryInput | null
  major?: MajorFilterQueryInput | null
  AND?: (BrowserFilterQueryInput | null)[] | null
  OR?: (BrowserFilterQueryInput | null)[] | null
  NOR?: (BrowserFilterQueryInput | null)[] | null
}

export interface VersionFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VersionFilterQueryInput | null
}

export interface MajorFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MajorFilterQueryInput | null
}

export interface EngineFilterQueryInput {
  name?: NameFilterQueryInput | null
  version?: VersionFilterQueryInput | null
  AND?: (EngineFilterQueryInput | null)[] | null
  OR?: (EngineFilterQueryInput | null)[] | null
  NOR?: (EngineFilterQueryInput | null)[] | null
}

export interface OsFilterQueryInput {
  name?: NameFilterQueryInput | null
  version?: VersionFilterQueryInput | null
  AND?: (OsFilterQueryInput | null)[] | null
  OR?: (OsFilterQueryInput | null)[] | null
  NOR?: (OsFilterQueryInput | null)[] | null
}

export interface DeviceFilterQueryInput {
  vendor?: VendorFilterQueryInput | null
  model?: ModelFilterQueryInput | null
  AND?: (DeviceFilterQueryInput | null)[] | null
  OR?: (DeviceFilterQueryInput | null)[] | null
  NOR?: (DeviceFilterQueryInput | null)[] | null
}

export interface VendorFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VendorFilterQueryInput | null
}

export interface ModelFilterQueryInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ModelFilterQueryInput | null
}

export interface CpuFilterQueryInput {
  AND?: (CpuFilterQueryInput | null)[] | null
  OR?: (CpuFilterQueryInput | null)[] | null
  NOR?: (CpuFilterQueryInput | null)[] | null
}

export interface TimestampFilterQueryInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimestampFilterQueryInput | null
}

export interface DeviceLogsConnectionRequest {
  items?: DeviceLogRequest
  total?: boolean | number
  skip?: boolean | number
  limit?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeviceLogRequest {
  id?: boolean | number
  _id?: boolean | number
  organizationId?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  consumerId?: boolean | number
  consumerType?: boolean | number
  tokenId?: boolean | number
  eventName?: boolean | number
  message?: boolean | number
  userAgent?: UserAgentRequest
  timestamp?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserAgentRequest {
  ua?: boolean | number
  browser?: BrowserRequest
  engine?: EngineRequest
  os?: OsRequest
  device?: DeviceRequest
  cpu?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BrowserRequest {
  name?: boolean | number
  version?: boolean | number
  major?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EngineRequest {
  name?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface OsRequest {
  name?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeviceRequest {
  vendor?: boolean | number
  model?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MutationRequest {
  createLocationType?: [{ data?: LocationTypeMutationInput | null }, LocationTypeRequest] | LocationTypeRequest
  updateLocationTypeById?: [{ id: String; data: LocationTypePartialMutationInput }, LocationTypeRequest]
  updateLocationType?: [
    { where: LocationTypeFilterMutationInput; sort?: String | null; data: LocationTypePartialMutationInput },
    LocationTypeRequest,
  ]
  deleteLocationTypeById?: [{ id?: String | null }, LocationTypeRequest] | LocationTypeRequest
  createLocation?: [{ data?: LocationMutationInput | null }, LocationRequest] | LocationRequest
  updateLocationById?: [{ id: String; data: LocationPartialMutationInput }, LocationRequest]
  updateLocation?: [
    { where: LocationFilterMutationInput; sort?: String | null; data: LocationPartialMutationInput },
    LocationRequest,
  ]
  deleteLocationById?: [{ id?: String | null }, LocationRequest] | LocationRequest
  updateHierarchy?:
    | [{ data?: (LocationWithChildrenPartialMutationInput | null)[] | null }, UpdateHierarchyResultRequest]
    | UpdateHierarchyResultRequest
  addBooking?: [{ visitorData: VisitorMutationInput; bookingData: BookingMutationInput }, BookingRequest]
  createBooking?: [{ data?: BookingMutationInput | null }, BookingRequest] | BookingRequest
  updateBookingById?: [{ id: String; data: BookingPartialMutationInput }, BookingRequest]
  updateBooking?: [
    { where: BookingFilterMutationInput; sort?: String | null; data: BookingPartialMutationInput },
    BookingRequest,
  ]
  deleteBookingById?: [{ id?: String | null }, BookingRequest] | BookingRequest
  createVisit?: [{ data?: VisitMutationInput | null }, VisitRequest] | VisitRequest
  updateVisitById?: [{ id: String; data: VisitPartialMutationInput }, VisitRequest]
  updateVisit?: [{ where: VisitFilterMutationInput; sort?: String | null; data: VisitPartialMutationInput }, VisitRequest]
  deleteVisitById?: [{ id: String }, VisitRequest]
  enqueue?: [{ data: EnqueueBodyMutationInput }, VisitRequest]
  addScanToVisit?: [{ visitId: String; scanId: String; data?: VisitMutationInput | null }, VisitRequest]
  printAllVisitAttachments?: [{ visitId: String }, SuccessRequest]
  printAttachments?: [{ checkinKioskId: String; attachmentIds: (String | null)[] }, SuccessRequest]
  callVisit?: [{ visitId: String; isRecall?: Boolean | null }, VisitRequest]
  callNextVisit?: [{ locationId: String; where?: VisitFilterMutationInput | null }, VisitRequest]
  endVisit?: [{ data: EndVisitMutationInput }, VisitRequest]
  transferVisitor?: [{ data: TransferVisitorMutationInput }, VisitRequest]
  amendVisit?: [{ data: AmendVisitMutationInput }, VisitRequest]
  startVisit?: [{ data: StartVisitMutationInput }, VisitRequest]
  sendTicketToPrint?: [{ visitId: String }, SendToPrintResultRequest]
  sendToPrint?: [{ visitId: String }, SendToPrintResultRequest]
  createVisitor?: [{ data?: VisitorMutationInput | null }, VisitorRequest] | VisitorRequest
  updateVisitorById?: [{ id: String; data: VisitorPartialMutationInput }, VisitorRequest]
  updateVisitor?: [
    { where: VisitorFilterMutationInput; sort?: String | null; data: VisitorPartialMutationInput },
    VisitorRequest,
  ]
  deleteVisitorById?: [{ id?: String | null }, VisitorRequest] | VisitorRequest
  createUser?: [{ data?: UserMutationInput | null }, UserRequest] | UserRequest
  updateUserById?: [{ id: String; data: UserPartialMutationInput }, UserRequest]
  updateUser?: [{ where: UserFilterMutationInput; sort?: String | null; data: UserPartialMutationInput }, UserRequest]
  deleteUserById?: [{ id?: String | null }, UserRequest] | UserRequest
  login?: [{ credentials: CredentialsPayloadMutationInput }, SuccessRequest]
  logout?: SuccessRequest
  resetPassword?: [{ data: ResetPasswordPayloadMutationInput }, SuccessRequest]
  resetPasswordRequest?: [{ data: ResetPasswordRequestPayloadMutationInput }, SuccessRequest]
  createSignageSessionToken?: [{ consumerId: String; consumerType: String }, CreateSignageSessionTokenResultRequest]
  createOrganization?: [{ data?: OrganizationMutationInput | null }, OrganizationRequest] | OrganizationRequest
  updateOrganizationById?: [{ id: String; data: OrganizationPartialMutationInput }, OrganizationRequest]
  updateOrganization?: [
    { where: OrganizationFilterMutationInput; sort?: String | null; data: OrganizationPartialMutationInput },
    OrganizationRequest,
  ]
  deleteOrganizationById?: [{ id?: String | null }, OrganizationRequest] | OrganizationRequest
  createMonitor?: [{ data?: MonitorMutationInput | null }, MonitorRequest] | MonitorRequest
  updateMonitorById?: [{ id: String; data: MonitorPartialMutationInput }, MonitorRequest]
  updateMonitor?: [
    { where: MonitorFilterMutationInput; sort?: String | null; data: MonitorPartialMutationInput },
    MonitorRequest,
  ]
  deleteMonitorById?: [{ id?: String | null }, MonitorRequest] | MonitorRequest
  startMonitorSession?: [{ monitorId: String }, ResultRequest]
  refreshMonitor?: [{ monitorId: JSON }, MonitorRequest]
  createCheckinKiosk?: [{ data?: CheckinKioskMutationInput | null }, CheckinKioskRequest] | CheckinKioskRequest
  updateCheckinKioskById?: [{ id: String; data: CheckinKioskPartialMutationInput }, CheckinKioskRequest]
  updateCheckinKiosk?: [
    { where: CheckinKioskFilterMutationInput; sort?: String | null; data: CheckinKioskPartialMutationInput },
    CheckinKioskRequest,
  ]
  deleteCheckinKioskById?: [{ id?: String | null }, CheckinKioskRequest] | CheckinKioskRequest
  startCheckinKioskSession?: [{ checkinKioskId: String }, ResultRequest]
  generateCheckinSignageUrl?: [{ checkinKioskId: String }, GenerateCheckinSignageUrlResultRequest]
  refreshCheckinKiosk?: [{ checkinKioskId: JSON }, CheckinKioskRequest]
  sendCheckinKioskNotification?:
    | [
        { checkinKioskId?: String | null; notificationData?: SendCheckinKioskNotificationPayloadMutationInput | null },
        SuccessRequest,
      ]
    | SuccessRequest
  createCheckinVirtualKiosk?:
    | [{ data?: CheckinVirtualKioskMutationInput | null }, CheckinVirtualKioskRequest]
    | CheckinVirtualKioskRequest
  updateCheckinVirtualKioskById?: [{ id: String; data: CheckinVirtualKioskPartialMutationInput }, CheckinVirtualKioskRequest]
  updateCheckinVirtualKiosk?: [
    { where: CheckinVirtualKioskFilterMutationInput; sort?: String | null; data: CheckinVirtualKioskPartialMutationInput },
    CheckinVirtualKioskRequest,
  ]
  deleteCheckinVirtualKioskById?: [{ id?: String | null }, CheckinVirtualKioskRequest] | CheckinVirtualKioskRequest
  generateVirtualCheckinURL?: [{ checkinVirtualKioskId: String }, VirtualCheckinURLRequest]
  deleteVirtualCheckinURL?: [{ tokenId: String }, SuccessRequest]
  createVirtualCheckinSession?:
    | [{ data?: VirtualCheckinSessionMutationInput | null }, VirtualCheckinSessionRequest]
    | VirtualCheckinSessionRequest
  updateVirtualCheckinSessionById?: [
    { id: String; data: VirtualCheckinSessionPartialMutationInput },
    VirtualCheckinSessionRequest,
  ]
  updateVirtualCheckinSession?: [
    {
      where: VirtualCheckinSessionFilterMutationInput
      sort?: String | null
      data: VirtualCheckinSessionPartialMutationInput
    },
    VirtualCheckinSessionRequest,
  ]
  deleteVirtualCheckinSessionById?: [{ id?: String | null }, VirtualCheckinSessionRequest] | VirtualCheckinSessionRequest
  createMembership?: [{ data?: MembershipMutationInput | null }, MembershipRequest] | MembershipRequest
  updateMembershipById?: [{ id: String; data: MembershipPartialMutationInput }, MembershipRequest]
  updateMembership?: [
    { where: MembershipFilterMutationInput; sort?: String | null; data: MembershipPartialMutationInput },
    MembershipRequest,
  ]
  deleteMembershipById?: [{ id?: String | null }, MembershipRequest] | MembershipRequest
  inviteUser?: [{ data: AddUserPayloadMutationInput }, SuccessRequest]
  activateUser?: [{ data: ActivateUserPayloadMutationInput }, SuccessRequest]
  createRole?: [{ data?: RoleMutationInput | null }, RoleRequest] | RoleRequest
  updateRoleById?: [{ id: String; data: RolePartialMutationInput }, RoleRequest]
  updateRole?: [{ where: RoleFilterMutationInput; sort?: String | null; data: RolePartialMutationInput }, RoleRequest]
  deleteRoleById?: [{ id?: String | null }, RoleRequest] | RoleRequest
  createUserRole?: [{ data?: UserRoleMutationInput | null }, UserRoleRequest] | UserRoleRequest
  updateUserRoleById?: [{ id: String; data: UserRolePartialMutationInput }, UserRoleRequest]
  updateUserRole?: [
    { where: UserRoleFilterMutationInput; sort?: String | null; data: UserRolePartialMutationInput },
    UserRoleRequest,
  ]
  deleteUserRoleById?: [{ id?: String | null }, UserRoleRequest] | UserRoleRequest
  createAgent?: [{ data?: AgentMutationInput | null }, AgentTokenRequest] | AgentTokenRequest
  updateAgentById?: [{ id: String; data: AgentPartialMutationInput }, AgentRequest]
  updateAgent?: [{ where: AgentFilterMutationInput; sort?: String | null; data: AgentPartialMutationInput }, AgentRequest]
  deleteAgentById?: [{ id?: String | null }, AgentRequest] | AgentRequest
  createPrinter?: [{ data?: PrinterMutationInput | null }, PrinterRequest] | PrinterRequest
  updatePrinterById?: [{ id: String; data: PrinterPartialMutationInput }, PrinterRequest]
  updatePrinter?: [
    { where: PrinterFilterMutationInput; sort?: String | null; data: PrinterPartialMutationInput },
    PrinterRequest,
  ]
  deletePrinterById?: [{ id?: String | null }, PrinterRequest] | PrinterRequest
  generateAgentToken?: [{ agentId?: String | null }, AgentTokenRequest] | AgentTokenRequest
  createAgentHealthCheck?: AgentHealthCheckRequest
  updateAgentPrinters?: [
    { printers: (PrinterPartialMutationInput | null)[]; agentId?: String | null },
    UpdateAgentPrintersResultRequest,
  ]
  createScan?: [{ data?: ScanMutationInput | null }, ScanRequest] | ScanRequest
  updateScanById?: [{ id: String; data: ScanPartialMutationInput }, ScanRequest]
  updateScan?: [{ where: ScanFilterMutationInput; sort?: String | null; data: ScanPartialMutationInput }, ScanRequest]
  deleteScanById?: [{ id?: String | null }, ScanRequest] | ScanRequest
  markNotificationAsRead?: [{ notificationId: String }, NotificationRequest]
  markNotificationAsDismissed?: [{ notificationId: String }, NotificationRequest]
  createTrigger?: [{ data?: TriggerMutationInput | null }, TriggerRequest] | TriggerRequest
  updateTriggerById?: [{ id: String; data: TriggerPartialMutationInput }, TriggerRequest]
  updateTrigger?: [
    { where: TriggerFilterMutationInput; sort?: String | null; data: TriggerPartialMutationInput },
    TriggerRequest,
  ]
  deleteTriggerById?: [{ id?: String | null }, TriggerRequest] | TriggerRequest
  createTemplate?: [{ data?: TemplateMutationInput | null }, TemplateRequest] | TemplateRequest
  updateTemplateById?: [{ id: String; data: TemplatePartialMutationInput }, TemplateRequest]
  updateTemplate?: [
    { where: TemplateFilterMutationInput; sort?: String | null; data: TemplatePartialMutationInput },
    TemplateRequest,
  ]
  deleteTemplateById?: [{ id?: String | null }, TemplateRequest] | TemplateRequest
  updatePreference?: [
    { name: String; data: PreferencePartialMutationInput; isDeltaUpdate?: Boolean | null },
    PreferenceRequest,
  ]
  updateDefaultPreference?: [
    { name: String; data: PreferenceMutationInput; isDeltaUpdate?: Boolean | null },
    PreferenceRequest,
  ]
  createDeviceLog?: [{ data?: DeviceLogMutationInput | null }, DeviceLogRequest] | DeviceLogRequest
  updateDeviceLogById?: [{ id: String; data: DeviceLogPartialMutationInput }, DeviceLogRequest]
  updateDeviceLog?: [
    { where: DeviceLogFilterMutationInput; sort?: String | null; data: DeviceLogPartialMutationInput },
    DeviceLogRequest,
  ]
  deleteDeviceLogById?: [{ id?: String | null }, DeviceLogRequest] | DeviceLogRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LocationTypeMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name: String
  appearance?: LocationTypeAppearanceMutationInput | null
}

export interface LocationTypeAppearanceMutationInput {
  location?: NameAndPluralNameMutationInput | null
  visitor?: NameAndPluralNameMutationInput | null
}

export interface NameAndPluralNameMutationInput {
  name?: String | null
  pluralName?: String | null
}

export interface LocationTypePartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  appearance?: LocationTypeAppearancePartialMutationInput | null
}

export interface LocationTypeAppearancePartialMutationInput {
  location?: NameAndPluralNamePartialMutationInput | null
  visitor?: NameAndPluralNamePartialMutationInput | null
}

export interface NameAndPluralNamePartialMutationInput {
  name?: String | null
  pluralName?: String | null
}

export interface LocationTypeFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  appearance?: AppearanceFilterMutationInput | null
  AND?: (LocationTypeFilterMutationInput | null)[] | null
  OR?: (LocationTypeFilterMutationInput | null)[] | null
  NOR?: (LocationTypeFilterMutationInput | null)[] | null
}

export interface IdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: IdFilterMutationInput | null
}

export interface _idFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: _idFilterMutationInput | null
}

export interface OrganizationIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OrganizationIdFilterMutationInput | null
}

export interface CreatedAtFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CreatedAtFilterMutationInput | null
}

export interface UpdatedAtFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UpdatedAtFilterMutationInput | null
}

export interface NameFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NameFilterMutationInput | null
}

export interface AppearanceFilterMutationInput {
  location?: LocationFilterMutationInput | null
  visitor?: VisitorFilterMutationInput | null
  AND?: (AppearanceFilterMutationInput | null)[] | null
  OR?: (AppearanceFilterMutationInput | null)[] | null
  NOR?: (AppearanceFilterMutationInput | null)[] | null
}

export interface LocationFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  shortName?: ShortNameFilterMutationInput | null
  color?: ColorFilterMutationInput | null
  locationTypeId?: LocationTypeIdFilterMutationInput | null
  parentLocationId?: ParentLocationIdFilterMutationInput | null
  queue?: QueueFilterMutationInput | null
  extraData?: ExtraDataFilterMutationInput | null
  AND?: (LocationFilterMutationInput | null)[] | null
  OR?: (LocationFilterMutationInput | null)[] | null
  NOR?: (LocationFilterMutationInput | null)[] | null
}

export interface ShortNameFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ShortNameFilterMutationInput | null
}

export interface ColorFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ColorFilterMutationInput | null
}

export interface LocationTypeIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationTypeIdFilterMutationInput | null
}

export interface ParentLocationIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ParentLocationIdFilterMutationInput | null
}

export interface QueueFilterMutationInput {
  hasQueue?: HasQueueFilterMutationInput | null
  visiting?: VisitingFilterMutationInput | null
  waiting?: WaitingFilterMutationInput | null
  onCallMarkPreviousVisitAsCompleted?: OnCallMarkPreviousVisitAsCompletedFilterMutationInput | null
  AND?: (QueueFilterMutationInput | null)[] | null
  OR?: (QueueFilterMutationInput | null)[] | null
  NOR?: (QueueFilterMutationInput | null)[] | null
}

export interface HasQueueFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: HasQueueFilterMutationInput | null
}

export interface VisitingFilterMutationInput {
  maxDuration?: MaxDurationFilterMutationInput | null
  AND?: (VisitingFilterMutationInput | null)[] | null
  OR?: (VisitingFilterMutationInput | null)[] | null
  NOR?: (VisitingFilterMutationInput | null)[] | null
}

export interface MaxDurationFilterMutationInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MaxDurationFilterMutationInput | null
}

export interface WaitingFilterMutationInput {
  maxDuration?: MaxDurationFilterMutationInput | null
  AND?: (WaitingFilterMutationInput | null)[] | null
  OR?: (WaitingFilterMutationInput | null)[] | null
  NOR?: (WaitingFilterMutationInput | null)[] | null
}

export interface OnCallMarkPreviousVisitAsCompletedFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OnCallMarkPreviousVisitAsCompletedFilterMutationInput | null
}

export interface ExtraDataFilterMutationInput {
  AND?: (ExtraDataFilterMutationInput | null)[] | null
  OR?: (ExtraDataFilterMutationInput | null)[] | null
  NOR?: (ExtraDataFilterMutationInput | null)[] | null
}

export interface VisitorFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  firstname?: FirstnameFilterMutationInput | null
  lastname?: LastnameFilterMutationInput | null
  fiscalCode?: FiscalCodeFilterMutationInput | null
  sex?: SexFilterMutationInput | null
  healthCardNumber?: HealthCardNumberFilterMutationInput | null
  birth?: BirthFilterMutationInput | null
  address?: AddressFilterMutationInput | null
  contacts?: ContactsFilterMutationInput | null
  attachmentIds?: AttachmentIdsFilterMutationInput | null
  extraData?: ExtraDataFilterMutationInput | null
  AND?: (VisitorFilterMutationInput | null)[] | null
  OR?: (VisitorFilterMutationInput | null)[] | null
  NOR?: (VisitorFilterMutationInput | null)[] | null
}

export interface FirstnameFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FirstnameFilterMutationInput | null
}

export interface LastnameFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LastnameFilterMutationInput | null
}

export interface FiscalCodeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FiscalCodeFilterMutationInput | null
}

export interface SexFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SexFilterMutationInput | null
}

export interface HealthCardNumberFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: HealthCardNumberFilterMutationInput | null
}

export interface BirthFilterMutationInput {
  city?: CityFilterMutationInput | null
  province?: ProvinceFilterMutationInput | null
  date?: DateFilterMutationInput | null
  AND?: (BirthFilterMutationInput | null)[] | null
  OR?: (BirthFilterMutationInput | null)[] | null
  NOR?: (BirthFilterMutationInput | null)[] | null
}

export interface CityFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CityFilterMutationInput | null
}

export interface ProvinceFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ProvinceFilterMutationInput | null
}

export interface DateFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DateFilterMutationInput | null
}

export interface AddressFilterMutationInput {
  line1?: Line1FilterMutationInput | null
  streetNumber?: StreetNumberFilterMutationInput | null
  city?: CityFilterMutationInput | null
  province?: ProvinceFilterMutationInput | null
  AND?: (AddressFilterMutationInput | null)[] | null
  OR?: (AddressFilterMutationInput | null)[] | null
  NOR?: (AddressFilterMutationInput | null)[] | null
}

export interface Line1FilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: Line1FilterMutationInput | null
}

export interface StreetNumberFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StreetNumberFilterMutationInput | null
}

export interface ContactsFilterMutationInput {
  number?: NumberFilterMutationInput | null
  AND?: (ContactsFilterMutationInput | null)[] | null
  OR?: (ContactsFilterMutationInput | null)[] | null
  NOR?: (ContactsFilterMutationInput | null)[] | null
}

export interface NumberFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NumberFilterMutationInput | null
}

export interface AttachmentIdsFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AttachmentIdsFilterMutationInput | null
}

export interface LocationMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  shortName?: String | null
  color?: String | null
  locationTypeId: String
  parentLocationId?: String | null
  queue?: QueueSettingsMutationInput | null
  extraData?: JSON | null
}

export interface QueueSettingsMutationInput {
  hasQueue?: Boolean | null
  visiting?: VisitSettingsMutationInput | null
  waiting?: VisitSettingsMutationInput | null
  onCallMarkPreviousVisitAsCompleted?: Boolean | null
}

export interface VisitSettingsMutationInput {
  maxDuration?: Float | null
}

export interface LocationPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  shortName?: String | null
  color?: String | null
  locationTypeId?: String | null
  parentLocationId?: String | null
  queue?: QueueSettingsPartialMutationInput | null
  extraData?: JSON | null
}

export interface QueueSettingsPartialMutationInput {
  hasQueue?: Boolean | null
  visiting?: VisitSettingsPartialMutationInput | null
  waiting?: VisitSettingsPartialMutationInput | null
  onCallMarkPreviousVisitAsCompleted?: Boolean | null
}

export interface VisitSettingsPartialMutationInput {
  maxDuration?: Float | null
}

export interface LocationWithChildrenPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  shortName?: String | null
  color?: String | null
  locationTypeId?: String | null
  parentLocationId?: String | null
  queue?: QueueSettingsPartialMutationInput | null
  extraData?: JSON | null
  children?: (LocationWithChildrenPartialMutationInput | null)[] | null
}

export interface UpdateHierarchyResultRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitorMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  firstname?: String | null
  lastname?: String | null
  fiscalCode?: String | null
  sex?: String | null
  healthCardNumber?: String | null
  birth?: BirthTypeMutationInput | null
  address?: AddressMutationInput | null
  contacts?: (VisitorContactsMutationInput | null)[] | null
  attachmentIds?: (String | null)[] | null
  extraData?: JSON | null
}

export interface BirthTypeMutationInput {
  city?: String | null
  province?: String | null
  date?: DateTime | null
}

export interface AddressMutationInput {
  line1?: String | null
  streetNumber?: String | null
  city?: String | null
  province?: String | null
}

export interface VisitorContactsMutationInput {
  number?: String | null
}

export interface BookingMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  visitorId?: String | null
  date?: DateTime | null
  time?: String | null
  notes?: String | null
  prescriptionCode?: String | null
  locationId?: String | null
  aspVisitId?: String | null
  externalId?: String | null
  attachmentIds?: (String | null)[] | null
  extraData?: JSON | null
}

export interface BookingPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  visitorId?: String | null
  date?: DateTime | null
  time?: String | null
  notes?: String | null
  prescriptionCode?: String | null
  locationId?: String | null
  aspVisitId?: String | null
  externalId?: String | null
  attachmentIds?: (String | null)[] | null
  extraData?: JSON | null
}

export interface BookingFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  visitorId?: VisitorIdFilterMutationInput | null
  date?: DateFilterMutationInput | null
  time?: TimeFilterMutationInput | null
  notes?: NotesFilterMutationInput | null
  prescriptionCode?: PrescriptionCodeFilterMutationInput | null
  locationId?: LocationIdFilterMutationInput | null
  aspVisitId?: AspVisitIdFilterMutationInput | null
  externalId?: ExternalIdFilterMutationInput | null
  attachmentIds?: AttachmentIdsFilterMutationInput | null
  extraData?: ExtraDataFilterMutationInput | null
  AND?: (BookingFilterMutationInput | null)[] | null
  OR?: (BookingFilterMutationInput | null)[] | null
  NOR?: (BookingFilterMutationInput | null)[] | null
}

export interface VisitorIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VisitorIdFilterMutationInput | null
}

export interface TimeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimeFilterMutationInput | null
}

export interface NotesFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: NotesFilterMutationInput | null
}

export interface PrescriptionCodeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: PrescriptionCodeFilterMutationInput | null
}

export interface LocationIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationIdFilterMutationInput | null
}

export interface AspVisitIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AspVisitIdFilterMutationInput | null
}

export interface ExternalIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ExternalIdFilterMutationInput | null
}

export interface VisitMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  ticketCode?: String | null
  order?: Float | null
  visitorId?: String | null
  locationId?: String | null
  checkinKioskId?: String | null
  checkinVirtualKioskId?: String | null
  startedAt?: DateTime | null
  endedAt?: DateTime | null
  endedReason?: String | null
  endedNote?: String | null
  duration?: Float | null
  startedById?: String | null
  bookingId?: String | null
  endedById?: String | null
  ticketPrinted?: Boolean | null
  selfCheckin?: Boolean | null
  canVisit?: Boolean | null
  scanIds?: (String | null)[] | null
  attachmentIds?: (String | null)[] | null
  status?: String | null
  tags?: (TagMutationInput | null)[] | null
}

export interface TagMutationInput {
  text?: String | null
  color?: String | null
}

export interface VisitPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  ticketCode?: String | null
  order?: Float | null
  visitorId?: String | null
  locationId?: String | null
  checkinKioskId?: String | null
  checkinVirtualKioskId?: String | null
  startedAt?: DateTime | null
  endedAt?: DateTime | null
  endedReason?: String | null
  endedNote?: String | null
  duration?: Float | null
  startedById?: String | null
  bookingId?: String | null
  endedById?: String | null
  ticketPrinted?: Boolean | null
  selfCheckin?: Boolean | null
  canVisit?: Boolean | null
  scanIds?: (String | null)[] | null
  attachmentIds?: (String | null)[] | null
  status?: String | null
  tags?: (TagPartialMutationInput | null)[] | null
}

export interface TagPartialMutationInput {
  text?: String | null
  color?: String | null
}

export interface VisitFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  ticketCode?: TicketCodeFilterMutationInput | null
  order?: OrderFilterMutationInput | null
  visitorId?: VisitorIdFilterMutationInput | null
  locationId?: LocationIdFilterMutationInput | null
  checkinKioskId?: CheckinKioskIdFilterMutationInput | null
  checkinVirtualKioskId?: CheckinVirtualKioskIdFilterMutationInput | null
  startedAt?: StartedAtFilterMutationInput | null
  endedAt?: EndedAtFilterMutationInput | null
  endedReason?: EndedReasonFilterMutationInput | null
  endedNote?: EndedNoteFilterMutationInput | null
  duration?: DurationFilterMutationInput | null
  startedById?: StartedByIdFilterMutationInput | null
  bookingId?: BookingIdFilterMutationInput | null
  endedById?: EndedByIdFilterMutationInput | null
  ticketPrinted?: TicketPrintedFilterMutationInput | null
  selfCheckin?: SelfCheckinFilterMutationInput | null
  canVisit?: CanVisitFilterMutationInput | null
  scanIds?: ScanIdsFilterMutationInput | null
  attachmentIds?: AttachmentIdsFilterMutationInput | null
  status?: StatusFilterMutationInput | null
  tags?: TagsFilterMutationInput | null
  AND?: (VisitFilterMutationInput | null)[] | null
  OR?: (VisitFilterMutationInput | null)[] | null
  NOR?: (VisitFilterMutationInput | null)[] | null
}

export interface TicketCodeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketCodeFilterMutationInput | null
}

export interface OrderFilterMutationInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: OrderFilterMutationInput | null
}

export interface CheckinKioskIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CheckinKioskIdFilterMutationInput | null
}

export interface CheckinVirtualKioskIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CheckinVirtualKioskIdFilterMutationInput | null
}

export interface StartedAtFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StartedAtFilterMutationInput | null
}

export interface EndedAtFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedAtFilterMutationInput | null
}

export interface EndedReasonFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedReasonFilterMutationInput | null
}

export interface EndedNoteFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedNoteFilterMutationInput | null
}

export interface DurationFilterMutationInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DurationFilterMutationInput | null
}

export interface StartedByIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StartedByIdFilterMutationInput | null
}

export interface BookingIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: BookingIdFilterMutationInput | null
}

export interface EndedByIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EndedByIdFilterMutationInput | null
}

export interface TicketPrintedFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketPrintedFilterMutationInput | null
}

export interface SelfCheckinFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SelfCheckinFilterMutationInput | null
}

export interface CanVisitFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: CanVisitFilterMutationInput | null
}

export interface ScanIdsFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ScanIdsFilterMutationInput | null
}

export interface StatusFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: StatusFilterMutationInput | null
}

export interface TagsFilterMutationInput {
  text?: TextFilterMutationInput | null
  color?: ColorFilterMutationInput | null
  AND?: (TagsFilterMutationInput | null)[] | null
  OR?: (TagsFilterMutationInput | null)[] | null
  NOR?: (TagsFilterMutationInput | null)[] | null
}

export interface TextFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TextFilterMutationInput | null
}

export interface EnqueueBodyMutationInput {
  checkinKioskId?: String | null
  checkinVirtualKioskId?: String | null
  visitorCriteria?: EnqueueVisitorCriteriaMutationInput | null
  visitorData?: VisitorMutationInput | null
  bookingCriteria?: EnqueueBookingCriteriaMutationInput | null
  locationId?: String | null
  visitData?: VisitMutationInput | null
  generateTicketNumber?: Boolean | null
  inferVisitorDataFromCriteria?: Boolean | null
}

export interface EnqueueVisitorCriteriaMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  firstname?: FirstnameFilterMutationInput | null
  lastname?: LastnameFilterMutationInput | null
  fiscalCode?: FiscalCodeFilterMutationInput | null
  sex?: SexFilterMutationInput | null
  healthCardNumber?: HealthCardNumberFilterMutationInput | null
  birth?: BirthFilterMutationInput | null
  address?: AddressFilterMutationInput | null
  contacts?: ContactsFilterMutationInput | null
  attachmentIds?: AttachmentIdsFilterMutationInput | null
  extraData?: ExtraDataFilterMutationInput | null
  AND?: (VisitorFilterMutationInput | null)[] | null
  OR?: (VisitorFilterMutationInput | null)[] | null
  NOR?: (VisitorFilterMutationInput | null)[] | null
}

export interface EnqueueBookingCriteriaMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  visitorId?: VisitorIdFilterMutationInput | null
  date?: DateFilterMutationInput | null
  time?: TimeFilterMutationInput | null
  notes?: NotesFilterMutationInput | null
  prescriptionCode?: PrescriptionCodeFilterMutationInput | null
  locationId?: LocationIdFilterMutationInput | null
  aspVisitId?: AspVisitIdFilterMutationInput | null
  externalId?: ExternalIdFilterMutationInput | null
  attachmentIds?: AttachmentIdsFilterMutationInput | null
  extraData?: ExtraDataFilterMutationInput | null
  AND?: (BookingFilterMutationInput | null)[] | null
  OR?: (BookingFilterMutationInput | null)[] | null
  NOR?: (BookingFilterMutationInput | null)[] | null
}

export interface SuccessRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EndVisitMutationInput {
  visitId?: String | null
  visitIds?: (String | null)[] | null
  endedReason?: String | null
  endedNote?: String | null
  endedById?: String | null
}

export interface TransferVisitorMutationInput {
  visitId: String
  destinationLocationId: String
  note?: String | null
}

export interface AmendVisitMutationInput {
  visitId: String
  moveTo: String
  note?: String | null
}

export interface StartVisitMutationInput {
  locationId: String
  visitorId?: String | null
  visitorData?: VisitorMutationInput | null
  bookingId?: String | null
  checkinKioskId?: String | null
  checkinVirtualKioskId?: String | null
}

export interface SendToPrintResultRequest {
  success?: boolean | number
  visitId?: boolean | number
  visit?: VisitRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VisitorPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  firstname?: String | null
  lastname?: String | null
  fiscalCode?: String | null
  sex?: String | null
  healthCardNumber?: String | null
  birth?: BirthTypePartialMutationInput | null
  address?: AddressPartialMutationInput | null
  contacts?: (VisitorContactsPartialMutationInput | null)[] | null
  attachmentIds?: (String | null)[] | null
  extraData?: JSON | null
}

export interface BirthTypePartialMutationInput {
  city?: String | null
  province?: String | null
  date?: DateTime | null
}

export interface AddressPartialMutationInput {
  line1?: String | null
  streetNumber?: String | null
  city?: String | null
  province?: String | null
}

export interface VisitorContactsPartialMutationInput {
  number?: String | null
}

export interface UserMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  email?: String | null
  firstname?: String | null
  lastname?: String | null
  contacts?: (UserContactsMutationInput | null)[] | null
  shortName?: String | null
}

export interface UserContactsMutationInput {
  number?: String | null
}

export interface UserPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  email?: String | null
  firstname?: String | null
  lastname?: String | null
  contacts?: (UserContactsPartialMutationInput | null)[] | null
  shortName?: String | null
}

export interface UserContactsPartialMutationInput {
  number?: String | null
}

export interface UserFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  email?: EmailFilterMutationInput | null
  firstname?: FirstnameFilterMutationInput | null
  lastname?: LastnameFilterMutationInput | null
  contacts?: ContactsFilterMutationInput | null
  shortName?: ShortNameFilterMutationInput | null
  AND?: (UserFilterMutationInput | null)[] | null
  OR?: (UserFilterMutationInput | null)[] | null
  NOR?: (UserFilterMutationInput | null)[] | null
}

export interface EmailFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EmailFilterMutationInput | null
}

export interface CredentialsPayloadMutationInput {
  email: String
  password: String
}

export interface ResetPasswordPayloadMutationInput {
  password: String
  token: String
}

export interface ResetPasswordRequestPayloadMutationInput {
  email: String
}

export interface CreateSignageSessionTokenResultRequest {
  token?: boolean | number
  url?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface OrganizationMutationInput {
  id?: String | null
  _id?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  timezone?: String | null
  scheduledResetTime?: String | null
}

export interface OrganizationPartialMutationInput {
  id?: String | null
  _id?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  timezone?: String | null
  scheduledResetTime?: String | null
}

export interface OrganizationFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  timezone?: TimezoneFilterMutationInput | null
  scheduledResetTime?: ScheduledResetTimeFilterMutationInput | null
  AND?: (OrganizationFilterMutationInput | null)[] | null
  OR?: (OrganizationFilterMutationInput | null)[] | null
  NOR?: (OrganizationFilterMutationInput | null)[] | null
}

export interface TimezoneFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimezoneFilterMutationInput | null
}

export interface ScheduledResetTimeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ScheduledResetTimeFilterMutationInput | null
}

export interface MonitorMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationIds?: (String | null)[] | null
  notification?: MonitorNotificationMutationInput | null
  enableDebugMode?: Boolean | null
  fontSizeMultiplier?: Float | null
  interface?: JSON | null
}

export interface MonitorNotificationMutationInput {
  type?: String | null
}

export interface MonitorPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationIds?: (String | null)[] | null
  notification?: MonitorNotificationPartialMutationInput | null
  enableDebugMode?: Boolean | null
  fontSizeMultiplier?: Float | null
  interface?: JSON | null
}

export interface MonitorNotificationPartialMutationInput {
  type?: String | null
}

export interface MonitorFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  locationIds?: LocationIdsFilterMutationInput | null
  notification?: NotificationFilterMutationInput | null
  enableDebugMode?: EnableDebugModeFilterMutationInput | null
  fontSizeMultiplier?: FontSizeMultiplierFilterMutationInput | null
  interface?: InterfaceFilterMutationInput | null
  AND?: (MonitorFilterMutationInput | null)[] | null
  OR?: (MonitorFilterMutationInput | null)[] | null
  NOR?: (MonitorFilterMutationInput | null)[] | null
}

export interface LocationIdsFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: LocationIdsFilterMutationInput | null
}

export interface NotificationFilterMutationInput {
  type?: TypeFilterMutationInput | null
  AND?: (NotificationFilterMutationInput | null)[] | null
  OR?: (NotificationFilterMutationInput | null)[] | null
  NOR?: (NotificationFilterMutationInput | null)[] | null
}

export interface TypeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TypeFilterMutationInput | null
}

export interface EnableDebugModeFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EnableDebugModeFilterMutationInput | null
}

export interface FontSizeMultiplierFilterMutationInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FontSizeMultiplierFilterMutationInput | null
}

export interface InterfaceFilterMutationInput {
  AND?: (InterfaceFilterMutationInput | null)[] | null
  OR?: (InterfaceFilterMutationInput | null)[] | null
  NOR?: (InterfaceFilterMutationInput | null)[] | null
}

export interface ResultRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CheckinKioskMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationConfigs?: (LocationConfigMutationInput | null)[] | null
  addUnknownVisitorsToLocationId?: String | null
  uiTemplateId?: String | null
  interface?: CheckinKioskUIMutationInput | null
  keyboard?: KeyboardMutationInput | null
}

export interface LocationConfigMutationInput {
  locationId: String
}

export interface CheckinKioskUIMutationInput {
  steps?: (StepMutationInput | null)[] | null
  macros?: JSON | null
  theme?: JSON | null
  debugMode?: Boolean | null
}

export interface StepMutationInput {
  name: String
  onMount?: JSON | null
  state?: JSON | null
  content: JSON
}

export interface KeyboardMutationInput {
  active?: Boolean | null
}

export interface CheckinKioskPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationConfigs?: (LocationConfigPartialMutationInput | null)[] | null
  addUnknownVisitorsToLocationId?: String | null
  uiTemplateId?: String | null
  interface?: CheckinKioskUIPartialMutationInput | null
  keyboard?: KeyboardPartialMutationInput | null
}

export interface LocationConfigPartialMutationInput {
  locationId?: String | null
}

export interface CheckinKioskUIPartialMutationInput {
  steps?: (StepPartialMutationInput | null)[] | null
  macros?: JSON | null
  theme?: JSON | null
  debugMode?: Boolean | null
}

export interface StepPartialMutationInput {
  name?: String | null
  onMount?: JSON | null
  state?: JSON | null
  content?: JSON | null
}

export interface KeyboardPartialMutationInput {
  active?: Boolean | null
}

export interface CheckinKioskFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  locationConfigs?: LocationConfigsFilterMutationInput | null
  addUnknownVisitorsToLocationId?: AddUnknownVisitorsToLocationIdFilterMutationInput | null
  uiTemplateId?: UiTemplateIdFilterMutationInput | null
  interface?: InterfaceFilterMutationInput | null
  keyboard?: KeyboardFilterMutationInput | null
  AND?: (CheckinKioskFilterMutationInput | null)[] | null
  OR?: (CheckinKioskFilterMutationInput | null)[] | null
  NOR?: (CheckinKioskFilterMutationInput | null)[] | null
}

export interface LocationConfigsFilterMutationInput {
  locationId?: LocationIdFilterMutationInput | null
  AND?: (LocationConfigsFilterMutationInput | null)[] | null
  OR?: (LocationConfigsFilterMutationInput | null)[] | null
  NOR?: (LocationConfigsFilterMutationInput | null)[] | null
}

export interface AddUnknownVisitorsToLocationIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AddUnknownVisitorsToLocationIdFilterMutationInput | null
}

export interface UiTemplateIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UiTemplateIdFilterMutationInput | null
}

export interface KeyboardFilterMutationInput {
  active?: ActiveFilterMutationInput | null
  AND?: (KeyboardFilterMutationInput | null)[] | null
  OR?: (KeyboardFilterMutationInput | null)[] | null
  NOR?: (KeyboardFilterMutationInput | null)[] | null
}

export interface ActiveFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActiveFilterMutationInput | null
}

export interface GenerateCheckinSignageUrlResultRequest {
  url?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SendCheckinKioskNotificationPayloadMutationInput {
  severity: String
  message?: String | null
  extra?: ExtraMutationInput | null
}

export interface ExtraMutationInput {
  visitId?: String | null
  visitorId?: String | null
  scanId?: String | null
  checkinKioskId?: String | null
}

export interface CheckinVirtualKioskMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationConfigs?: (LocationConfigMutationInput | null)[] | null
}

export interface CheckinVirtualKioskPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  locationConfigs?: (LocationConfigPartialMutationInput | null)[] | null
}

export interface CheckinVirtualKioskFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  locationConfigs?: LocationConfigsFilterMutationInput | null
  AND?: (CheckinVirtualKioskFilterMutationInput | null)[] | null
  OR?: (CheckinVirtualKioskFilterMutationInput | null)[] | null
  NOR?: (CheckinVirtualKioskFilterMutationInput | null)[] | null
}

export interface VirtualCheckinSessionMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  identifier?: String | null
  checkinVirtualKioskId?: String | null
  token?: String | null
  status?: String | null
  privacyAccepted?: Boolean | null
  availableLocations?: JSON | null
  choosenLocationId?: JSON | null
  visitId?: String | null
  dataLog?: (LogMutationInput | null)[] | null
}

export interface LogMutationInput {
  message?: String | null
  date?: DateTime | null
}

export interface VirtualCheckinSessionPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  identifier?: String | null
  checkinVirtualKioskId?: String | null
  token?: String | null
  status?: String | null
  privacyAccepted?: Boolean | null
  availableLocations?: JSON | null
  choosenLocationId?: JSON | null
  visitId?: String | null
  dataLog?: (LogPartialMutationInput | null)[] | null
}

export interface LogPartialMutationInput {
  message?: String | null
  date?: DateTime | null
}

export interface VirtualCheckinSessionFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  identifier?: IdentifierFilterMutationInput | null
  checkinVirtualKioskId?: CheckinVirtualKioskIdFilterMutationInput | null
  token?: TokenFilterMutationInput | null
  status?: StatusFilterMutationInput | null
  privacyAccepted?: PrivacyAcceptedFilterMutationInput | null
  availableLocations?: AvailableLocationsFilterMutationInput | null
  choosenLocationId?: ChoosenLocationIdFilterMutationInput | null
  visitId?: VisitIdFilterMutationInput | null
  dataLog?: DataLogFilterMutationInput | null
  AND?: (VirtualCheckinSessionFilterMutationInput | null)[] | null
  OR?: (VirtualCheckinSessionFilterMutationInput | null)[] | null
  NOR?: (VirtualCheckinSessionFilterMutationInput | null)[] | null
}

export interface IdentifierFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: IdentifierFilterMutationInput | null
}

export interface TokenFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TokenFilterMutationInput | null
}

export interface PrivacyAcceptedFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: PrivacyAcceptedFilterMutationInput | null
}

export interface AvailableLocationsFilterMutationInput {
  AND?: (AvailableLocationsFilterMutationInput | null)[] | null
  OR?: (AvailableLocationsFilterMutationInput | null)[] | null
  NOR?: (AvailableLocationsFilterMutationInput | null)[] | null
}

export interface ChoosenLocationIdFilterMutationInput {
  AND?: (ChoosenLocationIdFilterMutationInput | null)[] | null
  OR?: (ChoosenLocationIdFilterMutationInput | null)[] | null
  NOR?: (ChoosenLocationIdFilterMutationInput | null)[] | null
}

export interface VisitIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VisitIdFilterMutationInput | null
}

export interface DataLogFilterMutationInput {
  message?: MessageFilterMutationInput | null
  date?: DateFilterMutationInput | null
  AND?: (DataLogFilterMutationInput | null)[] | null
  OR?: (DataLogFilterMutationInput | null)[] | null
  NOR?: (DataLogFilterMutationInput | null)[] | null
}

export interface MessageFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MessageFilterMutationInput | null
}

export interface MembershipMutationInput {
  id?: String | null
  _id?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  organizationId?: String | null
  userId?: String | null
}

export interface MembershipPartialMutationInput {
  id?: String | null
  _id?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  organizationId?: String | null
  userId?: String | null
}

export interface MembershipFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  userId?: UserIdFilterMutationInput | null
  AND?: (MembershipFilterMutationInput | null)[] | null
  OR?: (MembershipFilterMutationInput | null)[] | null
  NOR?: (MembershipFilterMutationInput | null)[] | null
}

export interface UserIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UserIdFilterMutationInput | null
}

export interface AddUserPayloadMutationInput {
  email: String
  password?: String | null
  sendNotificationEmail?: Boolean | null
  roleIds?: (String | null)[] | null
}

export interface ActivateUserPayloadMutationInput {
  firstname: String
  lastname: String
  password: String
  token: String
}

export interface RoleMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name: String
  policies?: (PolicyMutationInput | null)[] | null
}

export interface PolicyMutationInput {
  effect: String
  actions: String[]
  resources?: (String | null)[] | null
}

export interface RolePartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  policies?: (PolicyPartialMutationInput | null)[] | null
}

export interface PolicyPartialMutationInput {
  effect?: String | null
  actions?: (String | null)[] | null
  resources?: (String | null)[] | null
}

export interface RoleFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  policies?: PoliciesFilterMutationInput | null
  AND?: (RoleFilterMutationInput | null)[] | null
  OR?: (RoleFilterMutationInput | null)[] | null
  NOR?: (RoleFilterMutationInput | null)[] | null
}

export interface PoliciesFilterMutationInput {
  effect?: EffectFilterMutationInput | null
  actions?: ActionsFilterMutationInput | null
  resources?: ResourcesFilterMutationInput | null
  AND?: (PoliciesFilterMutationInput | null)[] | null
  OR?: (PoliciesFilterMutationInput | null)[] | null
  NOR?: (PoliciesFilterMutationInput | null)[] | null
}

export interface EffectFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EffectFilterMutationInput | null
}

export interface ActionsFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActionsFilterMutationInput | null
}

export interface ResourcesFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ResourcesFilterMutationInput | null
}

export interface UserRoleMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  userId: String
  roleId: String
}

export interface UserRolePartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  userId?: String | null
  roleId?: String | null
}

export interface UserRoleFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  userId?: UserIdFilterMutationInput | null
  roleId?: RoleIdFilterMutationInput | null
  AND?: (UserRoleFilterMutationInput | null)[] | null
  OR?: (UserRoleFilterMutationInput | null)[] | null
  NOR?: (UserRoleFilterMutationInput | null)[] | null
}

export interface RoleIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: RoleIdFilterMutationInput | null
}

export interface AgentMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  queue?: AgentQueueMutationInput | null
  checkinKioskId?: String | null
  ticketPrintTemplateId?: String | null
  activationToken?: String | null
  status?: String | null
}

export interface AgentQueueMutationInput {
  url: String
  accessKey: String
  secretKey: String
}

export interface AgentPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  queue?: AgentQueuePartialMutationInput | null
  checkinKioskId?: String | null
  ticketPrintTemplateId?: String | null
  activationToken?: String | null
  status?: String | null
}

export interface AgentQueuePartialMutationInput {
  url?: String | null
  accessKey?: String | null
  secretKey?: String | null
}

export interface AgentFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  queue?: QueueFilterMutationInput | null
  checkinKioskId?: CheckinKioskIdFilterMutationInput | null
  ticketPrintTemplateId?: TicketPrintTemplateIdFilterMutationInput | null
  activationToken?: ActivationTokenFilterMutationInput | null
  status?: StatusFilterMutationInput | null
  AND?: (AgentFilterMutationInput | null)[] | null
  OR?: (AgentFilterMutationInput | null)[] | null
  NOR?: (AgentFilterMutationInput | null)[] | null
}

export interface TicketPrintTemplateIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TicketPrintTemplateIdFilterMutationInput | null
}

export interface ActivationTokenFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ActivationTokenFilterMutationInput | null
}

export interface PrinterMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  deviceId?: String | null
  type?: String | null
  enabled?: Boolean | null
  agentId?: String | null
  options?: PrintingOptionsMutationInput | null
}

export interface PrintingOptionsMutationInput {
  media?: String | null
  sides?: String | null
  fitToPage?: Boolean | null
}

export interface PrinterPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  deviceId?: String | null
  type?: String | null
  enabled?: Boolean | null
  agentId?: String | null
  options?: PrintingOptionsPartialMutationInput | null
}

export interface PrintingOptionsPartialMutationInput {
  media?: String | null
  sides?: String | null
  fitToPage?: Boolean | null
}

export interface PrinterFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  deviceId?: DeviceIdFilterMutationInput | null
  type?: TypeFilterMutationInput | null
  enabled?: EnabledFilterMutationInput | null
  agentId?: AgentIdFilterMutationInput | null
  options?: OptionsFilterMutationInput | null
  AND?: (PrinterFilterMutationInput | null)[] | null
  OR?: (PrinterFilterMutationInput | null)[] | null
  NOR?: (PrinterFilterMutationInput | null)[] | null
}

export interface DeviceIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: DeviceIdFilterMutationInput | null
}

export interface EnabledFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EnabledFilterMutationInput | null
}

export interface AgentIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: AgentIdFilterMutationInput | null
}

export interface OptionsFilterMutationInput {
  media?: MediaFilterMutationInput | null
  sides?: SidesFilterMutationInput | null
  fitToPage?: FitToPageFilterMutationInput | null
  AND?: (OptionsFilterMutationInput | null)[] | null
  OR?: (OptionsFilterMutationInput | null)[] | null
  NOR?: (OptionsFilterMutationInput | null)[] | null
}

export interface MediaFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MediaFilterMutationInput | null
}

export interface SidesFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SidesFilterMutationInput | null
}

export interface FitToPageFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: FitToPageFilterMutationInput | null
}

export interface UpdateAgentPrintersResultRequest {
  printers?: PrinterRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ScanMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  date?: DateTime | null
  deviceId?: String | null
  temperature?: Float | null
  wearMask?: Boolean | null
  checkinKioskId?: String | null
}

export interface ScanPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  date?: DateTime | null
  deviceId?: String | null
  temperature?: Float | null
  wearMask?: Boolean | null
  checkinKioskId?: String | null
}

export interface ScanFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  date?: DateFilterMutationInput | null
  deviceId?: DeviceIdFilterMutationInput | null
  temperature?: TemperatureFilterMutationInput | null
  wearMask?: WearMaskFilterMutationInput | null
  checkinKioskId?: CheckinKioskIdFilterMutationInput | null
  AND?: (ScanFilterMutationInput | null)[] | null
  OR?: (ScanFilterMutationInput | null)[] | null
  NOR?: (ScanFilterMutationInput | null)[] | null
}

export interface TemperatureFilterMutationInput {
  EQ?: Float | null
  NE?: Float | null
  GT?: Float | null
  GTE?: Float | null
  LT?: Float | null
  LTE?: Float | null
  IN?: (Float | null)[] | null
  NIN?: (Float | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TemperatureFilterMutationInput | null
}

export interface WearMaskFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: WearMaskFilterMutationInput | null
}

export interface TriggerMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  event?: String | null
  sync?: Boolean | null
  continueOnError?: Boolean | null
  actions?: (JSON | null)[] | null
}

export interface TriggerPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  event?: String | null
  sync?: Boolean | null
  continueOnError?: Boolean | null
  actions?: (JSON | null)[] | null
}

export interface TriggerFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  event?: EventFilterMutationInput | null
  sync?: SyncFilterMutationInput | null
  continueOnError?: ContinueOnErrorFilterMutationInput | null
  actions?: ActionsFilterMutationInput | null
  AND?: (TriggerFilterMutationInput | null)[] | null
  OR?: (TriggerFilterMutationInput | null)[] | null
  NOR?: (TriggerFilterMutationInput | null)[] | null
}

export interface EventFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EventFilterMutationInput | null
}

export interface SyncFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: SyncFilterMutationInput | null
}

export interface ContinueOnErrorFilterMutationInput {
  EQ?: Boolean | null
  NE?: Boolean | null
  GT?: Boolean | null
  GTE?: Boolean | null
  LT?: Boolean | null
  LTE?: Boolean | null
  IN?: (Boolean | null)[] | null
  NIN?: (Boolean | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ContinueOnErrorFilterMutationInput | null
}

export interface TemplateMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  content?: JSON | null
}

export interface TemplatePartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  name?: String | null
  content?: JSON | null
}

export interface TemplateFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  name?: NameFilterMutationInput | null
  content?: ContentFilterMutationInput | null
  AND?: (TemplateFilterMutationInput | null)[] | null
  OR?: (TemplateFilterMutationInput | null)[] | null
  NOR?: (TemplateFilterMutationInput | null)[] | null
}

export interface ContentFilterMutationInput {
  AND?: (ContentFilterMutationInput | null)[] | null
  OR?: (ContentFilterMutationInput | null)[] | null
  NOR?: (ContentFilterMutationInput | null)[] | null
}

export interface PreferencePartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  userId?: String | null
  name?: String | null
  entityIds?: (String | null)[] | null
  fields?: JSON | null
}

export interface PreferenceMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  userId?: String | null
  name: String
  entityIds?: (String | null)[] | null
  fields?: JSON | null
}

export interface DeviceLogMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  consumerId: String
  consumerType: String
  tokenId?: String | null
  eventName: String
  message?: String | null
  userAgent?: UserAgentMutationInput | null
  timestamp?: DateTime | null
}

export interface UserAgentMutationInput {
  ua?: String | null
  browser?: BrowserMutationInput | null
  engine?: EngineMutationInput | null
  os?: OsMutationInput | null
  device?: DeviceMutationInput | null
  cpu?: JSON | null
}

export interface BrowserMutationInput {
  name?: String | null
  version?: String | null
  major?: String | null
}

export interface EngineMutationInput {
  name?: String | null
  version?: String | null
}

export interface OsMutationInput {
  name?: String | null
  version?: String | null
}

export interface DeviceMutationInput {
  vendor?: String | null
  model?: String | null
}

export interface DeviceLogPartialMutationInput {
  id?: String | null
  _id?: String | null
  organizationId?: String | null
  createdAt?: DateTime | null
  updatedAt?: DateTime | null
  consumerId?: String | null
  consumerType?: String | null
  tokenId?: String | null
  eventName?: String | null
  message?: String | null
  userAgent?: UserAgentPartialMutationInput | null
  timestamp?: DateTime | null
}

export interface UserAgentPartialMutationInput {
  ua?: String | null
  browser?: BrowserPartialMutationInput | null
  engine?: EnginePartialMutationInput | null
  os?: OsPartialMutationInput | null
  device?: DevicePartialMutationInput | null
  cpu?: JSON | null
}

export interface BrowserPartialMutationInput {
  name?: String | null
  version?: String | null
  major?: String | null
}

export interface EnginePartialMutationInput {
  name?: String | null
  version?: String | null
}

export interface OsPartialMutationInput {
  name?: String | null
  version?: String | null
}

export interface DevicePartialMutationInput {
  vendor?: String | null
  model?: String | null
}

export interface DeviceLogFilterMutationInput {
  id?: IdFilterMutationInput | null
  _id?: _idFilterMutationInput | null
  organizationId?: OrganizationIdFilterMutationInput | null
  createdAt?: CreatedAtFilterMutationInput | null
  updatedAt?: UpdatedAtFilterMutationInput | null
  consumerId?: ConsumerIdFilterMutationInput | null
  consumerType?: ConsumerTypeFilterMutationInput | null
  tokenId?: TokenIdFilterMutationInput | null
  eventName?: EventNameFilterMutationInput | null
  message?: MessageFilterMutationInput | null
  userAgent?: UserAgentFilterMutationInput | null
  timestamp?: TimestampFilterMutationInput | null
  AND?: (DeviceLogFilterMutationInput | null)[] | null
  OR?: (DeviceLogFilterMutationInput | null)[] | null
  NOR?: (DeviceLogFilterMutationInput | null)[] | null
}

export interface ConsumerIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ConsumerIdFilterMutationInput | null
}

export interface ConsumerTypeFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ConsumerTypeFilterMutationInput | null
}

export interface TokenIdFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TokenIdFilterMutationInput | null
}

export interface EventNameFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: EventNameFilterMutationInput | null
}

export interface UserAgentFilterMutationInput {
  ua?: UaFilterMutationInput | null
  browser?: BrowserFilterMutationInput | null
  engine?: EngineFilterMutationInput | null
  os?: OsFilterMutationInput | null
  device?: DeviceFilterMutationInput | null
  cpu?: CpuFilterMutationInput | null
  AND?: (UserAgentFilterMutationInput | null)[] | null
  OR?: (UserAgentFilterMutationInput | null)[] | null
  NOR?: (UserAgentFilterMutationInput | null)[] | null
}

export interface UaFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: UaFilterMutationInput | null
}

export interface BrowserFilterMutationInput {
  name?: NameFilterMutationInput | null
  version?: VersionFilterMutationInput | null
  major?: MajorFilterMutationInput | null
  AND?: (BrowserFilterMutationInput | null)[] | null
  OR?: (BrowserFilterMutationInput | null)[] | null
  NOR?: (BrowserFilterMutationInput | null)[] | null
}

export interface VersionFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VersionFilterMutationInput | null
}

export interface MajorFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: MajorFilterMutationInput | null
}

export interface EngineFilterMutationInput {
  name?: NameFilterMutationInput | null
  version?: VersionFilterMutationInput | null
  AND?: (EngineFilterMutationInput | null)[] | null
  OR?: (EngineFilterMutationInput | null)[] | null
  NOR?: (EngineFilterMutationInput | null)[] | null
}

export interface OsFilterMutationInput {
  name?: NameFilterMutationInput | null
  version?: VersionFilterMutationInput | null
  AND?: (OsFilterMutationInput | null)[] | null
  OR?: (OsFilterMutationInput | null)[] | null
  NOR?: (OsFilterMutationInput | null)[] | null
}

export interface DeviceFilterMutationInput {
  vendor?: VendorFilterMutationInput | null
  model?: ModelFilterMutationInput | null
  AND?: (DeviceFilterMutationInput | null)[] | null
  OR?: (DeviceFilterMutationInput | null)[] | null
  NOR?: (DeviceFilterMutationInput | null)[] | null
}

export interface VendorFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: VendorFilterMutationInput | null
}

export interface ModelFilterMutationInput {
  EQ?: String | null
  NE?: String | null
  GT?: String | null
  GTE?: String | null
  LT?: String | null
  LTE?: String | null
  IN?: (String | null)[] | null
  NIN?: (String | null)[] | null
  EXISTS?: Boolean | null
  NOT?: ModelFilterMutationInput | null
}

export interface CpuFilterMutationInput {
  AND?: (CpuFilterMutationInput | null)[] | null
  OR?: (CpuFilterMutationInput | null)[] | null
  NOR?: (CpuFilterMutationInput | null)[] | null
}

export interface TimestampFilterMutationInput {
  EQ?: DateTime | null
  NE?: DateTime | null
  GT?: DateTime | null
  GTE?: DateTime | null
  LT?: DateTime | null
  LTE?: DateTime | null
  IN?: (DateTime | null)[] | null
  NIN?: (DateTime | null)[] | null
  EXISTS?: Boolean | null
  NOT?: TimestampFilterMutationInput | null
}

const Query_possibleTypes = ['Query']
export const isQuery = (obj: { __typename: String }): obj is Query => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Query_possibleTypes.includes(obj.__typename)
}

const LocationTypesConnection_possibleTypes = ['LocationTypesConnection']
export const isLocationTypesConnection = (obj: { __typename: String }): obj is LocationTypesConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationTypesConnection_possibleTypes.includes(obj.__typename)
}

const LocationType_possibleTypes = ['LocationType']
export const isLocationType = (obj: { __typename: String }): obj is LocationType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationType_possibleTypes.includes(obj.__typename)
}

const LocationTypeAppearance_possibleTypes = ['LocationTypeAppearance']
export const isLocationTypeAppearance = (obj: { __typename: String }): obj is LocationTypeAppearance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationTypeAppearance_possibleTypes.includes(obj.__typename)
}

const NameAndPluralName_possibleTypes = ['NameAndPluralName']
export const isNameAndPluralName = (obj: { __typename: String }): obj is NameAndPluralName => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NameAndPluralName_possibleTypes.includes(obj.__typename)
}

const LocationsConnection_possibleTypes = ['LocationsConnection']
export const isLocationsConnection = (obj: { __typename: String }): obj is LocationsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationsConnection_possibleTypes.includes(obj.__typename)
}

const Location_possibleTypes = ['Location']
export const isLocation = (obj: { __typename: String }): obj is Location => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Location_possibleTypes.includes(obj.__typename)
}

const QueueSettings_possibleTypes = ['QueueSettings']
export const isQueueSettings = (obj: { __typename: String }): obj is QueueSettings => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return QueueSettings_possibleTypes.includes(obj.__typename)
}

const VisitSettings_possibleTypes = ['VisitSettings']
export const isVisitSettings = (obj: { __typename: String }): obj is VisitSettings => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VisitSettings_possibleTypes.includes(obj.__typename)
}

const LocationWithChildren_possibleTypes = ['LocationWithChildren']
export const isLocationWithChildren = (obj: { __typename: String }): obj is LocationWithChildren => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationWithChildren_possibleTypes.includes(obj.__typename)
}

const Booking_possibleTypes = ['Booking']
export const isBooking = (obj: { __typename: String }): obj is Booking => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Booking_possibleTypes.includes(obj.__typename)
}

const Visitor_possibleTypes = ['Visitor']
export const isVisitor = (obj: { __typename: String }): obj is Visitor => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Visitor_possibleTypes.includes(obj.__typename)
}

const BirthType_possibleTypes = ['BirthType']
export const isBirthType = (obj: { __typename: String }): obj is BirthType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BirthType_possibleTypes.includes(obj.__typename)
}

const Address_possibleTypes = ['Address']
export const isAddress = (obj: { __typename: String }): obj is Address => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Address_possibleTypes.includes(obj.__typename)
}

const VisitorContacts_possibleTypes = ['VisitorContacts']
export const isVisitorContacts = (obj: { __typename: String }): obj is VisitorContacts => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VisitorContacts_possibleTypes.includes(obj.__typename)
}

const Visit_possibleTypes = ['Visit']
export const isVisit = (obj: { __typename: String }): obj is Visit => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Visit_possibleTypes.includes(obj.__typename)
}

const Tag_possibleTypes = ['Tag']
export const isTag = (obj: { __typename: String }): obj is Tag => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Tag_possibleTypes.includes(obj.__typename)
}

const User_possibleTypes = ['User']
export const isUser = (obj: { __typename: String }): obj is User => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return User_possibleTypes.includes(obj.__typename)
}

const UserContacts_possibleTypes = ['UserContacts']
export const isUserContacts = (obj: { __typename: String }): obj is UserContacts => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserContacts_possibleTypes.includes(obj.__typename)
}

const Role_possibleTypes = ['Role']
export const isRole = (obj: { __typename: String }): obj is Role => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Role_possibleTypes.includes(obj.__typename)
}

const Policy_possibleTypes = ['Policy']
export const isPolicy = (obj: { __typename: String }): obj is Policy => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Policy_possibleTypes.includes(obj.__typename)
}

const Membership_possibleTypes = ['Membership']
export const isMembership = (obj: { __typename: String }): obj is Membership => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Membership_possibleTypes.includes(obj.__typename)
}

const Organization_possibleTypes = ['Organization']
export const isOrganization = (obj: { __typename: String }): obj is Organization => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Organization_possibleTypes.includes(obj.__typename)
}

const Scan_possibleTypes = ['Scan']
export const isScan = (obj: { __typename: String }): obj is Scan => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Scan_possibleTypes.includes(obj.__typename)
}

const BookingsConnection_possibleTypes = ['BookingsConnection']
export const isBookingsConnection = (obj: { __typename: String }): obj is BookingsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BookingsConnection_possibleTypes.includes(obj.__typename)
}

const VisitsConnection_possibleTypes = ['VisitsConnection']
export const isVisitsConnection = (obj: { __typename: String }): obj is VisitsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VisitsConnection_possibleTypes.includes(obj.__typename)
}

const StartedVisitsByStartedByIdResult_possibleTypes = ['StartedVisitsByStartedByIdResult']
export const isStartedVisitsByStartedByIdResult = (obj: { __typename: String }): obj is StartedVisitsByStartedByIdResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StartedVisitsByStartedByIdResult_possibleTypes.includes(obj.__typename)
}

const SpeakResult_possibleTypes = ['SpeakResult']
export const isSpeakResult = (obj: { __typename: String }): obj is SpeakResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpeakResult_possibleTypes.includes(obj.__typename)
}

const QueueCounts_possibleTypes = ['QueueCounts']
export const isQueueCounts = (obj: { __typename: String }): obj is QueueCounts => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return QueueCounts_possibleTypes.includes(obj.__typename)
}

const Counts_possibleTypes = ['Counts']
export const isCounts = (obj: { __typename: String }): obj is Counts => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Counts_possibleTypes.includes(obj.__typename)
}

const VisitorsConnection_possibleTypes = ['VisitorsConnection']
export const isVisitorsConnection = (obj: { __typename: String }): obj is VisitorsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VisitorsConnection_possibleTypes.includes(obj.__typename)
}

const JourneyForVisitorResponse_possibleTypes = ['JourneyForVisitorResponse']
export const isJourneyForVisitorResponse = (obj: { __typename: String }): obj is JourneyForVisitorResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return JourneyForVisitorResponse_possibleTypes.includes(obj.__typename)
}

const UsersConnection_possibleTypes = ['UsersConnection']
export const isUsersConnection = (obj: { __typename: String }): obj is UsersConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UsersConnection_possibleTypes.includes(obj.__typename)
}

const Identity_possibleTypes = ['User', 'Monitor', 'CheckinKiosk', 'Agent', 'Visitor']
export const isIdentity = (obj: { __typename: String }): obj is Identity => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Identity_possibleTypes.includes(obj.__typename)
}

const Monitor_possibleTypes = ['Monitor']
export const isMonitor = (obj: { __typename: String }): obj is Monitor => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Monitor_possibleTypes.includes(obj.__typename)
}

const MonitorNotification_possibleTypes = ['MonitorNotification']
export const isMonitorNotification = (obj: { __typename: String }): obj is MonitorNotification => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MonitorNotification_possibleTypes.includes(obj.__typename)
}

const CheckinKiosk_possibleTypes = ['CheckinKiosk']
export const isCheckinKiosk = (obj: { __typename: String }): obj is CheckinKiosk => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CheckinKiosk_possibleTypes.includes(obj.__typename)
}

const LocationConfig_possibleTypes = ['LocationConfig']
export const isLocationConfig = (obj: { __typename: String }): obj is LocationConfig => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LocationConfig_possibleTypes.includes(obj.__typename)
}

const CheckinKioskUI_possibleTypes = ['CheckinKioskUI']
export const isCheckinKioskUI = (obj: { __typename: String }): obj is CheckinKioskUI => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CheckinKioskUI_possibleTypes.includes(obj.__typename)
}

const Step_possibleTypes = ['Step']
export const isStep = (obj: { __typename: String }): obj is Step => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Step_possibleTypes.includes(obj.__typename)
}

const Keyboard_possibleTypes = ['Keyboard']
export const isKeyboard = (obj: { __typename: String }): obj is Keyboard => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Keyboard_possibleTypes.includes(obj.__typename)
}

const Agent_possibleTypes = ['Agent']
export const isAgent = (obj: { __typename: String }): obj is Agent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Agent_possibleTypes.includes(obj.__typename)
}

const AgentQueue_possibleTypes = ['AgentQueue']
export const isAgentQueue = (obj: { __typename: String }): obj is AgentQueue => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AgentQueue_possibleTypes.includes(obj.__typename)
}

const AgentToken_possibleTypes = ['AgentToken']
export const isAgentToken = (obj: { __typename: String }): obj is AgentToken => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AgentToken_possibleTypes.includes(obj.__typename)
}

const Extra_possibleTypes = ['Extra']
export const isExtra = (obj: { __typename: String }): obj is Extra => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Extra_possibleTypes.includes(obj.__typename)
}

const TokenConsumer_possibleTypes = ['User', 'Monitor', 'CheckinKiosk', 'CheckinVirtualKiosk', 'Agent', 'Visitor']
export const isTokenConsumer = (obj: { __typename: String }): obj is TokenConsumer => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TokenConsumer_possibleTypes.includes(obj.__typename)
}

const CheckinVirtualKiosk_possibleTypes = ['CheckinVirtualKiosk']
export const isCheckinVirtualKiosk = (obj: { __typename: String }): obj is CheckinVirtualKiosk => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CheckinVirtualKiosk_possibleTypes.includes(obj.__typename)
}

const VirtualCheckinURL_possibleTypes = ['VirtualCheckinURL']
export const isVirtualCheckinURL = (obj: { __typename: String }): obj is VirtualCheckinURL => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VirtualCheckinURL_possibleTypes.includes(obj.__typename)
}

const Token_possibleTypes = ['Token']
export const isToken = (obj: { __typename: String }): obj is Token => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Token_possibleTypes.includes(obj.__typename)
}

const Printer_possibleTypes = ['Printer']
export const isPrinter = (obj: { __typename: String }): obj is Printer => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Printer_possibleTypes.includes(obj.__typename)
}

const PrintingOptions_possibleTypes = ['PrintingOptions']
export const isPrintingOptions = (obj: { __typename: String }): obj is PrintingOptions => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PrintingOptions_possibleTypes.includes(obj.__typename)
}

const AgentHealthChecksConnection_possibleTypes = ['AgentHealthChecksConnection']
export const isAgentHealthChecksConnection = (obj: { __typename: String }): obj is AgentHealthChecksConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AgentHealthChecksConnection_possibleTypes.includes(obj.__typename)
}

const AgentHealthCheck_possibleTypes = ['AgentHealthCheck']
export const isAgentHealthCheck = (obj: { __typename: String }): obj is AgentHealthCheck => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AgentHealthCheck_possibleTypes.includes(obj.__typename)
}

const OrganizationsConnection_possibleTypes = ['OrganizationsConnection']
export const isOrganizationsConnection = (obj: { __typename: String }): obj is OrganizationsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return OrganizationsConnection_possibleTypes.includes(obj.__typename)
}

const MonitorsConnection_possibleTypes = ['MonitorsConnection']
export const isMonitorsConnection = (obj: { __typename: String }): obj is MonitorsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MonitorsConnection_possibleTypes.includes(obj.__typename)
}

const MonitorVisitsByLocationIdResult_possibleTypes = ['MonitorVisitsByLocationIdResult']
export const isMonitorVisitsByLocationIdResult = (obj: { __typename: String }): obj is MonitorVisitsByLocationIdResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MonitorVisitsByLocationIdResult_possibleTypes.includes(obj.__typename)
}

const CheckinKiosksConnection_possibleTypes = ['CheckinKiosksConnection']
export const isCheckinKiosksConnection = (obj: { __typename: String }): obj is CheckinKiosksConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CheckinKiosksConnection_possibleTypes.includes(obj.__typename)
}

const CheckinVirtualKiosksConnection_possibleTypes = ['CheckinVirtualKiosksConnection']
export const isCheckinVirtualKiosksConnection = (obj: { __typename: String }): obj is CheckinVirtualKiosksConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CheckinVirtualKiosksConnection_possibleTypes.includes(obj.__typename)
}

const VirtualCheckinSessionsConnection_possibleTypes = ['VirtualCheckinSessionsConnection']
export const isVirtualCheckinSessionsConnection = (obj: { __typename: String }): obj is VirtualCheckinSessionsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VirtualCheckinSessionsConnection_possibleTypes.includes(obj.__typename)
}

const VirtualCheckinSession_possibleTypes = ['VirtualCheckinSession']
export const isVirtualCheckinSession = (obj: { __typename: String }): obj is VirtualCheckinSession => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VirtualCheckinSession_possibleTypes.includes(obj.__typename)
}

const Log_possibleTypes = ['Log']
export const isLog = (obj: { __typename: String }): obj is Log => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Log_possibleTypes.includes(obj.__typename)
}

const MembershipsConnection_possibleTypes = ['MembershipsConnection']
export const isMembershipsConnection = (obj: { __typename: String }): obj is MembershipsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MembershipsConnection_possibleTypes.includes(obj.__typename)
}

const RolesConnection_possibleTypes = ['RolesConnection']
export const isRolesConnection = (obj: { __typename: String }): obj is RolesConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RolesConnection_possibleTypes.includes(obj.__typename)
}

const UserRolesConnection_possibleTypes = ['UserRolesConnection']
export const isUserRolesConnection = (obj: { __typename: String }): obj is UserRolesConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserRolesConnection_possibleTypes.includes(obj.__typename)
}

const UserRole_possibleTypes = ['UserRole']
export const isUserRole = (obj: { __typename: String }): obj is UserRole => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserRole_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsCountResponse_possibleTypes = ['AnalyticsVisitsCountResponse']
export const isAnalyticsVisitsCountResponse = (obj: { __typename: String }): obj is AnalyticsVisitsCountResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsCountResponse_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsCountResponseData_possibleTypes = ['AnalyticsVisitsCountResponseData']
export const isAnalyticsVisitsCountResponseData = (obj: { __typename: String }): obj is AnalyticsVisitsCountResponseData => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsCountResponseData_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsDurationResponse_possibleTypes = ['AnalyticsVisitsDurationResponse']
export const isAnalyticsVisitsDurationResponse = (obj: { __typename: String }): obj is AnalyticsVisitsDurationResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsDurationResponse_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsDurationResponseData_possibleTypes = ['AnalyticsVisitsDurationResponseData']
export const isAnalyticsVisitsDurationResponseData = (obj: {
  __typename: String
}): obj is AnalyticsVisitsDurationResponseData => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsDurationResponseData_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsWaitingTimeResponse_possibleTypes = ['AnalyticsVisitsWaitingTimeResponse']
export const isAnalyticsVisitsWaitingTimeResponse = (obj: {
  __typename: String
}): obj is AnalyticsVisitsWaitingTimeResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsWaitingTimeResponse_possibleTypes.includes(obj.__typename)
}

const AnalyticsVisitsWaitingTimeResponseData_possibleTypes = ['AnalyticsVisitsWaitingTimeResponseData']
export const isAnalyticsVisitsWaitingTimeResponseData = (obj: {
  __typename: String
}): obj is AnalyticsVisitsWaitingTimeResponseData => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnalyticsVisitsWaitingTimeResponseData_possibleTypes.includes(obj.__typename)
}

const AgentsConnection_possibleTypes = ['AgentsConnection']
export const isAgentsConnection = (obj: { __typename: String }): obj is AgentsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AgentsConnection_possibleTypes.includes(obj.__typename)
}

const PrintersConnection_possibleTypes = ['PrintersConnection']
export const isPrintersConnection = (obj: { __typename: String }): obj is PrintersConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PrintersConnection_possibleTypes.includes(obj.__typename)
}

const ScansConnection_possibleTypes = ['ScansConnection']
export const isScansConnection = (obj: { __typename: String }): obj is ScansConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ScansConnection_possibleTypes.includes(obj.__typename)
}

const NotificationsConnection_possibleTypes = ['NotificationsConnection']
export const isNotificationsConnection = (obj: { __typename: String }): obj is NotificationsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NotificationsConnection_possibleTypes.includes(obj.__typename)
}

const Notification_possibleTypes = ['Notification']
export const isNotification = (obj: { __typename: String }): obj is Notification => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Notification_possibleTypes.includes(obj.__typename)
}

const Receiver_possibleTypes = ['Receiver']
export const isReceiver = (obj: { __typename: String }): obj is Receiver => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Receiver_possibleTypes.includes(obj.__typename)
}

const Sender_possibleTypes = ['CheckinKiosk']
export const isSender = (obj: { __typename: String }): obj is Sender => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Sender_possibleTypes.includes(obj.__typename)
}

const AblyAuthorizeResponse_possibleTypes = ['AblyAuthorizeResponse']
export const isAblyAuthorizeResponse = (obj: { __typename: String }): obj is AblyAuthorizeResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AblyAuthorizeResponse_possibleTypes.includes(obj.__typename)
}

const AblyEnabledResponse_possibleTypes = ['AblyEnabledResponse']
export const isAblyEnabledResponse = (obj: { __typename: String }): obj is AblyEnabledResponse => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AblyEnabledResponse_possibleTypes.includes(obj.__typename)
}

const TriggersConnection_possibleTypes = ['TriggersConnection']
export const isTriggersConnection = (obj: { __typename: String }): obj is TriggersConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TriggersConnection_possibleTypes.includes(obj.__typename)
}

const Trigger_possibleTypes = ['Trigger']
export const isTrigger = (obj: { __typename: String }): obj is Trigger => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Trigger_possibleTypes.includes(obj.__typename)
}

const TemplatesConnection_possibleTypes = ['TemplatesConnection']
export const isTemplatesConnection = (obj: { __typename: String }): obj is TemplatesConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TemplatesConnection_possibleTypes.includes(obj.__typename)
}

const Template_possibleTypes = ['Template']
export const isTemplate = (obj: { __typename: String }): obj is Template => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Template_possibleTypes.includes(obj.__typename)
}

const Preference_possibleTypes = ['Preference']
export const isPreference = (obj: { __typename: String }): obj is Preference => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Preference_possibleTypes.includes(obj.__typename)
}

const DeviceLogsConnection_possibleTypes = ['DeviceLogsConnection']
export const isDeviceLogsConnection = (obj: { __typename: String }): obj is DeviceLogsConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeviceLogsConnection_possibleTypes.includes(obj.__typename)
}

const DeviceLog_possibleTypes = ['DeviceLog']
export const isDeviceLog = (obj: { __typename: String }): obj is DeviceLog => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeviceLog_possibleTypes.includes(obj.__typename)
}

const UserAgent_possibleTypes = ['UserAgent']
export const isUserAgent = (obj: { __typename: String }): obj is UserAgent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserAgent_possibleTypes.includes(obj.__typename)
}

const Browser_possibleTypes = ['Browser']
export const isBrowser = (obj: { __typename: String }): obj is Browser => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Browser_possibleTypes.includes(obj.__typename)
}

const Engine_possibleTypes = ['Engine']
export const isEngine = (obj: { __typename: String }): obj is Engine => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Engine_possibleTypes.includes(obj.__typename)
}

const Os_possibleTypes = ['Os']
export const isOs = (obj: { __typename: String }): obj is Os => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Os_possibleTypes.includes(obj.__typename)
}

const Device_possibleTypes = ['Device']
export const isDevice = (obj: { __typename: String }): obj is Device => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Device_possibleTypes.includes(obj.__typename)
}

const Mutation_possibleTypes = ['Mutation']
export const isMutation = (obj: { __typename: String }): obj is Mutation => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Mutation_possibleTypes.includes(obj.__typename)
}

const UpdateHierarchyResult_possibleTypes = ['UpdateHierarchyResult']
export const isUpdateHierarchyResult = (obj: { __typename: String }): obj is UpdateHierarchyResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateHierarchyResult_possibleTypes.includes(obj.__typename)
}

const Success_possibleTypes = ['Success']
export const isSuccess = (obj: { __typename: String }): obj is Success => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Success_possibleTypes.includes(obj.__typename)
}

const SendToPrintResult_possibleTypes = ['SendToPrintResult']
export const isSendToPrintResult = (obj: { __typename: String }): obj is SendToPrintResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SendToPrintResult_possibleTypes.includes(obj.__typename)
}

const CreateSignageSessionTokenResult_possibleTypes = ['CreateSignageSessionTokenResult']
export const isCreateSignageSessionTokenResult = (obj: { __typename: String }): obj is CreateSignageSessionTokenResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateSignageSessionTokenResult_possibleTypes.includes(obj.__typename)
}

const Result_possibleTypes = ['Result']
export const isResult = (obj: { __typename: String }): obj is Result => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Result_possibleTypes.includes(obj.__typename)
}

const GenerateCheckinSignageUrlResult_possibleTypes = ['GenerateCheckinSignageUrlResult']
export const isGenerateCheckinSignageUrlResult = (obj: { __typename: String }): obj is GenerateCheckinSignageUrlResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GenerateCheckinSignageUrlResult_possibleTypes.includes(obj.__typename)
}

const UpdateAgentPrintersResult_possibleTypes = ['UpdateAgentPrintersResult']
export const isUpdateAgentPrintersResult = (obj: { __typename: String }): obj is UpdateAgentPrintersResult => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateAgentPrintersResult_possibleTypes.includes(obj.__typename)
}

export interface QueryPromiseChain {
  locationTypes: ((args?: {
    where?: LocationTypeFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationTypesConnectionPromiseChain & {
    execute: (
      request: LocationTypesConnectionRequest,
      defaultValue?: LocationTypesConnection | null,
    ) => Promise<LocationTypesConnection | null>
  }) &
    (LocationTypesConnectionPromiseChain & {
      execute: (
        request: LocationTypesConnectionRequest,
        defaultValue?: LocationTypesConnection | null,
      ) => Promise<LocationTypesConnection | null>
    })
  locationType: ((args?: {
    where?: LocationTypeFilterQueryInput | null
    sort?: String | null
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }) &
    (LocationTypePromiseChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
    })
  locationTypeById: ((args?: {
    id?: String | null
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }) &
    (LocationTypePromiseChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
    })
  locations: ((args?: {
    where?: LocationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationsConnectionPromiseChain & {
    execute: (
      request: LocationsConnectionRequest,
      defaultValue?: LocationsConnection | null,
    ) => Promise<LocationsConnection | null>
  }) &
    (LocationsConnectionPromiseChain & {
      execute: (
        request: LocationsConnectionRequest,
        defaultValue?: LocationsConnection | null,
      ) => Promise<LocationsConnection | null>
    })
  location: ((args?: {
    where?: LocationFilterQueryInput | null
    sort?: String | null
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }) &
    (LocationPromiseChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
    })
  locationById: ((args?: {
    id?: String | null
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }) &
    (LocationPromiseChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
    })
  locationsForTransfer: ((args?: {
    where?: LocationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationsConnectionPromiseChain & {
    execute: (
      request: LocationsConnectionRequest,
      defaultValue?: LocationsConnection | null,
    ) => Promise<LocationsConnection | null>
  }) &
    (LocationsConnectionPromiseChain & {
      execute: (
        request: LocationsConnectionRequest,
        defaultValue?: LocationsConnection | null,
      ) => Promise<LocationsConnection | null>
    })
  locationTree: ((args?: {
    id?: String | null
  }) => {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Promise<(LocationWithChildren | null)[] | null>
  }) & {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Promise<(LocationWithChildren | null)[] | null>
  }
  bookingsForVisitor: (args: {
    args: BookingsQueryQueryInput
  }) => {
    execute: (request: BookingRequest, defaultValue?: (Booking | null)[] | null) => Promise<(Booking | null)[] | null>
  }
  bookings: ((args?: {
    where?: BookingFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => BookingsConnectionPromiseChain & {
    execute: (
      request: BookingsConnectionRequest,
      defaultValue?: BookingsConnection | null,
    ) => Promise<BookingsConnection | null>
  }) &
    (BookingsConnectionPromiseChain & {
      execute: (
        request: BookingsConnectionRequest,
        defaultValue?: BookingsConnection | null,
      ) => Promise<BookingsConnection | null>
    })
  booking: ((args?: {
    where?: BookingFilterQueryInput | null
    sort?: String | null
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }) &
    (BookingPromiseChain & { execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null> })
  bookingById: ((args?: {
    id?: String | null
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }) &
    (BookingPromiseChain & { execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null> })
  visits: ((args?: {
    where?: VisitFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VisitsConnectionPromiseChain & {
    execute: (request: VisitsConnectionRequest, defaultValue?: VisitsConnection | null) => Promise<VisitsConnection | null>
  }) &
    (VisitsConnectionPromiseChain & {
      execute: (request: VisitsConnectionRequest, defaultValue?: VisitsConnection | null) => Promise<VisitsConnection | null>
    })
  visit: ((args?: {
    where?: VisitFilterQueryInput | null
    sort?: String | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }) &
    (VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> })
  visitById: ((args?: {
    id?: String | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }) &
    (VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> })
  startedVisitsByStartedById: ((args?: {
    where?: VisitFilterQueryInput | null
  }) => {
    execute: (
      request: StartedVisitsByStartedByIdResultRequest,
      defaultValue?: (StartedVisitsByStartedByIdResult | null)[] | null,
    ) => Promise<(StartedVisitsByStartedByIdResult | null)[] | null>
  }) & {
    execute: (
      request: StartedVisitsByStartedByIdResultRequest,
      defaultValue?: (StartedVisitsByStartedByIdResult | null)[] | null,
    ) => Promise<(StartedVisitsByStartedByIdResult | null)[] | null>
  }
  speak: (args: {
    ssml: String
    engine?: engine | null
  }) => SpeakResultPromiseChain & {
    execute: (request: SpeakResultRequest, defaultValue?: SpeakResult | null) => Promise<SpeakResult | null>
  }
  visitTags: { execute: (request: TagRequest, defaultValue?: (Tag | null)[] | null) => Promise<(Tag | null)[] | null> }
  queueCounts: ((args?: {
    where?: FetchQueueCountsCriteriaQueryInput | null
    includeChildren?: Boolean | null
    sort?: String | null
  }) => {
    execute: (
      request: QueueCountsRequest,
      defaultValue?: (QueueCounts | null)[] | null,
    ) => Promise<(QueueCounts | null)[] | null>
  }) & {
    execute: (
      request: QueueCountsRequest,
      defaultValue?: (QueueCounts | null)[] | null,
    ) => Promise<(QueueCounts | null)[] | null>
  }
  visitors: ((args?: {
    where?: VisitorFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VisitorsConnectionPromiseChain & {
    execute: (
      request: VisitorsConnectionRequest,
      defaultValue?: VisitorsConnection | null,
    ) => Promise<VisitorsConnection | null>
  }) &
    (VisitorsConnectionPromiseChain & {
      execute: (
        request: VisitorsConnectionRequest,
        defaultValue?: VisitorsConnection | null,
      ) => Promise<VisitorsConnection | null>
    })
  visitor: ((args?: {
    where?: VisitorFilterQueryInput | null
    sort?: String | null
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }) &
    (VisitorPromiseChain & { execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null> })
  visitorById: ((args?: {
    id?: String | null
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }) &
    (VisitorPromiseChain & { execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null> })
  journeyForVisitor: JourneyForVisitorResponsePromiseChain & {
    execute: (
      request: JourneyForVisitorResponseRequest,
      defaultValue?: JourneyForVisitorResponse | null,
    ) => Promise<JourneyForVisitorResponse | null>
  }
  users: ((args?: {
    where?: UserFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => UsersConnectionPromiseChain & {
    execute: (request: UsersConnectionRequest, defaultValue?: UsersConnection | null) => Promise<UsersConnection | null>
  }) &
    (UsersConnectionPromiseChain & {
      execute: (request: UsersConnectionRequest, defaultValue?: UsersConnection | null) => Promise<UsersConnection | null>
    })
  user: ((args?: {
    where?: UserFilterQueryInput | null
    sort?: String | null
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }) &
    (UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> })
  userById: ((args?: {
    id?: String | null
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }) &
    (UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> })
  identity: { execute: (request: IdentityRequest, defaultValue?: Identity | null) => Promise<Identity | null> }
  signageSessionTokens: (args: {
    consumerId: String
    consumerType: String
    limit?: Float | null
    skip?: Float | null
  }) => { execute: (request: TokenRequest, defaultValue?: (Token | null)[] | null) => Promise<(Token | null)[] | null> }
  checkTokenValidity: (args: {
    token: String
  }) => TokenPromiseChain & { execute: (request: TokenRequest, defaultValue?: Token | null) => Promise<Token | null> }
  organizations: ((args?: {
    where?: OrganizationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => OrganizationsConnectionPromiseChain & {
    execute: (
      request: OrganizationsConnectionRequest,
      defaultValue?: OrganizationsConnection | null,
    ) => Promise<OrganizationsConnection | null>
  }) &
    (OrganizationsConnectionPromiseChain & {
      execute: (
        request: OrganizationsConnectionRequest,
        defaultValue?: OrganizationsConnection | null,
      ) => Promise<OrganizationsConnection | null>
    })
  organization: ((args?: {
    where?: OrganizationFilterQueryInput | null
    sort?: String | null
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }) &
    (OrganizationPromiseChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
    })
  organizationById: ((args?: {
    id?: String | null
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }) &
    (OrganizationPromiseChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
    })
  monitors: ((args?: {
    where?: MonitorFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => MonitorsConnectionPromiseChain & {
    execute: (
      request: MonitorsConnectionRequest,
      defaultValue?: MonitorsConnection | null,
    ) => Promise<MonitorsConnection | null>
  }) &
    (MonitorsConnectionPromiseChain & {
      execute: (
        request: MonitorsConnectionRequest,
        defaultValue?: MonitorsConnection | null,
      ) => Promise<MonitorsConnection | null>
    })
  monitor: ((args?: {
    where?: MonitorFilterQueryInput | null
    sort?: String | null
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }) &
    (MonitorPromiseChain & { execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null> })
  monitorById: ((args?: {
    id?: String | null
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }) &
    (MonitorPromiseChain & { execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null> })
  monitorVisitsByLocationId: ((args?: {
    monitorId?: String | null
  }) => {
    execute: (
      request: MonitorVisitsByLocationIdResultRequest,
      defaultValue?: (MonitorVisitsByLocationIdResult | null)[] | null,
    ) => Promise<(MonitorVisitsByLocationIdResult | null)[] | null>
  }) & {
    execute: (
      request: MonitorVisitsByLocationIdResultRequest,
      defaultValue?: (MonitorVisitsByLocationIdResult | null)[] | null,
    ) => Promise<(MonitorVisitsByLocationIdResult | null)[] | null>
  }
  checkinKiosks: ((args?: {
    where?: CheckinKioskFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => CheckinKiosksConnectionPromiseChain & {
    execute: (
      request: CheckinKiosksConnectionRequest,
      defaultValue?: CheckinKiosksConnection | null,
    ) => Promise<CheckinKiosksConnection | null>
  }) &
    (CheckinKiosksConnectionPromiseChain & {
      execute: (
        request: CheckinKiosksConnectionRequest,
        defaultValue?: CheckinKiosksConnection | null,
      ) => Promise<CheckinKiosksConnection | null>
    })
  checkinKiosk: ((args?: {
    where?: CheckinKioskFilterQueryInput | null
    sort?: String | null
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }) &
    (CheckinKioskPromiseChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
    })
  checkinKioskById: ((args?: {
    id?: String | null
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }) &
    (CheckinKioskPromiseChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
    })
  checkinVirtualKiosks: ((args?: {
    where?: CheckinVirtualKioskFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => CheckinVirtualKiosksConnectionPromiseChain & {
    execute: (
      request: CheckinVirtualKiosksConnectionRequest,
      defaultValue?: CheckinVirtualKiosksConnection | null,
    ) => Promise<CheckinVirtualKiosksConnection | null>
  }) &
    (CheckinVirtualKiosksConnectionPromiseChain & {
      execute: (
        request: CheckinVirtualKiosksConnectionRequest,
        defaultValue?: CheckinVirtualKiosksConnection | null,
      ) => Promise<CheckinVirtualKiosksConnection | null>
    })
  checkinVirtualKiosk: ((args?: {
    where?: CheckinVirtualKioskFilterQueryInput | null
    sort?: String | null
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskPromiseChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Promise<CheckinVirtualKiosk | null>
    })
  checkinVirtualKioskById: ((args?: {
    id?: String | null
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskPromiseChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Promise<CheckinVirtualKiosk | null>
    })
  virtualCheckinSessions: ((args?: {
    where?: VirtualCheckinSessionFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VirtualCheckinSessionsConnectionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionsConnectionRequest,
      defaultValue?: VirtualCheckinSessionsConnection | null,
    ) => Promise<VirtualCheckinSessionsConnection | null>
  }) &
    (VirtualCheckinSessionsConnectionPromiseChain & {
      execute: (
        request: VirtualCheckinSessionsConnectionRequest,
        defaultValue?: VirtualCheckinSessionsConnection | null,
      ) => Promise<VirtualCheckinSessionsConnection | null>
    })
  virtualCheckinSession: ((args?: {
    where?: VirtualCheckinSessionFilterQueryInput | null
    sort?: String | null
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionPromiseChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Promise<VirtualCheckinSession | null>
    })
  virtualCheckinSessionById: ((args?: {
    id?: String | null
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionPromiseChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Promise<VirtualCheckinSession | null>
    })
  memberships: ((args?: {
    where?: MembershipFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => MembershipsConnectionPromiseChain & {
    execute: (
      request: MembershipsConnectionRequest,
      defaultValue?: MembershipsConnection | null,
    ) => Promise<MembershipsConnection | null>
  }) &
    (MembershipsConnectionPromiseChain & {
      execute: (
        request: MembershipsConnectionRequest,
        defaultValue?: MembershipsConnection | null,
      ) => Promise<MembershipsConnection | null>
    })
  membership: ((args?: {
    where?: MembershipFilterQueryInput | null
    sort?: String | null
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }) &
    (MembershipPromiseChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
    })
  membershipById: ((args?: {
    id?: String | null
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }) &
    (MembershipPromiseChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
    })
  roles: ((args?: {
    where?: RoleFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => RolesConnectionPromiseChain & {
    execute: (request: RolesConnectionRequest, defaultValue?: RolesConnection | null) => Promise<RolesConnection | null>
  }) &
    (RolesConnectionPromiseChain & {
      execute: (request: RolesConnectionRequest, defaultValue?: RolesConnection | null) => Promise<RolesConnection | null>
    })
  role: ((args?: {
    where?: RoleFilterQueryInput | null
    sort?: String | null
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }) &
    (RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> })
  roleById: ((args?: {
    id?: String | null
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }) &
    (RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> })
  userRoles: ((args?: {
    where?: UserRoleFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => UserRolesConnectionPromiseChain & {
    execute: (
      request: UserRolesConnectionRequest,
      defaultValue?: UserRolesConnection | null,
    ) => Promise<UserRolesConnection | null>
  }) &
    (UserRolesConnectionPromiseChain & {
      execute: (
        request: UserRolesConnectionRequest,
        defaultValue?: UserRolesConnection | null,
      ) => Promise<UserRolesConnection | null>
    })
  userRole: ((args?: {
    where?: UserRoleFilterQueryInput | null
    sort?: String | null
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }) &
    (UserRolePromiseChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
    })
  userRoleById: ((args?: {
    id?: String | null
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }) &
    (UserRolePromiseChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
    })
  analyticsVisitsCount: (args: {
    params: AnalyticsVisitsCountPayloadQueryInput
  }) => AnalyticsVisitsCountResponsePromiseChain & {
    execute: (
      request: AnalyticsVisitsCountResponseRequest,
      defaultValue?: AnalyticsVisitsCountResponse | null,
    ) => Promise<AnalyticsVisitsCountResponse | null>
  }
  analyticsVisitsDuration: (args: {
    params: AnalyticsVisitsDurationPayloadQueryInput
  }) => AnalyticsVisitsDurationResponsePromiseChain & {
    execute: (
      request: AnalyticsVisitsDurationResponseRequest,
      defaultValue?: AnalyticsVisitsDurationResponse | null,
    ) => Promise<AnalyticsVisitsDurationResponse | null>
  }
  analyticsVisitsWaitingTime: (args: {
    params: AnalyticsVisitsWaitingTimePayloadQueryInput
  }) => AnalyticsVisitsWaitingTimeResponsePromiseChain & {
    execute: (
      request: AnalyticsVisitsWaitingTimeResponseRequest,
      defaultValue?: AnalyticsVisitsWaitingTimeResponse | null,
    ) => Promise<AnalyticsVisitsWaitingTimeResponse | null>
  }
  agents: ((args?: {
    where?: AgentFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => AgentsConnectionPromiseChain & {
    execute: (request: AgentsConnectionRequest, defaultValue?: AgentsConnection | null) => Promise<AgentsConnection | null>
  }) &
    (AgentsConnectionPromiseChain & {
      execute: (request: AgentsConnectionRequest, defaultValue?: AgentsConnection | null) => Promise<AgentsConnection | null>
    })
  agent: ((args?: {
    where?: AgentFilterQueryInput | null
    sort?: String | null
  }) => AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> }) &
    (AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> })
  agentById: ((args?: {
    id?: String | null
  }) => AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> }) &
    (AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> })
  printers: ((args?: {
    where?: PrinterFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => PrintersConnectionPromiseChain & {
    execute: (
      request: PrintersConnectionRequest,
      defaultValue?: PrintersConnection | null,
    ) => Promise<PrintersConnection | null>
  }) &
    (PrintersConnectionPromiseChain & {
      execute: (
        request: PrintersConnectionRequest,
        defaultValue?: PrintersConnection | null,
      ) => Promise<PrintersConnection | null>
    })
  printer: ((args?: {
    where?: PrinterFilterQueryInput | null
    sort?: String | null
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }) &
    (PrinterPromiseChain & { execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null> })
  printerById: ((args?: {
    id?: String | null
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }) &
    (PrinterPromiseChain & { execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null> })
  scans: ((args?: {
    where?: ScanFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => ScansConnectionPromiseChain & {
    execute: (request: ScansConnectionRequest, defaultValue?: ScansConnection | null) => Promise<ScansConnection | null>
  }) &
    (ScansConnectionPromiseChain & {
      execute: (request: ScansConnectionRequest, defaultValue?: ScansConnection | null) => Promise<ScansConnection | null>
    })
  scan: ((args?: {
    where?: ScanFilterQueryInput | null
    sort?: String | null
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }) &
    (ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> })
  scanById: ((args?: {
    id?: String | null
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }) &
    (ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> })
  notifications: ((args?: {
    where?: NotificationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => NotificationsConnectionPromiseChain & {
    execute: (
      request: NotificationsConnectionRequest,
      defaultValue?: NotificationsConnection | null,
    ) => Promise<NotificationsConnection | null>
  }) &
    (NotificationsConnectionPromiseChain & {
      execute: (
        request: NotificationsConnectionRequest,
        defaultValue?: NotificationsConnection | null,
      ) => Promise<NotificationsConnection | null>
    })
  ablyAuthenticate: AblyAuthorizeResponsePromiseChain & {
    execute: (
      request: AblyAuthorizeResponseRequest,
      defaultValue?: AblyAuthorizeResponse | null,
    ) => Promise<AblyAuthorizeResponse | null>
  }
  ablyEnabled: AblyEnabledResponsePromiseChain & {
    execute: (
      request: AblyEnabledResponseRequest,
      defaultValue?: AblyEnabledResponse | null,
    ) => Promise<AblyEnabledResponse | null>
  }
  triggers: ((args?: {
    where?: TriggerFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => TriggersConnectionPromiseChain & {
    execute: (
      request: TriggersConnectionRequest,
      defaultValue?: TriggersConnection | null,
    ) => Promise<TriggersConnection | null>
  }) &
    (TriggersConnectionPromiseChain & {
      execute: (
        request: TriggersConnectionRequest,
        defaultValue?: TriggersConnection | null,
      ) => Promise<TriggersConnection | null>
    })
  trigger: ((args?: {
    where?: TriggerFilterQueryInput | null
    sort?: String | null
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }) &
    (TriggerPromiseChain & { execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null> })
  triggerById: ((args?: {
    id?: String | null
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }) &
    (TriggerPromiseChain & { execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null> })
  templates: ((args?: {
    where?: TemplateFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => TemplatesConnectionPromiseChain & {
    execute: (
      request: TemplatesConnectionRequest,
      defaultValue?: TemplatesConnection | null,
    ) => Promise<TemplatesConnection | null>
  }) &
    (TemplatesConnectionPromiseChain & {
      execute: (
        request: TemplatesConnectionRequest,
        defaultValue?: TemplatesConnection | null,
      ) => Promise<TemplatesConnection | null>
    })
  template: ((args?: {
    where?: TemplateFilterQueryInput | null
    sort?: String | null
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }) &
    (TemplatePromiseChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
    })
  templateById: ((args?: {
    id?: String | null
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }) &
    (TemplatePromiseChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
    })
  getPreference: (args: {
    args: GetPreferenceArgsQueryInput
  }) => PreferencePromiseChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Promise<Preference | null>
  }
  deviceLogs: ((args?: {
    where?: DeviceLogFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => DeviceLogsConnectionPromiseChain & {
    execute: (
      request: DeviceLogsConnectionRequest,
      defaultValue?: DeviceLogsConnection | null,
    ) => Promise<DeviceLogsConnection | null>
  }) &
    (DeviceLogsConnectionPromiseChain & {
      execute: (
        request: DeviceLogsConnectionRequest,
        defaultValue?: DeviceLogsConnection | null,
      ) => Promise<DeviceLogsConnection | null>
    })
  deviceLog: ((args?: {
    where?: DeviceLogFilterQueryInput | null
    sort?: String | null
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }) &
    (DeviceLogPromiseChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
    })
  deviceLogById: ((args?: {
    id?: String | null
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }) &
    (DeviceLogPromiseChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
    })
}

export interface QueryObservableChain {
  locationTypes: ((args?: {
    where?: LocationTypeFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationTypesConnectionObservableChain & {
    execute: (
      request: LocationTypesConnectionRequest,
      defaultValue?: LocationTypesConnection | null,
    ) => Observable<LocationTypesConnection | null>
  }) &
    (LocationTypesConnectionObservableChain & {
      execute: (
        request: LocationTypesConnectionRequest,
        defaultValue?: LocationTypesConnection | null,
      ) => Observable<LocationTypesConnection | null>
    })
  locationType: ((args?: {
    where?: LocationTypeFilterQueryInput | null
    sort?: String | null
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }) &
    (LocationTypeObservableChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
    })
  locationTypeById: ((args?: {
    id?: String | null
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }) &
    (LocationTypeObservableChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
    })
  locations: ((args?: {
    where?: LocationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationsConnectionObservableChain & {
    execute: (
      request: LocationsConnectionRequest,
      defaultValue?: LocationsConnection | null,
    ) => Observable<LocationsConnection | null>
  }) &
    (LocationsConnectionObservableChain & {
      execute: (
        request: LocationsConnectionRequest,
        defaultValue?: LocationsConnection | null,
      ) => Observable<LocationsConnection | null>
    })
  location: ((args?: {
    where?: LocationFilterQueryInput | null
    sort?: String | null
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }) &
    (LocationObservableChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
    })
  locationById: ((args?: {
    id?: String | null
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }) &
    (LocationObservableChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
    })
  locationsForTransfer: ((args?: {
    where?: LocationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => LocationsConnectionObservableChain & {
    execute: (
      request: LocationsConnectionRequest,
      defaultValue?: LocationsConnection | null,
    ) => Observable<LocationsConnection | null>
  }) &
    (LocationsConnectionObservableChain & {
      execute: (
        request: LocationsConnectionRequest,
        defaultValue?: LocationsConnection | null,
      ) => Observable<LocationsConnection | null>
    })
  locationTree: ((args?: {
    id?: String | null
  }) => {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Observable<(LocationWithChildren | null)[] | null>
  }) & {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Observable<(LocationWithChildren | null)[] | null>
  }
  bookingsForVisitor: (args: {
    args: BookingsQueryQueryInput
  }) => {
    execute: (request: BookingRequest, defaultValue?: (Booking | null)[] | null) => Observable<(Booking | null)[] | null>
  }
  bookings: ((args?: {
    where?: BookingFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => BookingsConnectionObservableChain & {
    execute: (
      request: BookingsConnectionRequest,
      defaultValue?: BookingsConnection | null,
    ) => Observable<BookingsConnection | null>
  }) &
    (BookingsConnectionObservableChain & {
      execute: (
        request: BookingsConnectionRequest,
        defaultValue?: BookingsConnection | null,
      ) => Observable<BookingsConnection | null>
    })
  booking: ((args?: {
    where?: BookingFilterQueryInput | null
    sort?: String | null
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }) &
    (BookingObservableChain & {
      execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
    })
  bookingById: ((args?: {
    id?: String | null
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }) &
    (BookingObservableChain & {
      execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
    })
  visits: ((args?: {
    where?: VisitFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VisitsConnectionObservableChain & {
    execute: (
      request: VisitsConnectionRequest,
      defaultValue?: VisitsConnection | null,
    ) => Observable<VisitsConnection | null>
  }) &
    (VisitsConnectionObservableChain & {
      execute: (
        request: VisitsConnectionRequest,
        defaultValue?: VisitsConnection | null,
      ) => Observable<VisitsConnection | null>
    })
  visit: ((args?: {
    where?: VisitFilterQueryInput | null
    sort?: String | null
  }) => VisitObservableChain & {
    execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null>
  }) &
    (VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> })
  visitById: ((args?: {
    id?: String | null
  }) => VisitObservableChain & {
    execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null>
  }) &
    (VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> })
  startedVisitsByStartedById: ((args?: {
    where?: VisitFilterQueryInput | null
  }) => {
    execute: (
      request: StartedVisitsByStartedByIdResultRequest,
      defaultValue?: (StartedVisitsByStartedByIdResult | null)[] | null,
    ) => Observable<(StartedVisitsByStartedByIdResult | null)[] | null>
  }) & {
    execute: (
      request: StartedVisitsByStartedByIdResultRequest,
      defaultValue?: (StartedVisitsByStartedByIdResult | null)[] | null,
    ) => Observable<(StartedVisitsByStartedByIdResult | null)[] | null>
  }
  speak: (args: {
    ssml: String
    engine?: engine | null
  }) => SpeakResultObservableChain & {
    execute: (request: SpeakResultRequest, defaultValue?: SpeakResult | null) => Observable<SpeakResult | null>
  }
  visitTags: { execute: (request: TagRequest, defaultValue?: (Tag | null)[] | null) => Observable<(Tag | null)[] | null> }
  queueCounts: ((args?: {
    where?: FetchQueueCountsCriteriaQueryInput | null
    includeChildren?: Boolean | null
    sort?: String | null
  }) => {
    execute: (
      request: QueueCountsRequest,
      defaultValue?: (QueueCounts | null)[] | null,
    ) => Observable<(QueueCounts | null)[] | null>
  }) & {
    execute: (
      request: QueueCountsRequest,
      defaultValue?: (QueueCounts | null)[] | null,
    ) => Observable<(QueueCounts | null)[] | null>
  }
  visitors: ((args?: {
    where?: VisitorFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VisitorsConnectionObservableChain & {
    execute: (
      request: VisitorsConnectionRequest,
      defaultValue?: VisitorsConnection | null,
    ) => Observable<VisitorsConnection | null>
  }) &
    (VisitorsConnectionObservableChain & {
      execute: (
        request: VisitorsConnectionRequest,
        defaultValue?: VisitorsConnection | null,
      ) => Observable<VisitorsConnection | null>
    })
  visitor: ((args?: {
    where?: VisitorFilterQueryInput | null
    sort?: String | null
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }) &
    (VisitorObservableChain & {
      execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
    })
  visitorById: ((args?: {
    id?: String | null
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }) &
    (VisitorObservableChain & {
      execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
    })
  journeyForVisitor: JourneyForVisitorResponseObservableChain & {
    execute: (
      request: JourneyForVisitorResponseRequest,
      defaultValue?: JourneyForVisitorResponse | null,
    ) => Observable<JourneyForVisitorResponse | null>
  }
  users: ((args?: {
    where?: UserFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => UsersConnectionObservableChain & {
    execute: (request: UsersConnectionRequest, defaultValue?: UsersConnection | null) => Observable<UsersConnection | null>
  }) &
    (UsersConnectionObservableChain & {
      execute: (request: UsersConnectionRequest, defaultValue?: UsersConnection | null) => Observable<UsersConnection | null>
    })
  user: ((args?: {
    where?: UserFilterQueryInput | null
    sort?: String | null
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }) &
    (UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> })
  userById: ((args?: {
    id?: String | null
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }) &
    (UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> })
  identity: { execute: (request: IdentityRequest, defaultValue?: Identity | null) => Observable<Identity | null> }
  signageSessionTokens: (args: {
    consumerId: String
    consumerType: String
    limit?: Float | null
    skip?: Float | null
  }) => { execute: (request: TokenRequest, defaultValue?: (Token | null)[] | null) => Observable<(Token | null)[] | null> }
  checkTokenValidity: (args: {
    token: String
  }) => TokenObservableChain & { execute: (request: TokenRequest, defaultValue?: Token | null) => Observable<Token | null> }
  organizations: ((args?: {
    where?: OrganizationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => OrganizationsConnectionObservableChain & {
    execute: (
      request: OrganizationsConnectionRequest,
      defaultValue?: OrganizationsConnection | null,
    ) => Observable<OrganizationsConnection | null>
  }) &
    (OrganizationsConnectionObservableChain & {
      execute: (
        request: OrganizationsConnectionRequest,
        defaultValue?: OrganizationsConnection | null,
      ) => Observable<OrganizationsConnection | null>
    })
  organization: ((args?: {
    where?: OrganizationFilterQueryInput | null
    sort?: String | null
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }) &
    (OrganizationObservableChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
    })
  organizationById: ((args?: {
    id?: String | null
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }) &
    (OrganizationObservableChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
    })
  monitors: ((args?: {
    where?: MonitorFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => MonitorsConnectionObservableChain & {
    execute: (
      request: MonitorsConnectionRequest,
      defaultValue?: MonitorsConnection | null,
    ) => Observable<MonitorsConnection | null>
  }) &
    (MonitorsConnectionObservableChain & {
      execute: (
        request: MonitorsConnectionRequest,
        defaultValue?: MonitorsConnection | null,
      ) => Observable<MonitorsConnection | null>
    })
  monitor: ((args?: {
    where?: MonitorFilterQueryInput | null
    sort?: String | null
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }) &
    (MonitorObservableChain & {
      execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
    })
  monitorById: ((args?: {
    id?: String | null
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }) &
    (MonitorObservableChain & {
      execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
    })
  monitorVisitsByLocationId: ((args?: {
    monitorId?: String | null
  }) => {
    execute: (
      request: MonitorVisitsByLocationIdResultRequest,
      defaultValue?: (MonitorVisitsByLocationIdResult | null)[] | null,
    ) => Observable<(MonitorVisitsByLocationIdResult | null)[] | null>
  }) & {
    execute: (
      request: MonitorVisitsByLocationIdResultRequest,
      defaultValue?: (MonitorVisitsByLocationIdResult | null)[] | null,
    ) => Observable<(MonitorVisitsByLocationIdResult | null)[] | null>
  }
  checkinKiosks: ((args?: {
    where?: CheckinKioskFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => CheckinKiosksConnectionObservableChain & {
    execute: (
      request: CheckinKiosksConnectionRequest,
      defaultValue?: CheckinKiosksConnection | null,
    ) => Observable<CheckinKiosksConnection | null>
  }) &
    (CheckinKiosksConnectionObservableChain & {
      execute: (
        request: CheckinKiosksConnectionRequest,
        defaultValue?: CheckinKiosksConnection | null,
      ) => Observable<CheckinKiosksConnection | null>
    })
  checkinKiosk: ((args?: {
    where?: CheckinKioskFilterQueryInput | null
    sort?: String | null
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }) &
    (CheckinKioskObservableChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
    })
  checkinKioskById: ((args?: {
    id?: String | null
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }) &
    (CheckinKioskObservableChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
    })
  checkinVirtualKiosks: ((args?: {
    where?: CheckinVirtualKioskFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => CheckinVirtualKiosksConnectionObservableChain & {
    execute: (
      request: CheckinVirtualKiosksConnectionRequest,
      defaultValue?: CheckinVirtualKiosksConnection | null,
    ) => Observable<CheckinVirtualKiosksConnection | null>
  }) &
    (CheckinVirtualKiosksConnectionObservableChain & {
      execute: (
        request: CheckinVirtualKiosksConnectionRequest,
        defaultValue?: CheckinVirtualKiosksConnection | null,
      ) => Observable<CheckinVirtualKiosksConnection | null>
    })
  checkinVirtualKiosk: ((args?: {
    where?: CheckinVirtualKioskFilterQueryInput | null
    sort?: String | null
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskObservableChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Observable<CheckinVirtualKiosk | null>
    })
  checkinVirtualKioskById: ((args?: {
    id?: String | null
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskObservableChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Observable<CheckinVirtualKiosk | null>
    })
  virtualCheckinSessions: ((args?: {
    where?: VirtualCheckinSessionFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => VirtualCheckinSessionsConnectionObservableChain & {
    execute: (
      request: VirtualCheckinSessionsConnectionRequest,
      defaultValue?: VirtualCheckinSessionsConnection | null,
    ) => Observable<VirtualCheckinSessionsConnection | null>
  }) &
    (VirtualCheckinSessionsConnectionObservableChain & {
      execute: (
        request: VirtualCheckinSessionsConnectionRequest,
        defaultValue?: VirtualCheckinSessionsConnection | null,
      ) => Observable<VirtualCheckinSessionsConnection | null>
    })
  virtualCheckinSession: ((args?: {
    where?: VirtualCheckinSessionFilterQueryInput | null
    sort?: String | null
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionObservableChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Observable<VirtualCheckinSession | null>
    })
  virtualCheckinSessionById: ((args?: {
    id?: String | null
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionObservableChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Observable<VirtualCheckinSession | null>
    })
  memberships: ((args?: {
    where?: MembershipFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => MembershipsConnectionObservableChain & {
    execute: (
      request: MembershipsConnectionRequest,
      defaultValue?: MembershipsConnection | null,
    ) => Observable<MembershipsConnection | null>
  }) &
    (MembershipsConnectionObservableChain & {
      execute: (
        request: MembershipsConnectionRequest,
        defaultValue?: MembershipsConnection | null,
      ) => Observable<MembershipsConnection | null>
    })
  membership: ((args?: {
    where?: MembershipFilterQueryInput | null
    sort?: String | null
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }) &
    (MembershipObservableChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
    })
  membershipById: ((args?: {
    id?: String | null
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }) &
    (MembershipObservableChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
    })
  roles: ((args?: {
    where?: RoleFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => RolesConnectionObservableChain & {
    execute: (request: RolesConnectionRequest, defaultValue?: RolesConnection | null) => Observable<RolesConnection | null>
  }) &
    (RolesConnectionObservableChain & {
      execute: (request: RolesConnectionRequest, defaultValue?: RolesConnection | null) => Observable<RolesConnection | null>
    })
  role: ((args?: {
    where?: RoleFilterQueryInput | null
    sort?: String | null
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }) &
    (RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> })
  roleById: ((args?: {
    id?: String | null
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }) &
    (RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> })
  userRoles: ((args?: {
    where?: UserRoleFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => UserRolesConnectionObservableChain & {
    execute: (
      request: UserRolesConnectionRequest,
      defaultValue?: UserRolesConnection | null,
    ) => Observable<UserRolesConnection | null>
  }) &
    (UserRolesConnectionObservableChain & {
      execute: (
        request: UserRolesConnectionRequest,
        defaultValue?: UserRolesConnection | null,
      ) => Observable<UserRolesConnection | null>
    })
  userRole: ((args?: {
    where?: UserRoleFilterQueryInput | null
    sort?: String | null
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }) &
    (UserRoleObservableChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
    })
  userRoleById: ((args?: {
    id?: String | null
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }) &
    (UserRoleObservableChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
    })
  analyticsVisitsCount: (args: {
    params: AnalyticsVisitsCountPayloadQueryInput
  }) => AnalyticsVisitsCountResponseObservableChain & {
    execute: (
      request: AnalyticsVisitsCountResponseRequest,
      defaultValue?: AnalyticsVisitsCountResponse | null,
    ) => Observable<AnalyticsVisitsCountResponse | null>
  }
  analyticsVisitsDuration: (args: {
    params: AnalyticsVisitsDurationPayloadQueryInput
  }) => AnalyticsVisitsDurationResponseObservableChain & {
    execute: (
      request: AnalyticsVisitsDurationResponseRequest,
      defaultValue?: AnalyticsVisitsDurationResponse | null,
    ) => Observable<AnalyticsVisitsDurationResponse | null>
  }
  analyticsVisitsWaitingTime: (args: {
    params: AnalyticsVisitsWaitingTimePayloadQueryInput
  }) => AnalyticsVisitsWaitingTimeResponseObservableChain & {
    execute: (
      request: AnalyticsVisitsWaitingTimeResponseRequest,
      defaultValue?: AnalyticsVisitsWaitingTimeResponse | null,
    ) => Observable<AnalyticsVisitsWaitingTimeResponse | null>
  }
  agents: ((args?: {
    where?: AgentFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => AgentsConnectionObservableChain & {
    execute: (
      request: AgentsConnectionRequest,
      defaultValue?: AgentsConnection | null,
    ) => Observable<AgentsConnection | null>
  }) &
    (AgentsConnectionObservableChain & {
      execute: (
        request: AgentsConnectionRequest,
        defaultValue?: AgentsConnection | null,
      ) => Observable<AgentsConnection | null>
    })
  agent: ((args?: {
    where?: AgentFilterQueryInput | null
    sort?: String | null
  }) => AgentObservableChain & {
    execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null>
  }) &
    (AgentObservableChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null> })
  agentById: ((args?: {
    id?: String | null
  }) => AgentObservableChain & {
    execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null>
  }) &
    (AgentObservableChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null> })
  printers: ((args?: {
    where?: PrinterFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => PrintersConnectionObservableChain & {
    execute: (
      request: PrintersConnectionRequest,
      defaultValue?: PrintersConnection | null,
    ) => Observable<PrintersConnection | null>
  }) &
    (PrintersConnectionObservableChain & {
      execute: (
        request: PrintersConnectionRequest,
        defaultValue?: PrintersConnection | null,
      ) => Observable<PrintersConnection | null>
    })
  printer: ((args?: {
    where?: PrinterFilterQueryInput | null
    sort?: String | null
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }) &
    (PrinterObservableChain & {
      execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
    })
  printerById: ((args?: {
    id?: String | null
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }) &
    (PrinterObservableChain & {
      execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
    })
  scans: ((args?: {
    where?: ScanFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => ScansConnectionObservableChain & {
    execute: (request: ScansConnectionRequest, defaultValue?: ScansConnection | null) => Observable<ScansConnection | null>
  }) &
    (ScansConnectionObservableChain & {
      execute: (request: ScansConnectionRequest, defaultValue?: ScansConnection | null) => Observable<ScansConnection | null>
    })
  scan: ((args?: {
    where?: ScanFilterQueryInput | null
    sort?: String | null
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }) &
    (ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> })
  scanById: ((args?: {
    id?: String | null
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }) &
    (ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> })
  notifications: ((args?: {
    where?: NotificationFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => NotificationsConnectionObservableChain & {
    execute: (
      request: NotificationsConnectionRequest,
      defaultValue?: NotificationsConnection | null,
    ) => Observable<NotificationsConnection | null>
  }) &
    (NotificationsConnectionObservableChain & {
      execute: (
        request: NotificationsConnectionRequest,
        defaultValue?: NotificationsConnection | null,
      ) => Observable<NotificationsConnection | null>
    })
  ablyAuthenticate: AblyAuthorizeResponseObservableChain & {
    execute: (
      request: AblyAuthorizeResponseRequest,
      defaultValue?: AblyAuthorizeResponse | null,
    ) => Observable<AblyAuthorizeResponse | null>
  }
  ablyEnabled: AblyEnabledResponseObservableChain & {
    execute: (
      request: AblyEnabledResponseRequest,
      defaultValue?: AblyEnabledResponse | null,
    ) => Observable<AblyEnabledResponse | null>
  }
  triggers: ((args?: {
    where?: TriggerFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => TriggersConnectionObservableChain & {
    execute: (
      request: TriggersConnectionRequest,
      defaultValue?: TriggersConnection | null,
    ) => Observable<TriggersConnection | null>
  }) &
    (TriggersConnectionObservableChain & {
      execute: (
        request: TriggersConnectionRequest,
        defaultValue?: TriggersConnection | null,
      ) => Observable<TriggersConnection | null>
    })
  trigger: ((args?: {
    where?: TriggerFilterQueryInput | null
    sort?: String | null
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }) &
    (TriggerObservableChain & {
      execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
    })
  triggerById: ((args?: {
    id?: String | null
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }) &
    (TriggerObservableChain & {
      execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
    })
  templates: ((args?: {
    where?: TemplateFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => TemplatesConnectionObservableChain & {
    execute: (
      request: TemplatesConnectionRequest,
      defaultValue?: TemplatesConnection | null,
    ) => Observable<TemplatesConnection | null>
  }) &
    (TemplatesConnectionObservableChain & {
      execute: (
        request: TemplatesConnectionRequest,
        defaultValue?: TemplatesConnection | null,
      ) => Observable<TemplatesConnection | null>
    })
  template: ((args?: {
    where?: TemplateFilterQueryInput | null
    sort?: String | null
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }) &
    (TemplateObservableChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
    })
  templateById: ((args?: {
    id?: String | null
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }) &
    (TemplateObservableChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
    })
  getPreference: (args: {
    args: GetPreferenceArgsQueryInput
  }) => PreferenceObservableChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Observable<Preference | null>
  }
  deviceLogs: ((args?: {
    where?: DeviceLogFilterQueryInput | null
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => DeviceLogsConnectionObservableChain & {
    execute: (
      request: DeviceLogsConnectionRequest,
      defaultValue?: DeviceLogsConnection | null,
    ) => Observable<DeviceLogsConnection | null>
  }) &
    (DeviceLogsConnectionObservableChain & {
      execute: (
        request: DeviceLogsConnectionRequest,
        defaultValue?: DeviceLogsConnection | null,
      ) => Observable<DeviceLogsConnection | null>
    })
  deviceLog: ((args?: {
    where?: DeviceLogFilterQueryInput | null
    sort?: String | null
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }) &
    (DeviceLogObservableChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
    })
  deviceLogById: ((args?: {
    id?: String | null
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }) &
    (DeviceLogObservableChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
    })
}

export interface LocationTypesConnectionPromiseChain {
  items: {
    execute: (
      request: LocationTypeRequest,
      defaultValue?: (LocationType | null)[] | null,
    ) => Promise<(LocationType | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface LocationTypesConnectionObservableChain {
  items: {
    execute: (
      request: LocationTypeRequest,
      defaultValue?: (LocationType | null)[] | null,
    ) => Observable<(LocationType | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface LocationTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  appearance: LocationTypeAppearancePromiseChain & {
    execute: (
      request: LocationTypeAppearanceRequest,
      defaultValue?: LocationTypeAppearance | null,
    ) => Promise<LocationTypeAppearance | null>
  }
}

export interface LocationTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  appearance: LocationTypeAppearanceObservableChain & {
    execute: (
      request: LocationTypeAppearanceRequest,
      defaultValue?: LocationTypeAppearance | null,
    ) => Observable<LocationTypeAppearance | null>
  }
}

export interface LocationTypeAppearancePromiseChain {
  location: NameAndPluralNamePromiseChain & {
    execute: (
      request: NameAndPluralNameRequest,
      defaultValue?: NameAndPluralName | null,
    ) => Promise<NameAndPluralName | null>
  }
  visitor: NameAndPluralNamePromiseChain & {
    execute: (
      request: NameAndPluralNameRequest,
      defaultValue?: NameAndPluralName | null,
    ) => Promise<NameAndPluralName | null>
  }
}

export interface LocationTypeAppearanceObservableChain {
  location: NameAndPluralNameObservableChain & {
    execute: (
      request: NameAndPluralNameRequest,
      defaultValue?: NameAndPluralName | null,
    ) => Observable<NameAndPluralName | null>
  }
  visitor: NameAndPluralNameObservableChain & {
    execute: (
      request: NameAndPluralNameRequest,
      defaultValue?: NameAndPluralName | null,
    ) => Observable<NameAndPluralName | null>
  }
}

export interface NameAndPluralNamePromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  pluralName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface NameAndPluralNameObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  pluralName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface LocationsConnectionPromiseChain {
  items: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Promise<(Location | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface LocationsConnectionObservableChain {
  items: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Observable<(Location | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface LocationPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationTypeId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  parentLocationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  queue: QueueSettingsPromiseChain & {
    execute: (request: QueueSettingsRequest, defaultValue?: QueueSettings | null) => Promise<QueueSettings | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  parentLocation: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  locationType: LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }
}

export interface LocationObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationTypeId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  parentLocationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  queue: QueueSettingsObservableChain & {
    execute: (request: QueueSettingsRequest, defaultValue?: QueueSettings | null) => Observable<QueueSettings | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  parentLocation: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  locationType: LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }
}

export interface QueueSettingsPromiseChain {
  hasQueue: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  visiting: VisitSettingsPromiseChain & {
    execute: (request: VisitSettingsRequest, defaultValue?: VisitSettings | null) => Promise<VisitSettings | null>
  }
  waiting: VisitSettingsPromiseChain & {
    execute: (request: VisitSettingsRequest, defaultValue?: VisitSettings | null) => Promise<VisitSettings | null>
  }
  onCallMarkPreviousVisitAsCompleted: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null>
  }
}

export interface QueueSettingsObservableChain {
  hasQueue: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  visiting: VisitSettingsObservableChain & {
    execute: (request: VisitSettingsRequest, defaultValue?: VisitSettings | null) => Observable<VisitSettings | null>
  }
  waiting: VisitSettingsObservableChain & {
    execute: (request: VisitSettingsRequest, defaultValue?: VisitSettings | null) => Observable<VisitSettings | null>
  }
  onCallMarkPreviousVisitAsCompleted: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
}

export interface VisitSettingsPromiseChain {
  maxDuration: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface VisitSettingsObservableChain {
  maxDuration: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface LocationWithChildrenPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationTypeId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  parentLocationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  queue: QueueSettingsPromiseChain & {
    execute: (request: QueueSettingsRequest, defaultValue?: QueueSettings | null) => Promise<QueueSettings | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  children: {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Promise<(LocationWithChildren | null)[] | null>
  }
  parentLocation: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  locationType: LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }
}

export interface LocationWithChildrenObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationTypeId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  parentLocationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  queue: QueueSettingsObservableChain & {
    execute: (request: QueueSettingsRequest, defaultValue?: QueueSettings | null) => Observable<QueueSettings | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  children: {
    execute: (
      request: LocationWithChildrenRequest,
      defaultValue?: (LocationWithChildren | null)[] | null,
    ) => Observable<(LocationWithChildren | null)[] | null>
  }
  parentLocation: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  locationType: LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }
}

export interface BookingPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  visitorId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  time: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  prescriptionCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  aspVisitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  externalId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  visitor: VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  visit: VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
}

export interface BookingObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  visitorId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  time: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  prescriptionCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  aspVisitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  externalId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  visitor: VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  visit: VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
}

export interface VisitorPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  firstname: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  lastname: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  fiscalCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  sex: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  healthCardNumber: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  birth: BirthTypePromiseChain & {
    execute: (request: BirthTypeRequest, defaultValue?: BirthType | null) => Promise<BirthType | null>
  }
  address: AddressPromiseChain & {
    execute: (request: AddressRequest, defaultValue?: Address | null) => Promise<Address | null>
  }
  contacts: {
    execute: (
      request: VisitorContactsRequest,
      defaultValue?: (VisitorContacts | null)[] | null,
    ) => Promise<(VisitorContacts | null)[] | null>
  }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  fullName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface VisitorObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  firstname: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  lastname: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  fiscalCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  sex: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  healthCardNumber: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  birth: BirthTypeObservableChain & {
    execute: (request: BirthTypeRequest, defaultValue?: BirthType | null) => Observable<BirthType | null>
  }
  address: AddressObservableChain & {
    execute: (request: AddressRequest, defaultValue?: Address | null) => Observable<Address | null>
  }
  contacts: {
    execute: (
      request: VisitorContactsRequest,
      defaultValue?: (VisitorContacts | null)[] | null,
    ) => Observable<(VisitorContacts | null)[] | null>
  }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  extraData: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  fullName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface BirthTypePromiseChain {
  city: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  province: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface BirthTypeObservableChain {
  city: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  province: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface AddressPromiseChain {
  line1: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  streetNumber: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  city: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  province: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AddressObservableChain {
  line1: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  streetNumber: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  city: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  province: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface VisitorContactsPromiseChain {
  number: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface VisitorContactsObservableChain {
  number: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface VisitPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  ticketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  visitorId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  checkinVirtualKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  endedReason: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  endedNote: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  duration: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  bookingId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  endedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  ticketPrinted: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  selfCheckin: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  canVisit: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  scanIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  tags: { execute: (request: TagRequest, defaultValue?: (Tag | null)[] | null) => Promise<(Tag | null)[] | null> }
  startedBy: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
  visitor: VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  scan: ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }
  scans: { execute: (request: ScanRequest, defaultValue?: (Scan | null)[] | null) => Promise<(Scan | null)[] | null> }
}

export interface VisitObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  ticketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  visitorId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  checkinVirtualKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  endedReason: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  endedNote: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  duration: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  bookingId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  endedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  ticketPrinted: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  selfCheckin: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  canVisit: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  scanIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  attachmentIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  tags: { execute: (request: TagRequest, defaultValue?: (Tag | null)[] | null) => Observable<(Tag | null)[] | null> }
  startedBy: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
  visitor: VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  scan: ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }
  scans: { execute: (request: ScanRequest, defaultValue?: (Scan | null)[] | null) => Observable<(Scan | null)[] | null> }
}

export interface TagPromiseChain {
  text: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface TagObservableChain {
  text: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  color: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface UserPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  email: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  firstname: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  lastname: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  contacts: {
    execute: (
      request: UserContactsRequest,
      defaultValue?: (UserContacts | null)[] | null,
    ) => Promise<(UserContacts | null)[] | null>
  }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  roles: { execute: (request: RoleRequest, defaultValue?: (Role | null)[] | null) => Promise<(Role | null)[] | null> }
  memberships: {
    execute: (
      request: MembershipRequest,
      defaultValue?: (Membership | null)[] | null,
    ) => Promise<(Membership | null)[] | null>
  }
  fullName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface UserObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  email: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  firstname: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  lastname: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  contacts: {
    execute: (
      request: UserContactsRequest,
      defaultValue?: (UserContacts | null)[] | null,
    ) => Observable<(UserContacts | null)[] | null>
  }
  shortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  roles: { execute: (request: RoleRequest, defaultValue?: (Role | null)[] | null) => Observable<(Role | null)[] | null> }
  memberships: {
    execute: (
      request: MembershipRequest,
      defaultValue?: (Membership | null)[] | null,
    ) => Observable<(Membership | null)[] | null>
  }
  fullName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface UserContactsPromiseChain {
  number: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface UserContactsObservableChain {
  number: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface RolePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  policies: {
    execute: (request: PolicyRequest, defaultValue?: (Policy | null)[] | null) => Promise<(Policy | null)[] | null>
  }
}

export interface RoleObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  policies: {
    execute: (request: PolicyRequest, defaultValue?: (Policy | null)[] | null) => Observable<(Policy | null)[] | null>
  }
}

export interface PolicyPromiseChain {
  effect: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  actions: { execute: (request?: boolean | number, defaultValue?: String[]) => Promise<String[]> }
  resources: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
}

export interface PolicyObservableChain {
  effect: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  actions: { execute: (request?: boolean | number, defaultValue?: String[]) => Observable<String[]> }
  resources: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
}

export interface MembershipPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organization: OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }
  user: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
}

export interface MembershipObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organization: OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }
  user: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
}

export interface OrganizationPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  timezone: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  scheduledResetTime: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface OrganizationObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  timezone: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  scheduledResetTime: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface ScanPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  deviceId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  wearMask: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface ScanObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  deviceId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  wearMask: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface BookingsConnectionPromiseChain {
  items: {
    execute: (request: BookingRequest, defaultValue?: (Booking | null)[] | null) => Promise<(Booking | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface BookingsConnectionObservableChain {
  items: {
    execute: (request: BookingRequest, defaultValue?: (Booking | null)[] | null) => Observable<(Booking | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface VisitsConnectionPromiseChain {
  items: { execute: (request: VisitRequest, defaultValue?: (Visit | null)[] | null) => Promise<(Visit | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface VisitsConnectionObservableChain {
  items: { execute: (request: VisitRequest, defaultValue?: (Visit | null)[] | null) => Observable<(Visit | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface StartedVisitsByStartedByIdResultPromiseChain {
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  visit: VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  startedBy: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
}

export interface StartedVisitsByStartedByIdResultObservableChain {
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  visit: VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  startedBy: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
}

export interface SpeakResultPromiseChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface SpeakResultObservableChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface QueueCountsPromiseChain {
  counts: CountsPromiseChain & { execute: (request: CountsRequest, defaultValue?: Counts | null) => Promise<Counts | null> }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  parentLocation: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
}

export interface QueueCountsObservableChain {
  counts: CountsObservableChain & {
    execute: (request: CountsRequest, defaultValue?: Counts | null) => Observable<Counts | null>
  }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  parentLocation: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
}

export interface CountsPromiseChain {
  waiting: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  started: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  ended: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  expected: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface CountsObservableChain {
  waiting: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  started: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  ended: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  expected: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface VisitorsConnectionPromiseChain {
  items: {
    execute: (request: VisitorRequest, defaultValue?: (Visitor | null)[] | null) => Promise<(Visitor | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface VisitorsConnectionObservableChain {
  items: {
    execute: (request: VisitorRequest, defaultValue?: (Visitor | null)[] | null) => Observable<(Visitor | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface JourneyForVisitorResponsePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationShortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  ticketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  currentTicketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface JourneyForVisitorResponseObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationShortName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  ticketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  currentTicketCode: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface UsersConnectionPromiseChain {
  items: { execute: (request: UserRequest, defaultValue?: (User | null)[] | null) => Promise<(User | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface UsersConnectionObservableChain {
  items: { execute: (request: UserRequest, defaultValue?: (User | null)[] | null) => Observable<(User | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface MonitorPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  notification: MonitorNotificationPromiseChain & {
    execute: (
      request: MonitorNotificationRequest,
      defaultValue?: MonitorNotification | null,
    ) => Promise<MonitorNotification | null>
  }
  enableDebugMode: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  fontSizeMultiplier: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  interface: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Promise<(Location | null)[] | null>
  }
}

export interface MonitorObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  notification: MonitorNotificationObservableChain & {
    execute: (
      request: MonitorNotificationRequest,
      defaultValue?: MonitorNotification | null,
    ) => Observable<MonitorNotification | null>
  }
  enableDebugMode: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  fontSizeMultiplier: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  interface: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Observable<(Location | null)[] | null>
  }
}

export interface MonitorNotificationPromiseChain {
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface MonitorNotificationObservableChain {
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface CheckinKioskPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationConfigs: {
    execute: (
      request: LocationConfigRequest,
      defaultValue?: (LocationConfig | null)[] | null,
    ) => Promise<(LocationConfig | null)[] | null>
  }
  addUnknownVisitorsToLocationId: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  uiTemplateId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  interface: CheckinKioskUIPromiseChain & {
    execute: (request: CheckinKioskUIRequest, defaultValue?: CheckinKioskUI | null) => Promise<CheckinKioskUI | null>
  }
  keyboard: KeyboardPromiseChain & {
    execute: (request: KeyboardRequest, defaultValue?: Keyboard | null) => Promise<Keyboard | null>
  }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Promise<(Location | null)[] | null>
  }
}

export interface CheckinKioskObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationConfigs: {
    execute: (
      request: LocationConfigRequest,
      defaultValue?: (LocationConfig | null)[] | null,
    ) => Observable<(LocationConfig | null)[] | null>
  }
  addUnknownVisitorsToLocationId: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  uiTemplateId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  interface: CheckinKioskUIObservableChain & {
    execute: (request: CheckinKioskUIRequest, defaultValue?: CheckinKioskUI | null) => Observable<CheckinKioskUI | null>
  }
  keyboard: KeyboardObservableChain & {
    execute: (request: KeyboardRequest, defaultValue?: Keyboard | null) => Observable<Keyboard | null>
  }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Observable<(Location | null)[] | null>
  }
}

export interface LocationConfigPromiseChain {
  locationId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface LocationConfigObservableChain {
  locationId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CheckinKioskUIPromiseChain {
  steps: { execute: (request: StepRequest, defaultValue?: (Step | null)[] | null) => Promise<(Step | null)[] | null> }
  macros: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  theme: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  debugMode: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface CheckinKioskUIObservableChain {
  steps: { execute: (request: StepRequest, defaultValue?: (Step | null)[] | null) => Observable<(Step | null)[] | null> }
  macros: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  theme: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  debugMode: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface StepPromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  onMount: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  state: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  content: { execute: (request?: boolean | number, defaultValue?: JSON) => Promise<JSON> }
}

export interface StepObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  onMount: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  state: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  content: { execute: (request?: boolean | number, defaultValue?: JSON) => Observable<JSON> }
}

export interface KeyboardPromiseChain {
  active: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface KeyboardObservableChain {
  active: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface AgentPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  queue: AgentQueuePromiseChain & {
    execute: (request: AgentQueueRequest, defaultValue?: AgentQueue | null) => Promise<AgentQueue | null>
  }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  ticketPrintTemplateId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  activationToken: AgentTokenPromiseChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Promise<AgentToken | null>
  }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  printers: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Promise<(Printer | null)[] | null>
  }
  healthChecks: ((args?: {
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => AgentHealthChecksConnectionPromiseChain & {
    execute: (
      request: AgentHealthChecksConnectionRequest,
      defaultValue?: AgentHealthChecksConnection | null,
    ) => Promise<AgentHealthChecksConnection | null>
  }) &
    (AgentHealthChecksConnectionPromiseChain & {
      execute: (
        request: AgentHealthChecksConnectionRequest,
        defaultValue?: AgentHealthChecksConnection | null,
      ) => Promise<AgentHealthChecksConnection | null>
    })
}

export interface AgentObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  queue: AgentQueueObservableChain & {
    execute: (request: AgentQueueRequest, defaultValue?: AgentQueue | null) => Observable<AgentQueue | null>
  }
  checkinKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  ticketPrintTemplateId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  activationToken: AgentTokenObservableChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Observable<AgentToken | null>
  }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  printers: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Observable<(Printer | null)[] | null>
  }
  healthChecks: ((args?: {
    limit?: Float | null
    skip?: Float | null
    sort?: String | null
  }) => AgentHealthChecksConnectionObservableChain & {
    execute: (
      request: AgentHealthChecksConnectionRequest,
      defaultValue?: AgentHealthChecksConnection | null,
    ) => Observable<AgentHealthChecksConnection | null>
  }) &
    (AgentHealthChecksConnectionObservableChain & {
      execute: (
        request: AgentHealthChecksConnectionRequest,
        defaultValue?: AgentHealthChecksConnection | null,
      ) => Observable<AgentHealthChecksConnection | null>
    })
}

export interface AgentQueuePromiseChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  accessKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  secretKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AgentQueueObservableChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  accessKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  secretKey: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AgentTokenPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  extra: ExtraPromiseChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Promise<Extra | null> }
  expireAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastUsed: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  consumer: {
    execute: (request: TokenConsumerRequest, defaultValue?: TokenConsumer | null) => Promise<TokenConsumer | null>
  }
}

export interface AgentTokenObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  extra: ExtraObservableChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Observable<Extra | null> }
  expireAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastUsed: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  consumer: {
    execute: (request: TokenConsumerRequest, defaultValue?: TokenConsumer | null) => Observable<TokenConsumer | null>
  }
}

export interface ExtraPromiseChain {
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface ExtraObservableChain {
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface CheckinVirtualKioskPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationConfigs: {
    execute: (
      request: LocationConfigRequest,
      defaultValue?: (LocationConfig | null)[] | null,
    ) => Promise<(LocationConfig | null)[] | null>
  }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Promise<(Location | null)[] | null>
  }
  checkinURLs: {
    execute: (
      request: VirtualCheckinURLRequest,
      defaultValue?: (VirtualCheckinURL | null)[] | null,
    ) => Promise<(VirtualCheckinURL | null)[] | null>
  }
}

export interface CheckinVirtualKioskObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationConfigs: {
    execute: (
      request: LocationConfigRequest,
      defaultValue?: (LocationConfig | null)[] | null,
    ) => Observable<(LocationConfig | null)[] | null>
  }
  locations: {
    execute: (request: LocationRequest, defaultValue?: (Location | null)[] | null) => Observable<(Location | null)[] | null>
  }
  checkinURLs: {
    execute: (
      request: VirtualCheckinURLRequest,
      defaultValue?: (VirtualCheckinURL | null)[] | null,
    ) => Observable<(VirtualCheckinURL | null)[] | null>
  }
}

export interface VirtualCheckinURLPromiseChain {
  token: TokenPromiseChain & { execute: (request: TokenRequest, defaultValue?: Token | null) => Promise<Token | null> }
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface VirtualCheckinURLObservableChain {
  token: TokenObservableChain & { execute: (request: TokenRequest, defaultValue?: Token | null) => Observable<Token | null> }
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface TokenPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  extra: ExtraPromiseChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Promise<Extra | null> }
  expireAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  lastUsed: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  consumer: {
    execute: (request: TokenConsumerRequest, defaultValue?: TokenConsumer | null) => Promise<TokenConsumer | null>
  }
}

export interface TokenObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  extra: ExtraObservableChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Observable<Extra | null> }
  expireAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  lastUsed: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  consumer: {
    execute: (request: TokenConsumerRequest, defaultValue?: TokenConsumer | null) => Observable<TokenConsumer | null>
  }
}

export interface PrinterPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  deviceId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  enabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  agentId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  options: PrintingOptionsPromiseChain & {
    execute: (request: PrintingOptionsRequest, defaultValue?: PrintingOptions | null) => Promise<PrintingOptions | null>
  }
}

export interface PrinterObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  deviceId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  enabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  agentId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  options: PrintingOptionsObservableChain & {
    execute: (request: PrintingOptionsRequest, defaultValue?: PrintingOptions | null) => Observable<PrintingOptions | null>
  }
}

export interface PrintingOptionsPromiseChain {
  media: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  sides: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  fitToPage: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface PrintingOptionsObservableChain {
  media: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  sides: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  fitToPage: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface AgentHealthChecksConnectionPromiseChain {
  items: {
    execute: (
      request: AgentHealthCheckRequest,
      defaultValue?: (AgentHealthCheck | null)[] | null,
    ) => Promise<(AgentHealthCheck | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface AgentHealthChecksConnectionObservableChain {
  items: {
    execute: (
      request: AgentHealthCheckRequest,
      defaultValue?: (AgentHealthCheck | null)[] | null,
    ) => Observable<(AgentHealthCheck | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface AgentHealthCheckPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  agentId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface AgentHealthCheckObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  agentId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface OrganizationsConnectionPromiseChain {
  items: {
    execute: (
      request: OrganizationRequest,
      defaultValue?: (Organization | null)[] | null,
    ) => Promise<(Organization | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface OrganizationsConnectionObservableChain {
  items: {
    execute: (
      request: OrganizationRequest,
      defaultValue?: (Organization | null)[] | null,
    ) => Observable<(Organization | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface MonitorsConnectionPromiseChain {
  items: {
    execute: (request: MonitorRequest, defaultValue?: (Monitor | null)[] | null) => Promise<(Monitor | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface MonitorsConnectionObservableChain {
  items: {
    execute: (request: MonitorRequest, defaultValue?: (Monitor | null)[] | null) => Observable<(Monitor | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface MonitorVisitsByLocationIdResultPromiseChain {
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  visit: VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
}

export interface MonitorVisitsByLocationIdResultObservableChain {
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  visit: VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
}

export interface CheckinKiosksConnectionPromiseChain {
  items: {
    execute: (
      request: CheckinKioskRequest,
      defaultValue?: (CheckinKiosk | null)[] | null,
    ) => Promise<(CheckinKiosk | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface CheckinKiosksConnectionObservableChain {
  items: {
    execute: (
      request: CheckinKioskRequest,
      defaultValue?: (CheckinKiosk | null)[] | null,
    ) => Observable<(CheckinKiosk | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface CheckinVirtualKiosksConnectionPromiseChain {
  items: {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: (CheckinVirtualKiosk | null)[] | null,
    ) => Promise<(CheckinVirtualKiosk | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface CheckinVirtualKiosksConnectionObservableChain {
  items: {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: (CheckinVirtualKiosk | null)[] | null,
    ) => Observable<(CheckinVirtualKiosk | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface VirtualCheckinSessionsConnectionPromiseChain {
  items: {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: (VirtualCheckinSession | null)[] | null,
    ) => Promise<(VirtualCheckinSession | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface VirtualCheckinSessionsConnectionObservableChain {
  items: {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: (VirtualCheckinSession | null)[] | null,
    ) => Observable<(VirtualCheckinSession | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface VirtualCheckinSessionPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  identifier: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  checkinVirtualKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  privacyAccepted: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  availableLocations: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  choosenLocationId: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  dataLog: { execute: (request: LogRequest, defaultValue?: (Log | null)[] | null) => Promise<(Log | null)[] | null> }
}

export interface VirtualCheckinSessionObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  identifier: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  checkinVirtualKioskId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  privacyAccepted: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  availableLocations: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  choosenLocationId: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  dataLog: { execute: (request: LogRequest, defaultValue?: (Log | null)[] | null) => Observable<(Log | null)[] | null> }
}

export interface LogPromiseChain {
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface LogObservableChain {
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  date: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface MembershipsConnectionPromiseChain {
  items: {
    execute: (
      request: MembershipRequest,
      defaultValue?: (Membership | null)[] | null,
    ) => Promise<(Membership | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface MembershipsConnectionObservableChain {
  items: {
    execute: (
      request: MembershipRequest,
      defaultValue?: (Membership | null)[] | null,
    ) => Observable<(Membership | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface RolesConnectionPromiseChain {
  items: { execute: (request: RoleRequest, defaultValue?: (Role | null)[] | null) => Promise<(Role | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface RolesConnectionObservableChain {
  items: { execute: (request: RoleRequest, defaultValue?: (Role | null)[] | null) => Observable<(Role | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface UserRolesConnectionPromiseChain {
  items: {
    execute: (request: UserRoleRequest, defaultValue?: (UserRole | null)[] | null) => Promise<(UserRole | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface UserRolesConnectionObservableChain {
  items: {
    execute: (request: UserRoleRequest, defaultValue?: (UserRole | null)[] | null) => Observable<(UserRole | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface UserRolePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  roleId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  role: RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }
}

export interface UserRoleObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  roleId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  role: RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }
}

export interface AnalyticsVisitsCountResponsePromiseChain {
  data: {
    execute: (
      request: AnalyticsVisitsCountResponseDataRequest,
      defaultValue?: (AnalyticsVisitsCountResponseData | null)[] | null,
    ) => Promise<(AnalyticsVisitsCountResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsCountResponseObservableChain {
  data: {
    execute: (
      request: AnalyticsVisitsCountResponseDataRequest,
      defaultValue?: (AnalyticsVisitsCountResponseData | null)[] | null,
    ) => Observable<(AnalyticsVisitsCountResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsCountResponseDataPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  startedBy: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
}

export interface AnalyticsVisitsCountResponseDataObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  startedBy: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
}

export interface AnalyticsVisitsDurationResponsePromiseChain {
  data: {
    execute: (
      request: AnalyticsVisitsDurationResponseDataRequest,
      defaultValue?: (AnalyticsVisitsDurationResponseData | null)[] | null,
    ) => Promise<(AnalyticsVisitsDurationResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsDurationResponseObservableChain {
  data: {
    execute: (
      request: AnalyticsVisitsDurationResponseDataRequest,
      defaultValue?: (AnalyticsVisitsDurationResponseData | null)[] | null,
    ) => Observable<(AnalyticsVisitsDurationResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsDurationResponseDataPromiseChain {
  durationAvgSecs: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  durationAvgMins: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  startedBy: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
}

export interface AnalyticsVisitsDurationResponseDataObservableChain {
  durationAvgSecs: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  durationAvgMins: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  startedBy: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
}

export interface AnalyticsVisitsWaitingTimeResponsePromiseChain {
  data: {
    execute: (
      request: AnalyticsVisitsWaitingTimeResponseDataRequest,
      defaultValue?: (AnalyticsVisitsWaitingTimeResponseData | null)[] | null,
    ) => Promise<(AnalyticsVisitsWaitingTimeResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsWaitingTimeResponseObservableChain {
  data: {
    execute: (
      request: AnalyticsVisitsWaitingTimeResponseDataRequest,
      defaultValue?: (AnalyticsVisitsWaitingTimeResponseData | null)[] | null,
    ) => Observable<(AnalyticsVisitsWaitingTimeResponseData | null)[] | null>
  }
}

export interface AnalyticsVisitsWaitingTimeResponseDataPromiseChain {
  waitingTimeAvgSecs: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  waitingTimeAvgMins: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  location: LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  startedBy: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
}

export interface AnalyticsVisitsWaitingTimeResponseDataObservableChain {
  waitingTimeAvgSecs: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  waitingTimeAvgMins: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  date: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  locationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  location: LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  startedById: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  startedBy: UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
}

export interface AgentsConnectionPromiseChain {
  items: { execute: (request: AgentRequest, defaultValue?: (Agent | null)[] | null) => Promise<(Agent | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface AgentsConnectionObservableChain {
  items: { execute: (request: AgentRequest, defaultValue?: (Agent | null)[] | null) => Observable<(Agent | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface PrintersConnectionPromiseChain {
  items: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Promise<(Printer | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface PrintersConnectionObservableChain {
  items: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Observable<(Printer | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface ScansConnectionPromiseChain {
  items: { execute: (request: ScanRequest, defaultValue?: (Scan | null)[] | null) => Promise<(Scan | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface ScansConnectionObservableChain {
  items: { execute: (request: ScanRequest, defaultValue?: (Scan | null)[] | null) => Observable<(Scan | null)[] | null> }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface NotificationsConnectionPromiseChain {
  items: {
    execute: (
      request: NotificationRequest,
      defaultValue?: (Notification | null)[] | null,
    ) => Promise<(Notification | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface NotificationsConnectionObservableChain {
  items: {
    execute: (
      request: NotificationRequest,
      defaultValue?: (Notification | null)[] | null,
    ) => Observable<(Notification | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface NotificationPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  severity: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  senderId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  senderType: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  extra: ExtraPromiseChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Promise<Extra | null> }
  receivers: {
    execute: (request: ReceiverRequest, defaultValue?: (Receiver | null)[] | null) => Promise<(Receiver | null)[] | null>
  }
  checkinKiosk: CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }
  scan: ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }
  visitor: VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }
  sender: { execute: (request: SenderRequest, defaultValue?: Sender | null) => Promise<Sender | null> }
}

export interface NotificationObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  severity: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  senderId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  senderType: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  extra: ExtraObservableChain & { execute: (request: ExtraRequest, defaultValue?: Extra | null) => Observable<Extra | null> }
  receivers: {
    execute: (request: ReceiverRequest, defaultValue?: (Receiver | null)[] | null) => Observable<(Receiver | null)[] | null>
  }
  checkinKiosk: CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }
  scan: ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }
  visitor: VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }
  sender: { execute: (request: SenderRequest, defaultValue?: Sender | null) => Observable<Sender | null> }
}

export interface ReceiverPromiseChain {
  receiverType: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  receiverId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  readAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  dismissedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface ReceiverObservableChain {
  receiverType: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  receiverId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  readAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  dismissedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface AblyAuthorizeResponsePromiseChain {
  capability: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  clientId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  expires: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  issued: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AblyAuthorizeResponseObservableChain {
  capability: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  clientId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  expires: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  issued: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AblyEnabledResponsePromiseChain {
  uiEnabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface AblyEnabledResponseObservableChain {
  uiEnabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface TriggersConnectionPromiseChain {
  items: {
    execute: (request: TriggerRequest, defaultValue?: (Trigger | null)[] | null) => Promise<(Trigger | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface TriggersConnectionObservableChain {
  items: {
    execute: (request: TriggerRequest, defaultValue?: (Trigger | null)[] | null) => Observable<(Trigger | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface TriggerPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  event: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  sync: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  continueOnError: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  actions: {
    execute: (request?: boolean | number, defaultValue?: (JSON | null)[] | null) => Promise<(JSON | null)[] | null>
  }
}

export interface TriggerObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  event: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  sync: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  continueOnError: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  actions: {
    execute: (request?: boolean | number, defaultValue?: (JSON | null)[] | null) => Observable<(JSON | null)[] | null>
  }
}

export interface TemplatesConnectionPromiseChain {
  items: {
    execute: (request: TemplateRequest, defaultValue?: (Template | null)[] | null) => Promise<(Template | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface TemplatesConnectionObservableChain {
  items: {
    execute: (request: TemplateRequest, defaultValue?: (Template | null)[] | null) => Observable<(Template | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface TemplatePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
}

export interface TemplateObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
}

export interface PreferencePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  entityIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Promise<(String | null)[] | null>
  }
  fields: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
}

export interface PreferenceObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  userId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  entityIds: {
    execute: (request?: boolean | number, defaultValue?: (String | null)[] | null) => Observable<(String | null)[] | null>
  }
  fields: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
}

export interface DeviceLogsConnectionPromiseChain {
  items: {
    execute: (request: DeviceLogRequest, defaultValue?: (DeviceLog | null)[] | null) => Promise<(DeviceLog | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface DeviceLogsConnectionObservableChain {
  items: {
    execute: (
      request: DeviceLogRequest,
      defaultValue?: (DeviceLog | null)[] | null,
    ) => Observable<(DeviceLog | null)[] | null>
  }
  total: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  skip: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  limit: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface DeviceLogPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  consumerType: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  tokenId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  eventName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  userAgent: UserAgentPromiseChain & {
    execute: (request: UserAgentRequest, defaultValue?: UserAgent | null) => Promise<UserAgent | null>
  }
  timestamp: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
}

export interface DeviceLogObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  _id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  organizationId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  consumerId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  consumerType: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  tokenId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  eventName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  userAgent: UserAgentObservableChain & {
    execute: (request: UserAgentRequest, defaultValue?: UserAgent | null) => Observable<UserAgent | null>
  }
  timestamp: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
}

export interface UserAgentPromiseChain {
  ua: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  browser: BrowserPromiseChain & {
    execute: (request: BrowserRequest, defaultValue?: Browser | null) => Promise<Browser | null>
  }
  engine: EnginePromiseChain & { execute: (request: EngineRequest, defaultValue?: Engine | null) => Promise<Engine | null> }
  os: OsPromiseChain & { execute: (request: OsRequest, defaultValue?: Os | null) => Promise<Os | null> }
  device: DevicePromiseChain & { execute: (request: DeviceRequest, defaultValue?: Device | null) => Promise<Device | null> }
  cpu: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Promise<JSON | null> }
}

export interface UserAgentObservableChain {
  ua: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  browser: BrowserObservableChain & {
    execute: (request: BrowserRequest, defaultValue?: Browser | null) => Observable<Browser | null>
  }
  engine: EngineObservableChain & {
    execute: (request: EngineRequest, defaultValue?: Engine | null) => Observable<Engine | null>
  }
  os: OsObservableChain & { execute: (request: OsRequest, defaultValue?: Os | null) => Observable<Os | null> }
  device: DeviceObservableChain & {
    execute: (request: DeviceRequest, defaultValue?: Device | null) => Observable<Device | null>
  }
  cpu: { execute: (request?: boolean | number, defaultValue?: JSON | null) => Observable<JSON | null> }
}

export interface BrowserPromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  major: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface BrowserObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  major: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface EnginePromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface EngineObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface OsPromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface OsObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface DevicePromiseChain {
  vendor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  model: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface DeviceObservableChain {
  vendor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  model: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface MutationPromiseChain {
  createLocationType: ((args?: {
    data?: LocationTypeMutationInput | null
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }) &
    (LocationTypePromiseChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
    })
  updateLocationTypeById: (args: {
    id: String
    data: LocationTypePartialMutationInput
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }
  updateLocationType: (args: {
    where: LocationTypeFilterMutationInput
    sort?: String | null
    data: LocationTypePartialMutationInput
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }
  deleteLocationTypeById: ((args?: {
    id?: String | null
  }) => LocationTypePromiseChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
  }) &
    (LocationTypePromiseChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Promise<LocationType | null>
    })
  createLocation: ((args?: {
    data?: LocationMutationInput | null
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }) &
    (LocationPromiseChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
    })
  updateLocationById: (args: {
    id: String
    data: LocationPartialMutationInput
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  updateLocation: (args: {
    where: LocationFilterMutationInput
    sort?: String | null
    data: LocationPartialMutationInput
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }
  deleteLocationById: ((args?: {
    id?: String | null
  }) => LocationPromiseChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
  }) &
    (LocationPromiseChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Promise<Location | null>
    })
  updateHierarchy: ((args?: {
    data?: (LocationWithChildrenPartialMutationInput | null)[] | null
  }) => UpdateHierarchyResultPromiseChain & {
    execute: (
      request: UpdateHierarchyResultRequest,
      defaultValue?: UpdateHierarchyResult | null,
    ) => Promise<UpdateHierarchyResult | null>
  }) &
    (UpdateHierarchyResultPromiseChain & {
      execute: (
        request: UpdateHierarchyResultRequest,
        defaultValue?: UpdateHierarchyResult | null,
      ) => Promise<UpdateHierarchyResult | null>
    })
  addBooking: (args: {
    visitorData: VisitorMutationInput
    bookingData: BookingMutationInput
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }
  createBooking: ((args?: {
    data?: BookingMutationInput | null
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }) &
    (BookingPromiseChain & { execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null> })
  updateBookingById: (args: {
    id: String
    data: BookingPartialMutationInput
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }
  updateBooking: (args: {
    where: BookingFilterMutationInput
    sort?: String | null
    data: BookingPartialMutationInput
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }
  deleteBookingById: ((args?: {
    id?: String | null
  }) => BookingPromiseChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null>
  }) &
    (BookingPromiseChain & { execute: (request: BookingRequest, defaultValue?: Booking | null) => Promise<Booking | null> })
  createVisit: ((args?: {
    data?: VisitMutationInput | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }) &
    (VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> })
  updateVisitById: (args: {
    id: String
    data: VisitPartialMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  updateVisit: (args: {
    where: VisitFilterMutationInput
    sort?: String | null
    data: VisitPartialMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  deleteVisitById: (args: {
    id: String
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  enqueue: (args: {
    data: EnqueueBodyMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  addScanToVisit: (args: {
    visitId: String
    scanId: String
    data?: VisitMutationInput | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  printAllVisitAttachments: (args: {
    visitId: String
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  printAttachments: (args: {
    checkinKioskId: String
    attachmentIds: (String | null)[]
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  callVisit: (args: {
    visitId: String
    isRecall?: Boolean | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  callNextVisit: (args: {
    locationId: String
    where?: VisitFilterMutationInput | null
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  endVisit: (args: {
    data: EndVisitMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  transferVisitor: (args: {
    data: TransferVisitorMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  amendVisit: (args: {
    data: AmendVisitMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  startVisit: (args: {
    data: StartVisitMutationInput
  }) => VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
  sendTicketToPrint: (args: {
    visitId: String
  }) => SendToPrintResultPromiseChain & {
    execute: (
      request: SendToPrintResultRequest,
      defaultValue?: SendToPrintResult | null,
    ) => Promise<SendToPrintResult | null>
  }
  sendToPrint: (args: {
    visitId: String
  }) => SendToPrintResultPromiseChain & {
    execute: (
      request: SendToPrintResultRequest,
      defaultValue?: SendToPrintResult | null,
    ) => Promise<SendToPrintResult | null>
  }
  createVisitor: ((args?: {
    data?: VisitorMutationInput | null
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }) &
    (VisitorPromiseChain & { execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null> })
  updateVisitorById: (args: {
    id: String
    data: VisitorPartialMutationInput
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }
  updateVisitor: (args: {
    where: VisitorFilterMutationInput
    sort?: String | null
    data: VisitorPartialMutationInput
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }
  deleteVisitorById: ((args?: {
    id?: String | null
  }) => VisitorPromiseChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null>
  }) &
    (VisitorPromiseChain & { execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Promise<Visitor | null> })
  createUser: ((args?: {
    data?: UserMutationInput | null
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }) &
    (UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> })
  updateUserById: (args: {
    id: String
    data: UserPartialMutationInput
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
  updateUser: (args: {
    where: UserFilterMutationInput
    sort?: String | null
    data: UserPartialMutationInput
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
  deleteUserById: ((args?: {
    id?: String | null
  }) => UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }) &
    (UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> })
  login: (args: {
    credentials: CredentialsPayloadMutationInput
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  logout: SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  resetPassword: (args: {
    data: ResetPasswordPayloadMutationInput
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  resetPasswordRequest: (args: {
    data: ResetPasswordRequestPayloadMutationInput
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  createSignageSessionToken: (args: {
    consumerId: String
    consumerType: String
  }) => CreateSignageSessionTokenResultPromiseChain & {
    execute: (
      request: CreateSignageSessionTokenResultRequest,
      defaultValue?: CreateSignageSessionTokenResult | null,
    ) => Promise<CreateSignageSessionTokenResult | null>
  }
  createOrganization: ((args?: {
    data?: OrganizationMutationInput | null
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }) &
    (OrganizationPromiseChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
    })
  updateOrganizationById: (args: {
    id: String
    data: OrganizationPartialMutationInput
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }
  updateOrganization: (args: {
    where: OrganizationFilterMutationInput
    sort?: String | null
    data: OrganizationPartialMutationInput
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }
  deleteOrganizationById: ((args?: {
    id?: String | null
  }) => OrganizationPromiseChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
  }) &
    (OrganizationPromiseChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Promise<Organization | null>
    })
  createMonitor: ((args?: {
    data?: MonitorMutationInput | null
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }) &
    (MonitorPromiseChain & { execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null> })
  updateMonitorById: (args: {
    id: String
    data: MonitorPartialMutationInput
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }
  updateMonitor: (args: {
    where: MonitorFilterMutationInput
    sort?: String | null
    data: MonitorPartialMutationInput
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }
  deleteMonitorById: ((args?: {
    id?: String | null
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }) &
    (MonitorPromiseChain & { execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null> })
  startMonitorSession: (args: {
    monitorId: String
  }) => ResultPromiseChain & { execute: (request: ResultRequest, defaultValue?: Result | null) => Promise<Result | null> }
  refreshMonitor: (args: {
    monitorId: JSON
  }) => MonitorPromiseChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Promise<Monitor | null>
  }
  createCheckinKiosk: ((args?: {
    data?: CheckinKioskMutationInput | null
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }) &
    (CheckinKioskPromiseChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
    })
  updateCheckinKioskById: (args: {
    id: String
    data: CheckinKioskPartialMutationInput
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }
  updateCheckinKiosk: (args: {
    where: CheckinKioskFilterMutationInput
    sort?: String | null
    data: CheckinKioskPartialMutationInput
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }
  deleteCheckinKioskById: ((args?: {
    id?: String | null
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }) &
    (CheckinKioskPromiseChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
    })
  startCheckinKioskSession: (args: {
    checkinKioskId: String
  }) => ResultPromiseChain & { execute: (request: ResultRequest, defaultValue?: Result | null) => Promise<Result | null> }
  generateCheckinSignageUrl: (args: {
    checkinKioskId: String
  }) => GenerateCheckinSignageUrlResultPromiseChain & {
    execute: (
      request: GenerateCheckinSignageUrlResultRequest,
      defaultValue?: GenerateCheckinSignageUrlResult | null,
    ) => Promise<GenerateCheckinSignageUrlResult | null>
  }
  refreshCheckinKiosk: (args: {
    checkinKioskId: JSON
  }) => CheckinKioskPromiseChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Promise<CheckinKiosk | null>
  }
  sendCheckinKioskNotification: ((args?: {
    checkinKioskId?: String | null
    notificationData?: SendCheckinKioskNotificationPayloadMutationInput | null
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }) &
    (SuccessPromiseChain & { execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null> })
  createCheckinVirtualKiosk: ((args?: {
    data?: CheckinVirtualKioskMutationInput | null
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskPromiseChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Promise<CheckinVirtualKiosk | null>
    })
  updateCheckinVirtualKioskById: (args: {
    id: String
    data: CheckinVirtualKioskPartialMutationInput
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }
  updateCheckinVirtualKiosk: (args: {
    where: CheckinVirtualKioskFilterMutationInput
    sort?: String | null
    data: CheckinVirtualKioskPartialMutationInput
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }
  deleteCheckinVirtualKioskById: ((args?: {
    id?: String | null
  }) => CheckinVirtualKioskPromiseChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Promise<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskPromiseChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Promise<CheckinVirtualKiosk | null>
    })
  generateVirtualCheckinURL: (args: {
    checkinVirtualKioskId: String
  }) => VirtualCheckinURLPromiseChain & {
    execute: (
      request: VirtualCheckinURLRequest,
      defaultValue?: VirtualCheckinURL | null,
    ) => Promise<VirtualCheckinURL | null>
  }
  deleteVirtualCheckinURL: (args: {
    tokenId: String
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  createVirtualCheckinSession: ((args?: {
    data?: VirtualCheckinSessionMutationInput | null
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionPromiseChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Promise<VirtualCheckinSession | null>
    })
  updateVirtualCheckinSessionById: (args: {
    id: String
    data: VirtualCheckinSessionPartialMutationInput
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }
  updateVirtualCheckinSession: (args: {
    where: VirtualCheckinSessionFilterMutationInput
    sort?: String | null
    data: VirtualCheckinSessionPartialMutationInput
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }
  deleteVirtualCheckinSessionById: ((args?: {
    id?: String | null
  }) => VirtualCheckinSessionPromiseChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Promise<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionPromiseChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Promise<VirtualCheckinSession | null>
    })
  createMembership: ((args?: {
    data?: MembershipMutationInput | null
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }) &
    (MembershipPromiseChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
    })
  updateMembershipById: (args: {
    id: String
    data: MembershipPartialMutationInput
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }
  updateMembership: (args: {
    where: MembershipFilterMutationInput
    sort?: String | null
    data: MembershipPartialMutationInput
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }
  deleteMembershipById: ((args?: {
    id?: String | null
  }) => MembershipPromiseChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
  }) &
    (MembershipPromiseChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Promise<Membership | null>
    })
  inviteUser: (args: {
    data: AddUserPayloadMutationInput
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  activateUser: (args: {
    data: ActivateUserPayloadMutationInput
  }) => SuccessPromiseChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Promise<Success | null>
  }
  createRole: ((args?: {
    data?: RoleMutationInput | null
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }) &
    (RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> })
  updateRoleById: (args: {
    id: String
    data: RolePartialMutationInput
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }
  updateRole: (args: {
    where: RoleFilterMutationInput
    sort?: String | null
    data: RolePartialMutationInput
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }
  deleteRoleById: ((args?: {
    id?: String | null
  }) => RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> }) &
    (RolePromiseChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Promise<Role | null> })
  createUserRole: ((args?: {
    data?: UserRoleMutationInput | null
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }) &
    (UserRolePromiseChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
    })
  updateUserRoleById: (args: {
    id: String
    data: UserRolePartialMutationInput
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }
  updateUserRole: (args: {
    where: UserRoleFilterMutationInput
    sort?: String | null
    data: UserRolePartialMutationInput
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }
  deleteUserRoleById: ((args?: {
    id?: String | null
  }) => UserRolePromiseChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
  }) &
    (UserRolePromiseChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Promise<UserRole | null>
    })
  createAgent: ((args?: {
    data?: AgentMutationInput | null
  }) => AgentTokenPromiseChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Promise<AgentToken | null>
  }) &
    (AgentTokenPromiseChain & {
      execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Promise<AgentToken | null>
    })
  updateAgentById: (args: {
    id: String
    data: AgentPartialMutationInput
  }) => AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> }
  updateAgent: (args: {
    where: AgentFilterMutationInput
    sort?: String | null
    data: AgentPartialMutationInput
  }) => AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> }
  deleteAgentById: ((args?: {
    id?: String | null
  }) => AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> }) &
    (AgentPromiseChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Promise<Agent | null> })
  createPrinter: ((args?: {
    data?: PrinterMutationInput | null
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }) &
    (PrinterPromiseChain & { execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null> })
  updatePrinterById: (args: {
    id: String
    data: PrinterPartialMutationInput
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }
  updatePrinter: (args: {
    where: PrinterFilterMutationInput
    sort?: String | null
    data: PrinterPartialMutationInput
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }
  deletePrinterById: ((args?: {
    id?: String | null
  }) => PrinterPromiseChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null>
  }) &
    (PrinterPromiseChain & { execute: (request: PrinterRequest, defaultValue?: Printer | null) => Promise<Printer | null> })
  generateAgentToken: ((args?: {
    agentId?: String | null
  }) => AgentTokenPromiseChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Promise<AgentToken | null>
  }) &
    (AgentTokenPromiseChain & {
      execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Promise<AgentToken | null>
    })
  createAgentHealthCheck: AgentHealthCheckPromiseChain & {
    execute: (request: AgentHealthCheckRequest, defaultValue?: AgentHealthCheck | null) => Promise<AgentHealthCheck | null>
  }
  updateAgentPrinters: (args: {
    printers: (PrinterPartialMutationInput | null)[]
    agentId?: String | null
  }) => UpdateAgentPrintersResultPromiseChain & {
    execute: (
      request: UpdateAgentPrintersResultRequest,
      defaultValue?: UpdateAgentPrintersResult | null,
    ) => Promise<UpdateAgentPrintersResult | null>
  }
  createScan: ((args?: {
    data?: ScanMutationInput | null
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }) &
    (ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> })
  updateScanById: (args: {
    id: String
    data: ScanPartialMutationInput
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }
  updateScan: (args: {
    where: ScanFilterMutationInput
    sort?: String | null
    data: ScanPartialMutationInput
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }
  deleteScanById: ((args?: {
    id?: String | null
  }) => ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> }) &
    (ScanPromiseChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Promise<Scan | null> })
  markNotificationAsRead: (args: {
    notificationId: String
  }) => NotificationPromiseChain & {
    execute: (request: NotificationRequest, defaultValue?: Notification | null) => Promise<Notification | null>
  }
  markNotificationAsDismissed: (args: {
    notificationId: String
  }) => NotificationPromiseChain & {
    execute: (request: NotificationRequest, defaultValue?: Notification | null) => Promise<Notification | null>
  }
  createTrigger: ((args?: {
    data?: TriggerMutationInput | null
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }) &
    (TriggerPromiseChain & { execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null> })
  updateTriggerById: (args: {
    id: String
    data: TriggerPartialMutationInput
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }
  updateTrigger: (args: {
    where: TriggerFilterMutationInput
    sort?: String | null
    data: TriggerPartialMutationInput
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }
  deleteTriggerById: ((args?: {
    id?: String | null
  }) => TriggerPromiseChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null>
  }) &
    (TriggerPromiseChain & { execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Promise<Trigger | null> })
  createTemplate: ((args?: {
    data?: TemplateMutationInput | null
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }) &
    (TemplatePromiseChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
    })
  updateTemplateById: (args: {
    id: String
    data: TemplatePartialMutationInput
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }
  updateTemplate: (args: {
    where: TemplateFilterMutationInput
    sort?: String | null
    data: TemplatePartialMutationInput
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }
  deleteTemplateById: ((args?: {
    id?: String | null
  }) => TemplatePromiseChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
  }) &
    (TemplatePromiseChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Promise<Template | null>
    })
  updatePreference: (args: {
    name: String
    data: PreferencePartialMutationInput
    isDeltaUpdate?: Boolean | null
  }) => PreferencePromiseChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Promise<Preference | null>
  }
  updateDefaultPreference: (args: {
    name: String
    data: PreferenceMutationInput
    isDeltaUpdate?: Boolean | null
  }) => PreferencePromiseChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Promise<Preference | null>
  }
  createDeviceLog: ((args?: {
    data?: DeviceLogMutationInput | null
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }) &
    (DeviceLogPromiseChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
    })
  updateDeviceLogById: (args: {
    id: String
    data: DeviceLogPartialMutationInput
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }
  updateDeviceLog: (args: {
    where: DeviceLogFilterMutationInput
    sort?: String | null
    data: DeviceLogPartialMutationInput
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }
  deleteDeviceLogById: ((args?: {
    id?: String | null
  }) => DeviceLogPromiseChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
  }) &
    (DeviceLogPromiseChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Promise<DeviceLog | null>
    })
}

export interface MutationObservableChain {
  createLocationType: ((args?: {
    data?: LocationTypeMutationInput | null
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }) &
    (LocationTypeObservableChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
    })
  updateLocationTypeById: (args: {
    id: String
    data: LocationTypePartialMutationInput
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }
  updateLocationType: (args: {
    where: LocationTypeFilterMutationInput
    sort?: String | null
    data: LocationTypePartialMutationInput
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }
  deleteLocationTypeById: ((args?: {
    id?: String | null
  }) => LocationTypeObservableChain & {
    execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
  }) &
    (LocationTypeObservableChain & {
      execute: (request: LocationTypeRequest, defaultValue?: LocationType | null) => Observable<LocationType | null>
    })
  createLocation: ((args?: {
    data?: LocationMutationInput | null
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }) &
    (LocationObservableChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
    })
  updateLocationById: (args: {
    id: String
    data: LocationPartialMutationInput
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  updateLocation: (args: {
    where: LocationFilterMutationInput
    sort?: String | null
    data: LocationPartialMutationInput
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }
  deleteLocationById: ((args?: {
    id?: String | null
  }) => LocationObservableChain & {
    execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
  }) &
    (LocationObservableChain & {
      execute: (request: LocationRequest, defaultValue?: Location | null) => Observable<Location | null>
    })
  updateHierarchy: ((args?: {
    data?: (LocationWithChildrenPartialMutationInput | null)[] | null
  }) => UpdateHierarchyResultObservableChain & {
    execute: (
      request: UpdateHierarchyResultRequest,
      defaultValue?: UpdateHierarchyResult | null,
    ) => Observable<UpdateHierarchyResult | null>
  }) &
    (UpdateHierarchyResultObservableChain & {
      execute: (
        request: UpdateHierarchyResultRequest,
        defaultValue?: UpdateHierarchyResult | null,
      ) => Observable<UpdateHierarchyResult | null>
    })
  addBooking: (args: {
    visitorData: VisitorMutationInput
    bookingData: BookingMutationInput
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }
  createBooking: ((args?: {
    data?: BookingMutationInput | null
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }) &
    (BookingObservableChain & {
      execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
    })
  updateBookingById: (args: {
    id: String
    data: BookingPartialMutationInput
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }
  updateBooking: (args: {
    where: BookingFilterMutationInput
    sort?: String | null
    data: BookingPartialMutationInput
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }
  deleteBookingById: ((args?: {
    id?: String | null
  }) => BookingObservableChain & {
    execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
  }) &
    (BookingObservableChain & {
      execute: (request: BookingRequest, defaultValue?: Booking | null) => Observable<Booking | null>
    })
  createVisit: ((args?: {
    data?: VisitMutationInput | null
  }) => VisitObservableChain & {
    execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null>
  }) &
    (VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> })
  updateVisitById: (args: {
    id: String
    data: VisitPartialMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  updateVisit: (args: {
    where: VisitFilterMutationInput
    sort?: String | null
    data: VisitPartialMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  deleteVisitById: (args: {
    id: String
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  enqueue: (args: {
    data: EnqueueBodyMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  addScanToVisit: (args: {
    visitId: String
    scanId: String
    data?: VisitMutationInput | null
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  printAllVisitAttachments: (args: {
    visitId: String
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  printAttachments: (args: {
    checkinKioskId: String
    attachmentIds: (String | null)[]
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  callVisit: (args: {
    visitId: String
    isRecall?: Boolean | null
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  callNextVisit: (args: {
    locationId: String
    where?: VisitFilterMutationInput | null
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  endVisit: (args: {
    data: EndVisitMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  transferVisitor: (args: {
    data: TransferVisitorMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  amendVisit: (args: {
    data: AmendVisitMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  startVisit: (args: {
    data: StartVisitMutationInput
  }) => VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
  sendTicketToPrint: (args: {
    visitId: String
  }) => SendToPrintResultObservableChain & {
    execute: (
      request: SendToPrintResultRequest,
      defaultValue?: SendToPrintResult | null,
    ) => Observable<SendToPrintResult | null>
  }
  sendToPrint: (args: {
    visitId: String
  }) => SendToPrintResultObservableChain & {
    execute: (
      request: SendToPrintResultRequest,
      defaultValue?: SendToPrintResult | null,
    ) => Observable<SendToPrintResult | null>
  }
  createVisitor: ((args?: {
    data?: VisitorMutationInput | null
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }) &
    (VisitorObservableChain & {
      execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
    })
  updateVisitorById: (args: {
    id: String
    data: VisitorPartialMutationInput
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }
  updateVisitor: (args: {
    where: VisitorFilterMutationInput
    sort?: String | null
    data: VisitorPartialMutationInput
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }
  deleteVisitorById: ((args?: {
    id?: String | null
  }) => VisitorObservableChain & {
    execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
  }) &
    (VisitorObservableChain & {
      execute: (request: VisitorRequest, defaultValue?: Visitor | null) => Observable<Visitor | null>
    })
  createUser: ((args?: {
    data?: UserMutationInput | null
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }) &
    (UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> })
  updateUserById: (args: {
    id: String
    data: UserPartialMutationInput
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
  updateUser: (args: {
    where: UserFilterMutationInput
    sort?: String | null
    data: UserPartialMutationInput
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }
  deleteUserById: ((args?: {
    id?: String | null
  }) => UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> }) &
    (UserObservableChain & { execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null> })
  login: (args: {
    credentials: CredentialsPayloadMutationInput
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  logout: SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  resetPassword: (args: {
    data: ResetPasswordPayloadMutationInput
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  resetPasswordRequest: (args: {
    data: ResetPasswordRequestPayloadMutationInput
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  createSignageSessionToken: (args: {
    consumerId: String
    consumerType: String
  }) => CreateSignageSessionTokenResultObservableChain & {
    execute: (
      request: CreateSignageSessionTokenResultRequest,
      defaultValue?: CreateSignageSessionTokenResult | null,
    ) => Observable<CreateSignageSessionTokenResult | null>
  }
  createOrganization: ((args?: {
    data?: OrganizationMutationInput | null
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }) &
    (OrganizationObservableChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
    })
  updateOrganizationById: (args: {
    id: String
    data: OrganizationPartialMutationInput
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }
  updateOrganization: (args: {
    where: OrganizationFilterMutationInput
    sort?: String | null
    data: OrganizationPartialMutationInput
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }
  deleteOrganizationById: ((args?: {
    id?: String | null
  }) => OrganizationObservableChain & {
    execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
  }) &
    (OrganizationObservableChain & {
      execute: (request: OrganizationRequest, defaultValue?: Organization | null) => Observable<Organization | null>
    })
  createMonitor: ((args?: {
    data?: MonitorMutationInput | null
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }) &
    (MonitorObservableChain & {
      execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
    })
  updateMonitorById: (args: {
    id: String
    data: MonitorPartialMutationInput
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }
  updateMonitor: (args: {
    where: MonitorFilterMutationInput
    sort?: String | null
    data: MonitorPartialMutationInput
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }
  deleteMonitorById: ((args?: {
    id?: String | null
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }) &
    (MonitorObservableChain & {
      execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
    })
  startMonitorSession: (args: {
    monitorId: String
  }) => ResultObservableChain & {
    execute: (request: ResultRequest, defaultValue?: Result | null) => Observable<Result | null>
  }
  refreshMonitor: (args: {
    monitorId: JSON
  }) => MonitorObservableChain & {
    execute: (request: MonitorRequest, defaultValue?: Monitor | null) => Observable<Monitor | null>
  }
  createCheckinKiosk: ((args?: {
    data?: CheckinKioskMutationInput | null
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }) &
    (CheckinKioskObservableChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
    })
  updateCheckinKioskById: (args: {
    id: String
    data: CheckinKioskPartialMutationInput
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }
  updateCheckinKiosk: (args: {
    where: CheckinKioskFilterMutationInput
    sort?: String | null
    data: CheckinKioskPartialMutationInput
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }
  deleteCheckinKioskById: ((args?: {
    id?: String | null
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }) &
    (CheckinKioskObservableChain & {
      execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
    })
  startCheckinKioskSession: (args: {
    checkinKioskId: String
  }) => ResultObservableChain & {
    execute: (request: ResultRequest, defaultValue?: Result | null) => Observable<Result | null>
  }
  generateCheckinSignageUrl: (args: {
    checkinKioskId: String
  }) => GenerateCheckinSignageUrlResultObservableChain & {
    execute: (
      request: GenerateCheckinSignageUrlResultRequest,
      defaultValue?: GenerateCheckinSignageUrlResult | null,
    ) => Observable<GenerateCheckinSignageUrlResult | null>
  }
  refreshCheckinKiosk: (args: {
    checkinKioskId: JSON
  }) => CheckinKioskObservableChain & {
    execute: (request: CheckinKioskRequest, defaultValue?: CheckinKiosk | null) => Observable<CheckinKiosk | null>
  }
  sendCheckinKioskNotification: ((args?: {
    checkinKioskId?: String | null
    notificationData?: SendCheckinKioskNotificationPayloadMutationInput | null
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }) &
    (SuccessObservableChain & {
      execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
    })
  createCheckinVirtualKiosk: ((args?: {
    data?: CheckinVirtualKioskMutationInput | null
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskObservableChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Observable<CheckinVirtualKiosk | null>
    })
  updateCheckinVirtualKioskById: (args: {
    id: String
    data: CheckinVirtualKioskPartialMutationInput
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }
  updateCheckinVirtualKiosk: (args: {
    where: CheckinVirtualKioskFilterMutationInput
    sort?: String | null
    data: CheckinVirtualKioskPartialMutationInput
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }
  deleteCheckinVirtualKioskById: ((args?: {
    id?: String | null
  }) => CheckinVirtualKioskObservableChain & {
    execute: (
      request: CheckinVirtualKioskRequest,
      defaultValue?: CheckinVirtualKiosk | null,
    ) => Observable<CheckinVirtualKiosk | null>
  }) &
    (CheckinVirtualKioskObservableChain & {
      execute: (
        request: CheckinVirtualKioskRequest,
        defaultValue?: CheckinVirtualKiosk | null,
      ) => Observable<CheckinVirtualKiosk | null>
    })
  generateVirtualCheckinURL: (args: {
    checkinVirtualKioskId: String
  }) => VirtualCheckinURLObservableChain & {
    execute: (
      request: VirtualCheckinURLRequest,
      defaultValue?: VirtualCheckinURL | null,
    ) => Observable<VirtualCheckinURL | null>
  }
  deleteVirtualCheckinURL: (args: {
    tokenId: String
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  createVirtualCheckinSession: ((args?: {
    data?: VirtualCheckinSessionMutationInput | null
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionObservableChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Observable<VirtualCheckinSession | null>
    })
  updateVirtualCheckinSessionById: (args: {
    id: String
    data: VirtualCheckinSessionPartialMutationInput
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }
  updateVirtualCheckinSession: (args: {
    where: VirtualCheckinSessionFilterMutationInput
    sort?: String | null
    data: VirtualCheckinSessionPartialMutationInput
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }
  deleteVirtualCheckinSessionById: ((args?: {
    id?: String | null
  }) => VirtualCheckinSessionObservableChain & {
    execute: (
      request: VirtualCheckinSessionRequest,
      defaultValue?: VirtualCheckinSession | null,
    ) => Observable<VirtualCheckinSession | null>
  }) &
    (VirtualCheckinSessionObservableChain & {
      execute: (
        request: VirtualCheckinSessionRequest,
        defaultValue?: VirtualCheckinSession | null,
      ) => Observable<VirtualCheckinSession | null>
    })
  createMembership: ((args?: {
    data?: MembershipMutationInput | null
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }) &
    (MembershipObservableChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
    })
  updateMembershipById: (args: {
    id: String
    data: MembershipPartialMutationInput
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }
  updateMembership: (args: {
    where: MembershipFilterMutationInput
    sort?: String | null
    data: MembershipPartialMutationInput
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }
  deleteMembershipById: ((args?: {
    id?: String | null
  }) => MembershipObservableChain & {
    execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
  }) &
    (MembershipObservableChain & {
      execute: (request: MembershipRequest, defaultValue?: Membership | null) => Observable<Membership | null>
    })
  inviteUser: (args: {
    data: AddUserPayloadMutationInput
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  activateUser: (args: {
    data: ActivateUserPayloadMutationInput
  }) => SuccessObservableChain & {
    execute: (request: SuccessRequest, defaultValue?: Success | null) => Observable<Success | null>
  }
  createRole: ((args?: {
    data?: RoleMutationInput | null
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }) &
    (RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> })
  updateRoleById: (args: {
    id: String
    data: RolePartialMutationInput
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }
  updateRole: (args: {
    where: RoleFilterMutationInput
    sort?: String | null
    data: RolePartialMutationInput
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }
  deleteRoleById: ((args?: {
    id?: String | null
  }) => RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> }) &
    (RoleObservableChain & { execute: (request: RoleRequest, defaultValue?: Role | null) => Observable<Role | null> })
  createUserRole: ((args?: {
    data?: UserRoleMutationInput | null
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }) &
    (UserRoleObservableChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
    })
  updateUserRoleById: (args: {
    id: String
    data: UserRolePartialMutationInput
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }
  updateUserRole: (args: {
    where: UserRoleFilterMutationInput
    sort?: String | null
    data: UserRolePartialMutationInput
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }
  deleteUserRoleById: ((args?: {
    id?: String | null
  }) => UserRoleObservableChain & {
    execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
  }) &
    (UserRoleObservableChain & {
      execute: (request: UserRoleRequest, defaultValue?: UserRole | null) => Observable<UserRole | null>
    })
  createAgent: ((args?: {
    data?: AgentMutationInput | null
  }) => AgentTokenObservableChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Observable<AgentToken | null>
  }) &
    (AgentTokenObservableChain & {
      execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Observable<AgentToken | null>
    })
  updateAgentById: (args: {
    id: String
    data: AgentPartialMutationInput
  }) => AgentObservableChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null> }
  updateAgent: (args: {
    where: AgentFilterMutationInput
    sort?: String | null
    data: AgentPartialMutationInput
  }) => AgentObservableChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null> }
  deleteAgentById: ((args?: {
    id?: String | null
  }) => AgentObservableChain & {
    execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null>
  }) &
    (AgentObservableChain & { execute: (request: AgentRequest, defaultValue?: Agent | null) => Observable<Agent | null> })
  createPrinter: ((args?: {
    data?: PrinterMutationInput | null
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }) &
    (PrinterObservableChain & {
      execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
    })
  updatePrinterById: (args: {
    id: String
    data: PrinterPartialMutationInput
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }
  updatePrinter: (args: {
    where: PrinterFilterMutationInput
    sort?: String | null
    data: PrinterPartialMutationInput
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }
  deletePrinterById: ((args?: {
    id?: String | null
  }) => PrinterObservableChain & {
    execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
  }) &
    (PrinterObservableChain & {
      execute: (request: PrinterRequest, defaultValue?: Printer | null) => Observable<Printer | null>
    })
  generateAgentToken: ((args?: {
    agentId?: String | null
  }) => AgentTokenObservableChain & {
    execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Observable<AgentToken | null>
  }) &
    (AgentTokenObservableChain & {
      execute: (request: AgentTokenRequest, defaultValue?: AgentToken | null) => Observable<AgentToken | null>
    })
  createAgentHealthCheck: AgentHealthCheckObservableChain & {
    execute: (
      request: AgentHealthCheckRequest,
      defaultValue?: AgentHealthCheck | null,
    ) => Observable<AgentHealthCheck | null>
  }
  updateAgentPrinters: (args: {
    printers: (PrinterPartialMutationInput | null)[]
    agentId?: String | null
  }) => UpdateAgentPrintersResultObservableChain & {
    execute: (
      request: UpdateAgentPrintersResultRequest,
      defaultValue?: UpdateAgentPrintersResult | null,
    ) => Observable<UpdateAgentPrintersResult | null>
  }
  createScan: ((args?: {
    data?: ScanMutationInput | null
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }) &
    (ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> })
  updateScanById: (args: {
    id: String
    data: ScanPartialMutationInput
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }
  updateScan: (args: {
    where: ScanFilterMutationInput
    sort?: String | null
    data: ScanPartialMutationInput
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }
  deleteScanById: ((args?: {
    id?: String | null
  }) => ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> }) &
    (ScanObservableChain & { execute: (request: ScanRequest, defaultValue?: Scan | null) => Observable<Scan | null> })
  markNotificationAsRead: (args: {
    notificationId: String
  }) => NotificationObservableChain & {
    execute: (request: NotificationRequest, defaultValue?: Notification | null) => Observable<Notification | null>
  }
  markNotificationAsDismissed: (args: {
    notificationId: String
  }) => NotificationObservableChain & {
    execute: (request: NotificationRequest, defaultValue?: Notification | null) => Observable<Notification | null>
  }
  createTrigger: ((args?: {
    data?: TriggerMutationInput | null
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }) &
    (TriggerObservableChain & {
      execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
    })
  updateTriggerById: (args: {
    id: String
    data: TriggerPartialMutationInput
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }
  updateTrigger: (args: {
    where: TriggerFilterMutationInput
    sort?: String | null
    data: TriggerPartialMutationInput
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }
  deleteTriggerById: ((args?: {
    id?: String | null
  }) => TriggerObservableChain & {
    execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
  }) &
    (TriggerObservableChain & {
      execute: (request: TriggerRequest, defaultValue?: Trigger | null) => Observable<Trigger | null>
    })
  createTemplate: ((args?: {
    data?: TemplateMutationInput | null
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }) &
    (TemplateObservableChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
    })
  updateTemplateById: (args: {
    id: String
    data: TemplatePartialMutationInput
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }
  updateTemplate: (args: {
    where: TemplateFilterMutationInput
    sort?: String | null
    data: TemplatePartialMutationInput
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }
  deleteTemplateById: ((args?: {
    id?: String | null
  }) => TemplateObservableChain & {
    execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
  }) &
    (TemplateObservableChain & {
      execute: (request: TemplateRequest, defaultValue?: Template | null) => Observable<Template | null>
    })
  updatePreference: (args: {
    name: String
    data: PreferencePartialMutationInput
    isDeltaUpdate?: Boolean | null
  }) => PreferenceObservableChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Observable<Preference | null>
  }
  updateDefaultPreference: (args: {
    name: String
    data: PreferenceMutationInput
    isDeltaUpdate?: Boolean | null
  }) => PreferenceObservableChain & {
    execute: (request: PreferenceRequest, defaultValue?: Preference | null) => Observable<Preference | null>
  }
  createDeviceLog: ((args?: {
    data?: DeviceLogMutationInput | null
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }) &
    (DeviceLogObservableChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
    })
  updateDeviceLogById: (args: {
    id: String
    data: DeviceLogPartialMutationInput
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }
  updateDeviceLog: (args: {
    where: DeviceLogFilterMutationInput
    sort?: String | null
    data: DeviceLogPartialMutationInput
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }
  deleteDeviceLogById: ((args?: {
    id?: String | null
  }) => DeviceLogObservableChain & {
    execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
  }) &
    (DeviceLogObservableChain & {
      execute: (request: DeviceLogRequest, defaultValue?: DeviceLog | null) => Observable<DeviceLog | null>
    })
}

export interface UpdateHierarchyResultPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface UpdateHierarchyResultObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface SuccessPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface SuccessObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface SendToPrintResultPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  visit: VisitPromiseChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Promise<Visit | null> }
}

export interface SendToPrintResultObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
  visitId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  visit: VisitObservableChain & { execute: (request: VisitRequest, defaultValue?: Visit | null) => Observable<Visit | null> }
}

export interface CreateSignageSessionTokenResultPromiseChain {
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface CreateSignageSessionTokenResultObservableChain {
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface ResultPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface ResultObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface GenerateCheckinSignageUrlResultPromiseChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface GenerateCheckinSignageUrlResultObservableChain {
  url: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface UpdateAgentPrintersResultPromiseChain {
  printers: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Promise<(Printer | null)[] | null>
  }
}

export interface UpdateAgentPrintersResultObservableChain {
  printers: {
    execute: (request: PrinterRequest, defaultValue?: (Printer | null)[] | null) => Observable<(Printer | null)[] | null>
  }
}
