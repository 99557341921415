import { useCallback, useEffect, useState } from 'react'

export interface UseWebNotificationProps {
  requestPermission?: boolean
}

export type showNotification = {
  title: string
} & NotificationOptions

export const useWebNotification = (props: UseWebNotificationProps) => {
  const [isPermissionGranted, setIsPermissionGranted] = useState(Notification.permission === 'granted')

  const requestPermission = useCallback(() => {
    if (isPermissionGranted) return true

    return Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      const isGranted = permission === 'granted'
      setIsPermissionGranted(isGranted)

      return isGranted
    })
  }, [isPermissionGranted])

  useEffect(() => {
    if (props.requestPermission) {
      if (isPermissionGranted) return

      requestPermission()
    }
  }, [props.requestPermission])

  const createNotification = useCallback(
    async (args: showNotification) => {
      await requestPermission()
      const { title, ...rest } = args

      return new Notification(title, rest)
    },
    [requestPermission],
  )

  return { show: createNotification }
}
