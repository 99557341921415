import React, { useCallback, useMemo, Fragment } from 'react'
import { CheckinKiosk } from '@gaudia/ui-common'
import { jsonLogic } from '@gaudia/shared'
import { Box, Button, Input } from '../../../../components/layout'
import { Text } from '../../../../components/layout/Text'

interface Content {
  name: string
  type:
    | 'row'
    | 'col'
    | 'box'
    | 'card'
    | 'text'
    | 'paragraph'
    | 'title'
    | 'button'
    | 'navigation'
    | 'image'
    | 'input'
    | 'list'
    | 'repeater'
  props: any
  elements: Content[]
}

const WrapperComp: React.FC<{ type?: Content['type'] }> = ({ type, children }) => {
  if (type === 'list') {
    return <Box as="li">{children}</Box>
  }

  return <Fragment>{children}</Fragment>
}

export const LayoutItem: React.FC<{
  content: Content
  checkinKiosk?: CheckinKiosk | null
  goToPreviousStep: Function
  goToNextStep: Function
  goToStep: Function
  state: Record<string, any>
  updateState: Function
  performAction?: Function
}> = React.memo(
  ({
    content,
    checkinKiosk,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    state,
    updateState,
    performAction,
  }) => {
    const renderedChildren = useMemo(
      () =>
        content?.elements?.map((el, index: number) => {
          return (
            <WrapperComp type={content?.type} key={index}>
              <LayoutItem
                content={el}
                checkinKiosk={checkinKiosk}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                goToStep={goToStep}
                state={state}
                updateState={updateState}
                performAction={performAction}
              />
            </WrapperComp>
          )
        }),
      [
        content?.elements,
        checkinKiosk,
        goToNextStep,
        goToPreviousStep,
        goToStep,
        state,
        updateState,
      ],
    )

    const handleClick = useCallback(() => {
      performAction?.(content?.props?.onClick)
    }, [content?.props?.onClick, performAction])

    const handleMouseUp = useCallback(() => {
      performAction?.(content?.props?.onMouseUp)
    }, [content?.props?.onClick, performAction])

    const formattedProps = useMemo(() => {
      const props = {
        ...(content?.props ?? {}),
        state,
        updateState,
        onClick: content?.props?.onClick ? handleClick : null,
        onMouseUp: content?.props?.onMouseUp ? handleMouseUp : null,
      }

      return props
    }, [content?.props, checkinKiosk, handleClick, handleMouseUp, state, updateState])

    const repeatedItems = useMemo(() => {
      if (content?.type !== 'repeater') return null

      return formattedProps?.data?.map((i: any, index: number) => {
        const itemProps = jsonLogic.mapper(
          { $item: i },
          formattedProps.itemMapping,
          // (key) => !['onSuccess', 'onReceive', 'onClick'].includes(key),
        )

        const item = itemProps

        return (
          <LayoutItem
            key={index}
            content={item}
            checkinKiosk={checkinKiosk}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            goToStep={goToStep}
            state={state}
            updateState={updateState}
            performAction={performAction}
          />
        )
      })
    }, [
      content?.type,
      formattedProps.itemProps,
      formattedProps?.items,
      goToNextStep,
      goToPreviousStep,
      goToStep,
      performAction,
      state,
      updateState,
    ])

    if (typeof formattedProps?.visible !== 'undefined' && !formattedProps?.visible) {
      return null
    }

    return (
      <>
        {content?.type === 'box' && (
          <Box
            display="flex"
            flex={1}
            alignItems="stretch"
            justifyContent="center"
            variant="box.default"
            {...formattedProps}
          >
            {renderedChildren}
          </Box>
        )}

        {content?.type === 'card' && (
          <Box
            display="flex"
            flex={1}
            alignItems="stretch"
            justifyContent="center"
            variant="card.default"
            {...formattedProps}
          >
            {renderedChildren}
          </Box>
        )}

        {/*{content?.type === 'row' && (
          <Row
            justify="space-between"
            style={{ flex: 1, width: '100%' }}
            gutter={12}
            {...formattedProps}
          >
            {renderedChildren}
          </Row>
        )}

        {content?.type === 'col' && (
          <Col flex={1} style={{ flex: 1 }} {...formattedProps}>
            {renderedChildren}
          </Col>
        )}*/}

        {content?.type === 'text' && (
          <Text variant="default" {...formattedProps}>
            {content.props?.value}
          </Text>
        )}

        {content?.type === 'paragraph' && (
          <Text as="p" display="block" m={0} variant="default" {...formattedProps}>
            {renderedChildren}
          </Text>
        )}

        {content?.type === 'list' && (
          <Box as={content.props?.ordered ? 'ol' : 'ul'} variant="default" {...formattedProps}>
            {renderedChildren}
          </Box>
        )}

        {content?.type === 'button' && (
          <Button variant="default" {...formattedProps}>
            {content.props?.value ?? renderedChildren}
          </Button>
        )}

        {content?.type === 'image' && <Box as="img" variant="image.default" {...formattedProps} />}

        {content?.type === 'input' && <Input variant="default" {...formattedProps} />}

        {content?.type === 'repeater' && <>{repeatedItems}</>}
      </>
    )
  },
  (prevProps, nextProps) => {
    const areEqual = JSON.stringify(prevProps?.content) === JSON.stringify(nextProps?.content)
    // if (nextProps?.content?.name === 'per favore inserisciiiii') {
    /*console.log(
      nextProps,
      // @ts-ignore
      Object.keys(prevProps).filter((propName) => prevProps[propName] !== nextProps[propName]),
      areEqual,
    )*/

    return areEqual
    // }
  },
)
