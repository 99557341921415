import { useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { PreMiddlewareFunction } from '@gaudia/ui-common'
import { httpClient } from '../lib/httpClient'

const development = true

export const useToken = ({ locationSearch }: { locationSearch: string }) => {
  const history = useHistory()
  const urlParams = useMemo(() => new URLSearchParams(locationSearch), [locationSearch])
  const queryToken = useMemo(() => urlParams.get('token'), [urlParams])
  const queryOrganizationId = useMemo(() => urlParams.get('organizationId'), [urlParams])
  const [token, setToken] = useState<string | null>(queryToken)
  const [organizationId, setOrganizationId] = useState<string | null>(queryOrganizationId)

  useEffect(() => {
    if (queryToken) {
      setToken(() => queryToken)
    }
  }, [queryToken])

  useEffect(() => {
    if (organizationId) {
      setOrganizationId(() => organizationId)
    }
  }, [organizationId])

  useEffect(() => {
    if (token) {
      const middleware: PreMiddlewareFunction = ({ headers }) => {
        headers.authorization = token
      }
      httpClient.addMiddleware('pre', middleware)

      if (!development) {
        urlParams.delete('token')
      }
      const newSearchParams = urlParams.toString()
      history.replace({
        pathname: history.location.pathname,
        search: newSearchParams ? `?${newSearchParams}` : '',
      })

      return () => {
        // httpClient.removeMiddleware('pre', middleware)
      }
    }
  }, [token])

  useEffect(() => {
    if (organizationId) {
      const middleware: PreMiddlewareFunction = ({ headers }) => {
        headers['x-gaudia-organizationid'] = organizationId
      }
      httpClient.addMiddleware('pre', middleware)

      if (!development) {
        urlParams.delete('organizationId')
      }
      const newSearchParams = urlParams.toString()
      history.replace({
        pathname: history.location.pathname,
        search: newSearchParams ? `?${newSearchParams}` : '',
      })

      return () => {
        // httpClient.removeMiddleware('pre', middleware)
      }
    }
  }, [organizationId])

  return { token }
}
