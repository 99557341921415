import { useMemo } from 'react'
import { TextToSpeech, TextToSpeechOptions } from '../lib/TextToSpeech'
import { createClient } from '../lib/graphql'

export interface UseTextToSpeechProps {
  namespace?: string
  customClient?: ReturnType<typeof createClient>
  textToSpeechOptions?: TextToSpeechOptions
}

export const useTextToSpeech = ({ namespace, customClient, textToSpeechOptions }: UseTextToSpeechProps) => {
  const textToSpeech = useMemo(
    () => new TextToSpeech(customClient, textToSpeechOptions),
    [customClient, JSON.stringify(textToSpeechOptions)],
  )
  const audioQueue = useMemo(
    () => (namespace ? textToSpeech.getAudioQueue(namespace) : null),
    [textToSpeech, namespace],
  )

  return {
    play: (ssml: string) => textToSpeech.play(ssml, namespace),
    isQueueIdle: audioQueue ? audioQueue?.size === 0 && audioQueue?.pending === 0 : true,
    instance: textToSpeech,
  }
}
