import React from 'react'
import styled from '@emotion/styled'
import { useAbly } from '../lib/ably'

const Wrapper = styled.div({
  background: 'red',
  padding: '10px 5px',
  fontSize: '2vw',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
})

export const OfflineWarning: React.FC = () => {
  const { stateChangeHistory } = useAbly()
  if (stateChangeHistory[0] === 'connected' || stateChangeHistory?.length <= 3) return null

  return <Wrapper>Il dispositivo non risulta collegato ad internet</Wrapper>
}
