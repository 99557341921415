import { useIdle } from 'react-use'
import { useEffect, useMemo, useState } from 'react'
import pino from 'pino'
import { CheckinKiosk } from '@gaudia/ui-common'
import { store } from '../../store'
import { useAblyEvent } from '../../lib/ably'

const logger = pino({ browser: { asObject: true } }).child({ ns: 'checkInKioskSmartUpdate' })

export const useSmartUpdate = (
  checkinKiosk?: CheckinKiosk | null,
  currentStepIndex?: number | null,
) => {
  const hasCodeUpdate = store.hasUpdate
  const [hasDataUpdate, setHasDataUpdate] = useState(false)
  const hasUpdate = useMemo(() => hasCodeUpdate || hasDataUpdate, [hasCodeUpdate, hasDataUpdate])
  const isIdle = useIdle(60 * 1000)

  useAblyEvent(
    checkinKiosk?.id ? [{ channelName: `checkinKiosk:${checkinKiosk?.id}` }] : [],
    () => {
      setHasDataUpdate(true)
    },
  )

  useEffect(() => {
    const debugModeActive = checkinKiosk?.interface?.debugMode
    const initialStepIdle = currentStepIndex === 0 && isIdle

    if ((initialStepIdle || debugModeActive) && hasUpdate) {
      logger.info('Updating UI')
      window.location.reload()
    }
  }, [checkinKiosk?.interface?.debugMode, currentStepIndex, hasUpdate, isIdle])
}
