import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

function Home() {
  const location = useLocation()
  const history = useHistory()
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    const newSearchParams = urlParams.toString()
    history.replace({
      ...location,
      pathname: '/redirector',
      search: newSearchParams ? `?${newSearchParams}` : '',
    })
  }, [history, location, urlParams])

  return null
}

export default Home
