// Example uses styled-components, but styled-system works with most other css-in-js libraries as well
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import { Box, BoxProps } from './Box'

// Add styled-system functions to your component
export const Button = styled(Box)<BoxProps>(
  variant({
    scale: 'buttons',
    variants: {},
  }),
)

Button.defaultProps = {
  as: 'button',
}
