import { useCallback, useMemo, useReducer } from 'react'
import { CheckinKiosk, Step } from '@gaudia/ui-common'

type GoToLogic = 'history' | 'order'

export const useStepsController = ({
  checkinKiosk,
}: {
  checkinKiosk?: CheckinKiosk | null
}): {
  currentStep?: Step | null
  currentStepIndex?: number | null
  setCurrentStep: (step: Step) => void
  goToStep: Function
  goToNextStep: Function
  goToPreviousStep: Function
  goToFirstStep: Function
} => {
  const [{ currentStep, previousStep }, setCurrentStep] = useReducer(
    (state: { currentStep?: Step | null; previousStep?: Step | null }, step: Step | null) => ({
      previousStep: state.currentStep,
      currentStep: step,
    }),
    { currentStep: null, previousStep: null },
  )
  const currentStepIndex = useMemo(
    () => currentStep && checkinKiosk?.interface?.steps?.indexOf(currentStep),
    [checkinKiosk?.interface?.steps, currentStep],
  )

  const goToPreviousStep = useCallback(
    (goToLogic: GoToLogic) => {
      const logic = goToLogic ?? 'history'

      if (logic === 'order' && typeof currentStepIndex === 'number' && currentStepIndex !== 0) {
        return setCurrentStep(checkinKiosk?.interface?.steps?.[currentStepIndex - 1] as Step)
      }

      if (logic === 'history' && previousStep) {
        return setCurrentStep(previousStep)
      }
    },
    [checkinKiosk?.interface?.steps, currentStepIndex, previousStep],
  )

  const goToNextStep = useCallback(() => {
    if (
      checkinKiosk?.interface?.steps &&
      typeof currentStepIndex === 'number' &&
      currentStepIndex !== checkinKiosk?.interface?.steps?.length - 1
    ) {
      setCurrentStep(checkinKiosk?.interface?.steps?.[currentStepIndex + 1] as Step)
    }
  }, [checkinKiosk?.interface?.steps, currentStepIndex])

  const goToStep = useCallback(
    (stepName: string) => {
      const stepIndex = checkinKiosk?.interface?.steps?.findIndex((s) => s?.name === stepName) ?? -1
      if (stepIndex > -1) {
        setCurrentStep(checkinKiosk?.interface?.steps?.[stepIndex] as Step)
      }
    },
    [checkinKiosk?.interface?.steps],
  )

  const goToFirstStep = useCallback(
    () => setCurrentStep(checkinKiosk?.interface?.steps?.[0] as Step),
    [checkinKiosk?.interface?.steps],
  )

  return {
    currentStep,
    currentStepIndex,
    setCurrentStep,
    goToStep,
    goToNextStep,
    goToPreviousStep,
    goToFirstStep,
  }
}
