import trimStart from 'lodash-es/trimStart'
import compact from 'lodash-es/compact'
import axios from 'axios'
import Bluebird from 'bluebird'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export type PreMiddlewareArgs = Pick<AxiosRequestConfig, 'method' | 'data' | 'headers' | 'params'>
export type PreMiddlewareFunction = (args: PreMiddlewareArgs) => any

export type PostMiddlewareArgs = { result: AxiosResponse; args: AxiosRequestConfig }
export type PostMiddlewareFunction = (args: PostMiddlewareArgs) => any

export type Middlewares = {
  pre: PreMiddlewareFunction[]
  post: PostMiddlewareFunction[]
}

type PreStage = 'pre'
type PostStage = 'post'

type Stage = PreStage | PostStage

export const createHttpClient = (baseURL: string) => {
  const middlewares: Middlewares = {
    pre: [],
    post: [],
  }

  function addMiddleware(stage: PreStage, fn: PreMiddlewareFunction): void
  function addMiddleware(stage: PostStage, fn: PostMiddlewareFunction): void
  function addMiddleware(stage: Stage, fn: PreMiddlewareFunction & PostMiddlewareFunction) {
    middlewares[stage].push(fn)
  }

  function removeMiddleware(stage: PreStage, fn: PreMiddlewareFunction): void
  function removeMiddleware(stage: PostStage, fn: PostMiddlewareFunction): void
  function removeMiddleware(stage: Stage, fn: PreMiddlewareFunction & PostMiddlewareFunction) {
    const index = middlewares[stage].indexOf(fn)
    if (index > -1) {
      middlewares[stage].splice(index, 1)
    }
  }

  const execMiddlewares = (stage: Stage, args: any, allMiddlewares = middlewares) => {
    const mds: Function[] = allMiddlewares[stage] || []

    return Bluebird.map(mds, (fn) => fn(args), { concurrency: 1 })
  }

  const client = async ({ method, url, data = {}, params = {}, headers = {}, ...rest }: AxiosRequestConfig) => {
    const args = { method, data, params, headers }
    await execMiddlewares('pre', args)

    const u = (url || '').indexOf('http') === 0 ? url : compact([baseURL, trimStart(url)]).join('/')

    const request = {
      ...args,
      url: u,
      data,
      params,
      ...rest,
    }

    try {
      const result = await axios.request(request)
      await execMiddlewares('post', { args, result })
      return result
    } catch (error) {
      await execMiddlewares('post', { args, error })
      throw error
    }
  }

  return { client, addMiddleware, removeMiddleware }
}
