import React from 'react'
import styled from '@emotion/styled'

export const Wrapper = styled('div')`
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  align-items: stretch;
  flex-direction: column;
  user-select: none;

  *:active,
  *:focus,
  *:visited {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`

export const Inner = styled('section')`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  font-size: 1rem;
`
