// Example uses styled-components, but styled-system works with most other css-in-js libraries as well
import styled from '@emotion/styled'
import {
  space,
  layout,
  typography,
  color,
  flex,
  flexGrow,
  flexbox,
  position,
  border,
  background,
  boxShadow,
  grid,
  gridRow,
  gridTemplateColumns,
  FlexboxProps,
  DisplayProps,
  TypographyProps,
  LayoutProps,
  MarginProps,
  SpaceProps,
  /*ColorProps,*/
  FlexProps,
  FlexGrowProps,
  PositionProps,
  BorderProps,
  BackgroundProps,
  BoxShadowProps,
  GridProps,
  GridRowProps,
  GridTemplateColumnsProps,
  variant,
  system,
  gridColumnGap,
  GridColumnGapProps,
  GridRowGapProps,
  gridRowGap,
} from 'styled-system'

export type BoxProps = FlexboxProps &
  DisplayProps &
  TypographyProps &
  LayoutProps &
  MarginProps &
  SpaceProps &
  FlexProps &
  FlexGrowProps &
  PositionProps &
  BorderProps &
  BackgroundProps &
  BoxShadowProps &
  GridProps &
  GridRowProps &
  GridTemplateColumnsProps &
  GridColumnGapProps &
  GridRowGapProps

const textTransform = system({
  textTransform: {
    property: 'textTransform',
    scale: 'textTransforms',
  },
})

const transition = system({
  transition: {
    property: 'transition',
    scale: 'transition',
  },
})

const textOverflow = system({
  textOverflow: {
    property: 'textOverflow',
    scale: 'textOverflow',
  },
})

const direction = system({
  direction: {
    property: 'direction',
    scale: 'direction',
  },
})

const whiteSpace = system({
  whiteSpace: {
    property: 'whiteSpace',
    scale: 'whiteSpace',
  },
})

// Add styled-system functions to your component
export const Box = styled.div<BoxProps>(
  space,
  layout,
  typography,
  color,
  flex,
  flexGrow,
  flexbox,
  position,
  border,
  background,
  boxShadow,
  textTransform,
  transition,
  textOverflow,
  direction,
  whiteSpace,
  grid,
  gridRow,
  gridTemplateColumns,
  gridColumnGap,
  gridRowGap,
)

Box.defaultProps = {
  fontFamily: 'default',
}
