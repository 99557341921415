import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'
import { Box, BoxProps } from './Box'

// Add styled-system functions to your component
export const StyledText = styled(Box)<BoxProps>(
  variant({
    scale: 'texts',
    variants: {},
  }),
)

StyledText.defaultProps = {
  as: 'span',
}

export const Text = React.memo((props: BoxProps & any) => {
  const [val, setVal] = useState<string>()

  const handleChange = useCallback(
    (e) => {
      const val = e.target.value
      setVal(val)
      if (props.path) {
        props.updateState([props.path, val])
      }
    },
    [props],
  )

  return (
    <>
      <StyledText value={val} onChange={handleChange} {...props} />
    </>
  )
})
