/* eslint-disable no-console */

import { register } from 'register-service-worker'
import UpdateChecker from './UpdateChecker'
import { initLogger } from '../initLogger'

const TEN_MINUTES = 60 * 10 * 1000

const logger = initLogger('Service Worker', null, { browser: { asObject: false } })

export function registerServiceWorker({
  url,
  baseUrl,
  onUpdateFound,
  updatePollingIntervalSeconds,
}: {
  url?: string
  baseUrl?: string
  onUpdateFound: Function
  updatePollingIntervalSeconds?: number
}) {
  const pollingInterval = updatePollingIntervalSeconds ?? TEN_MINUTES
  const updateChecker = new UpdateChecker({ pollingInterval })

  const swUrl = url ?? `${baseUrl}/service-worker.js`

  register(swUrl, {
    ready(registration) {
      logger.info(
        `App is being served from cache by a service worker. \n For more details, visit https://goo.gl/AFskqB
				`,
      )

      updateChecker.setSwRegistration(registration)
      updateChecker.setupPolling()
    },
    registered(registration) {
      logger.info('Service worker has been registered.')
      updateChecker.setSwRegistration(registration)
      updateChecker.setupPolling()
    },
    cached() {
      logger.info('Content has been cached for offline use.')
    },
    updatefound() {
      logger.info('New content is downloading.')
    },
    updated(registration) {
      logger.info('New content is available; please refresh.')
      registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })

      // show an update notification
      logger.info('should notify')
      onUpdateFound()
    },
    offline() {
      logger.info('No internet connection found. App is running in offline mode.')
    },
    async error(error) {
      logger.error({ error }, 'Error during service worker registration:')
    },
  })
}
