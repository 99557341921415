import CryptoJS from 'crypto-js'
import pQueue from 'p-queue/dist'
import * as Sentry from '@sentry/react'
import customClient from '../customClient'
import { signageDB } from './DB'

export class TextToSpeech {
  audioSource: AudioBufferSourceNode | undefined | null
  audioCtx: AudioContext | undefined | null
  audioQueues: Record<string, pQueue> = {}

  async play(ssml: string, namespace?: string) {
    if (!namespace) return this.playSync(ssml)

    this.audioQueues[namespace] =
      this.audioQueues[namespace] ?? new pQueue({ concurrency: 1, timeout: 30000 })
    const queue = this.audioQueues[namespace]

    const addedPromise = queue.add(() => this.playSync(ssml).catch())
    const result = await addedPromise

    return result
  }

  async playSync(ssml: string) {
    const id = this.encodeSsml(ssml)
    const record = await signageDB.textToSpeeches
      .where('id')
      .equals(id)
      .first()
      .catch((err) => {
        Sentry.captureException(err, { extra: { operation: 'signageDB.textToSpeeches.first()' } })
      })

    if (record && record?.id) {
      await signageDB.textToSpeeches
        .update(record.id, {
          lastUsed: new Date(),
        })
        .catch((err) =>
          Sentry.captureException(err, {
            extra: { operation: 'signageDB.textToSpeeches.update()' },
          }),
        )

      return this.playBuffer(record.data)
    }

    return customClient
      .query({
        speak: [{ ssml }, { url: true }],
      })
      .then(async ({ data }) => {
        if (!data?.speak?.url) {
          throw new Error('Not able to create the speech')
        }
        const buffer = await this.getBufferFromUrl(data?.speak?.url)
        await signageDB.textToSpeeches
          .put({ id, data: buffer, lastUsed: new Date() })
          .catch((err) =>
            Sentry.captureException(err, {
              extra: { operation: 'signageDB.textToSpeeches.put()' },
            }),
          )

        return this.playBuffer(buffer)
      })
      .catch((err) => {
        Sentry.captureException(err, {
          extra: { operation: 'customClient.query.speak()' },
        })

        throw err
      })
  }

  async getBufferFromUrl(url: string) {
    const response = await fetch(url)
    return await response.arrayBuffer()
  }

  stop() {
    try {
      if (this.audioSource) {
        this.audioSource.buffer = null
      }
      this.audioSource?.stop()
      this.audioSource?.disconnect()
      this.audioSource = null
      this.audioCtx?.close()
      this.audioCtx = null
      this.audioSource = null
    } catch (err) {}
  }

  encodeSsml(ssml: string) {
    return CryptoJS.MD5(ssml).toString()
  }

  async playBuffer(arrayBuffer: ArrayBuffer) {
    return new Promise<void>(async (resolve) => {
      this.stop()

      // @ts-ignore
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()

      this.audioSource = this.audioCtx.createBufferSource()
      this.audioSource.buffer = await this.audioCtx.decodeAudioData(arrayBuffer)
      this.audioSource.connect(this.audioCtx.destination)
      this.audioSource.loop = false
      this.audioSource.addEventListener('ended', () => {
        this.stop()
        resolve()
      })
      this.audioSource.start(0)
    })
  }
}
