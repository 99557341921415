import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useIdentity } from '../../components/useIdentity'
import { useToken } from '../../components/useToken'
import { Box } from '../../components/layout'

function Redirector() {
  const location = useLocation()
  const history = useHistory()
  const { token } = useToken({ locationSearch: location?.search })

  const identityQuery = useIdentity()

  useEffect(() => {
    if (token && !identityQuery.isLoading) {
      const interval = setInterval(() => {
        if (!identityQuery.isLoading) {
          identityQuery.refetch()
          clearInterval(interval)
        }
      }, 200)

      return () => clearInterval(interval)
    }
  }, [token, identityQuery.isLoading])

  useEffect(() => {
    const id = identityQuery.data?.identity?.id
    if (identityQuery.data?.identity?.__typename === 'CheckinKiosk') {
      history.push({ pathname: `/checkin-kiosk/${id}` })
    }

    if (identityQuery.data?.identity?.__typename === 'Monitor') {
      history.push({ pathname: `/monitor/${id}` })
    }

    // debug
    /*if (identityQuery.data?.identity?.__typename) {
      history.push(`/checkin-kiosk/5f1dec57dd170792c7fbb8a5`)
    }*/
  }, [history, identityQuery.data?.identity?.__typename, identityQuery.data?.identity?.id])

  return (
    <Box
      background="whitesmoke"
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box fontSize="2em" px={20}>
        {identityQuery?.isFetchingInitial ? <>Caricamento in corso...</> : null}
        {/* @ts-ignore */}
        {identityQuery?.error ? <>Errore: {identityQuery?.error?.[0]?.message}</> : null}

        {/* @ts-ignore */}
        {!identityQuery?.loading && !identityQuery.error && identityQuery.data ? (
          <>
            Non é possibile fare redirect automatico per i consumer di tipo "
            {identityQuery?.data?.identity?.__typename}"
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default Redirector
