import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { initLogger, Location, Monitor, useNow } from '@gaudia/ui-common'
import dayjs from 'dayjs'
import _ from 'lodash'
import _fp from 'lodash/fp'
import { Query } from 'react-query'
import { LayoutItem } from '../../../lib/jsonLayout/LayoutItem'
import { queryCache, useRequest } from '../../../components/queryHooks'
import { useAblyEvent } from '../../../lib/ably'
import { BaseTheme } from '../../../components/BaseTheme'
import { useJsonLayout } from '../../../lib/jsonLayout/useJsonLayout'
import monitorDataForOperators1 from '../monitorDataForOperators'
import monitorDataForOperators2 from '../monitorDataForOperators2'
import monitorData from '../monitorData'
import customClient from '../../../customClient'
import { OfflineWarning } from '../../../components/OfflineWarning'

const debugMonitor = false as unknown as Monitor
// const debugMonitor = monitorDataForOperators1
// const debugMonitor = monitorDataForOperators2
// const debugMonitor = monitorData
if (debugMonitor) {
  // @ts-ignore
  console.log(JSON.stringify(debugMonitor.interface))
}

function registerGlobalUpdater(checkinKioskId?: string, UI?: any) {
  // @ts-ignore
  window.__updateMonitor = () =>
    customClient
      .mutation({
        // @ts-ignore
        updateMonitorById: [{ id: checkinKioskId, data: { interface: UI } }, { id: true }],
      })
      .then(console.log)
      .catch(console.error)
}

const DebugBar = React.memo(
  ({ state, context }: { state?: Record<string, any>; context?: Record<string, any> }) => {
    return (
      <>
        <div>state: {JSON.stringify(state)}</div>
        <div>context: {JSON.stringify(context)}</div>
      </>
    )
  },
)

const MonitorUI = () => {
  /******************************
   * Initialization
   ******************************/
  const { id: monitorId } = useParams<{
    id: string
  }>()

  const logger = useMemo(() => initLogger('monitor', { monitorId }), [monitorId])

  const monitorQuery = useRequest(
    {
      monitorById: [
        { id: monitorId },
        {
          id: true,
          name: true,
          locations: { id: true, name: true, shortName: true, queue: { hasQueue: true } },
          locationIds: true,
          interface: true,
        },
      ],
    },
    { enabled: monitorId, refetchOnWindowFocus: false },
  )
  const monitor: Monitor = useMemo(
    () => debugMonitor || monitorQuery?.data?.monitorById,
    [monitorQuery?.data?.monitorById],
  )
  const locationIds = useMemo(
    () => monitorQuery?.data?.monitorById?.locationIds,
    [monitorQuery?.data?.monitorById?.locationIds],
  )

  /******************************
   * jsonLayout
   ******************************/
  const { state, performActions, updateState, content, context, setContext } = useJsonLayout({
    content: monitor?.interface?.content,
  })

  /******************************
   * Visits
   ******************************/
  const now = useNow({ interval: 60 * 1000 })
  const startOfDay = useMemo(() => dayjs(now).startOf('day').toDate().toISOString(), [now])

  const visitsQuery = useRequest(
    {
      startedVisitsByStartedById: [
        {
          where: {
            locationId: { IN: locationIds },
            startedAt: { GTE: startOfDay },
          },
        },
        {
          startedById: true,
          startedBy: {
            id: true,
            shortName: true,
          },
          visit: {
            id: true,
            ticketCode: true,
            locationId: true,
            startedAt: true,
            location: {
              shortName: true,
            },
            updatedAt: true,
          },
        },
      ],
      monitorVisitsByLocationId: [
        {
          monitorId,
        },
        {
          locationId: true,
          location: {
            name: true,
            shortName: true,
            queue: {
              hasQueue: true,
            },
          },
          visit: {
            id: true,
            ticketCode: true,
            locationId: true,
            startedAt: true,
            location: {
              shortName: true,
            },
            updatedAt: true,
          },
        },
      ],
    },
    {
      enabled: locationIds?.length && monitor?.id,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (!visitsQuery?.isInitialData) {
      visitsQuery.refetch()
    }
  }, [startOfDay])

  // @ts-ignore
  const handleAblyEvent = useCallback(
    (message) => {
      if (locationIds?.includes(message?.data?.visit?.locationId) || !message?.data?.visit) {
        ablyEvent.ensureRefetch(visitsQuery)
      }
    },
    [locationIds],
  )

  const handleAblyOnRecover = useCallback(() => {
    ablyEvent.ensureRefetch(visitsQuery)
  }, [])

  // @ts-ignore
  const ablyEvent = useAblyEvent(
    monitor?.id
      ? [
          {
            channelName: `monitor:${monitor?.id}`,
          },
        ]
      : [],
    handleAblyEvent,
    { onRecover: handleAblyOnRecover },
  )

  const handleAblyOnRefresh = useCallback(() => {
    logger.info(`Received monitor:${monitorId}:refresh ably message. Refreshing`)
    window.location.reload()
  }, [logger, monitorId])

  useAblyEvent(
    monitor?.id
      ? [
          {
            channelName: `monitor:${monitor?.id}:refresh`,
          },
        ]
      : [],
    handleAblyOnRefresh,
  )

  useEffect(() => {
    setContext({
      $visitsByStartedBy: visitsQuery?.data?.startedVisitsByStartedById,
      $visitsByLocationId: visitsQuery?.data?.monitorVisitsByLocationId,
      $locations: monitor?.locations,
    })
  }, [JSON.stringify(visitsQuery?.data ?? {}), JSON.stringify(monitor?.locations)])

  // console.log({ context })

  // registerGlobalUpdater(monitorId, monitor?.interface)

  return (
    <>
      <OfflineWarning />
      <BaseTheme theme={monitor?.interface?.theme ?? {}}>
        <>
          {monitor?.interface?.debugMode ? (
            <DebugBar state={state} context={{} ?? context} />
          ) : null}

          {!monitorQuery.isFetchingInitial && !visitsQuery?.isFetchingInitial && (
            <LayoutItem content={content} state={state} performAction={performActions} />
          )}
        </>
      </BaseTheme>
    </>
  )
}

export default MonitorUI
