import CryptoJS from 'crypto-js'
import pQueue from 'p-queue'
import Dexie, { DexieOptions } from 'dexie'
import Bluebird from 'bluebird'
import { createClient } from './graphql'

export interface ITextToSpeech {
  id?: string
  data: ArrayBuffer
  lastUsed: Date
}

class SignageDB extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  textToSpeeches: Dexie.Table<ITextToSpeech, string> // number = type of the primkey
  //...other tables goes here...

  constructor(databaseName: string = 'GaudiaSignage', options?: DexieOptions) {
    super(databaseName, options)
    this.version(1).stores({
      textToSpeeches: '++id, *data, *lastUsed',
      //...other tables goes here...
    })
    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.textToSpeeches = this.table('textToSpeeches')
  }
}

export interface TextToSpeechOptions {
  useCacheDb?: boolean
}

const defaultTextToSpeechOptions: Partial<TextToSpeechOptions> = {
  useCacheDb: true,
}

export class TextToSpeech {
  audioSource: AudioBufferSourceNode | undefined | null
  audioCtx: AudioContext | undefined | null
  audioQueues: Record<string, pQueue> = {}
  options: TextToSpeechOptions
  signageDB = new SignageDB('GaudiaSignage')

  constructor(
    private customClient: ReturnType<typeof createClient>,
    options: TextToSpeechOptions,
  ) {
    this.options = { ...defaultTextToSpeechOptions, ...options }
  }

  public async play(ssml: string, namespace?: string) {
    if (!namespace) return this.playSync(ssml)

    this.audioQueues[namespace] = this.audioQueues[namespace] ?? new pQueue({ concurrency: 1, timeout: 30000 })
    const queue = this.audioQueues[namespace]

    const addedPromise = queue.add(() => this.playSync(ssml).catch())
    const result = await addedPromise

    return result
  }

  public async playSync(ssml: string) {
    const id = this.encodeSsml(ssml)

    if (this.options.useCacheDb) {
      const record = await this.signageDB.textToSpeeches
        .where('id')
        .equals(id)
        .first()
        .catch((err) => {
          // Sentry.captureException(err, { extra: { operation: 'signageDB.textToSpeeches.first()' } })
          throw err
        })

      if (record && record?.id) {
        await this.signageDB.textToSpeeches
          .update(record.id, {
            lastUsed: new Date(),
          })
          .catch((err) => {
            // Sentry.captureException(err, {
            //   extra: { operation: 'signageDB.textToSpeeches.update()' },
            // })

            throw err
          })

        return this.playBuffer(record.data)
      }
    }

    return this.customClient
      .query({
        speak: [{ ssml }, { url: true }],
      })
      .then(async ({ data }) => {
        if (!data?.speak?.url) {
          throw new Error('Not able to create the speech')
        }
        const buffer = await this.getBufferFromUrl(data?.speak?.url)
        if (this.options.useCacheDb) {
          await this.signageDB.textToSpeeches.put({ id, data: buffer, lastUsed: new Date() }).catch((err) => {
            // Sentry.captureException(err, {
            //   extra: { operation: 'signageDB.textToSpeeches.put()' },
            // })

            throw err
          })
        }

        return this.playBuffer(buffer)
      })
      .catch((err) => {
        // Sentry.captureException(err, {
        //   extra: { operation: 'customClient.query.speak()' },
        // })

        throw err
      })
  }

  public stop() {
    try {
      if (this.audioSource) {
        this.audioSource.buffer = null
      }
      this.audioSource?.stop()
      this.audioSource?.disconnect()
      this.audioSource = null
      this.audioCtx?.close()
      this.audioCtx = null
      this.audioSource = null
    } catch (err) {}
  }

  public async playBuffer(arrayBuffer: ArrayBuffer) {
    return new Promise<void>(async (resolve) => {
      this.stop()

      // @ts-ignore
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()

      this.audioSource = this.audioCtx.createBufferSource()
      this.audioSource.buffer = await this.audioCtx.decodeAudioData(arrayBuffer)
      this.audioSource.connect(this.audioCtx.destination)
      this.audioSource.loop = false
      this.audioSource.addEventListener('ended', async () => {
        await Bluebird.delay(200)
        this.stop()
        resolve()
      })
      this.audioSource.start(0)
    })
  }

  public getAudioQueue(namespace: string) {
    this.audioQueues[namespace] = this.audioQueues[namespace] ?? new pQueue({ concurrency: 1, timeout: 30000 })

    return this.audioQueues[namespace]
  }

  private encodeSsml(ssml: string) {
    return CryptoJS.MD5(ssml).toString()
  }

  private async getBufferFromUrl(url: string) {
    const response = await fetch(url)
    return await response.arrayBuffer()
  }
}
