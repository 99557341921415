import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useToken } from './useToken'
import { useIdentity } from './useIdentity'

export function withIdentityCheck<Props>(
  Component: React.ComponentType<Props>,
  preventRender = false,
) {
  return (props: Props) => {
    const location = useLocation()
    const { token } = useToken({ locationSearch: location?.search })

    const identityQuery = useIdentity()

    useEffect(() => {
      if (token && !identityQuery.isLoading && !identityQuery?.data?.identity) {
        const interval = setInterval(() => {
          if (!identityQuery.isLoading) {
            identityQuery.refetch()
            clearInterval(interval)
          }
        }, 200)

        return () => clearInterval(interval)
      }
    }, [token, identityQuery.isLoading, identityQuery?.data?.identity])

    if (!identityQuery.data?.identity?.__typename && preventRender) return null

    // @ts-ignore
    return <Component {...props} />
  }
}
