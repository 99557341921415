import React, { useEffect } from 'react'
import LogRocket from 'logrocket'
import { setUser } from '@sentry/browser'
import pino from 'pino'
import { useIdentity } from './useIdentity'

const logger = pino({ browser: { asObject: true } }).child({ ns: 'identity' })

export const IdentityObserver: React.FC = React.memo(() => {
  const identityQuery = useIdentity()

  useEffect(() => {
    if (!identityQuery.data?.identity?.__typename) return
    const identity = identityQuery.data?.identity

    let username = null
    let traits = {}

    if (identity?.__typename === 'User') {
      const fullName = `${identity.firstname} ${identity.lastname}`
      username = `${identity.email}-${identity.id}`

      traits = {
        name: fullName,
        email: identity.email,
      }
    }

    if (identity?.__typename === 'Monitor') {
      const name = identity.name
      username = `monitor-${identity.id}`

      traits = {
        name,
      }
    }

    if (identity?.__typename === 'CheckinKiosk') {
      const name = identity.name
      username = `checkinKiosk-${identity.id}`

      traits = {
        name,
      }
    }

    if (username) {
      logger.info({ username, traits }, 'setting traits')
      LogRocket.identify(username, traits)
      if (identity?.id) {
        setUser({ id: identity?.id, username: username })
      }

      /*

      // @ts-ignore
      const gtag = window.gtag
      gtag('config', process.env.REACT_APP_GTAG, {
        user_id: username,
      })*!/*/
    }
    // eslint-disable-next-line
  }, [identityQuery?.data?.identity?.__typename])

  return null
})
